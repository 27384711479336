import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

import { ORDERS_SOURCE } from '../../constants';
import { getOrdersApiBySource } from '../../utils/get-orders-api-by-source';

export const useOrders = (source: ORDERS_SOURCE, enabled?: boolean) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery([QUERIES.ORDERS, source], () => getOrdersApiBySource(source).getOrders(), {
    enabled,
    refetchInterval: 5000,
    onError: () => notify(t('errors.common')),
  });
};
