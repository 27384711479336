import React, { useCallback } from 'react';

import { API_ERROR_CODES } from '@api/api.constants';
import { ISignIn2FAError } from '@api/auth/auth-api.interfaces';
import { ApiError } from '@api/core/api.error';
import { I2FAOtp } from '@api/two-factor-auth/two-factor-auth.interfaces';
import TwoFactorAuth from '@components/Common/TwoFactorAuth';
import AuthLayout from '@components/Layout/AuthLayout';
import TradingRules from "@components/Layout/TradingRules";
import SignInForm, { ISignInFormData } from '@components/SignInForm/SignInForm';
import { useSignIn2FAMutation } from '@hooks/mutations/useSignIn2FAMutation';
import { useSignInMutation } from '@hooks/mutations/useSignInMutation';
import { use2FA } from '@hooks/use2FA';
import { useText } from '@hooks/useText';

const SignInPage = () => {
  const { t } = useText();
  const { mutateAsync: signIn, error: signInError } = useSignInMutation();
  const {
    mutateAsync: mutateSignIn2FA,
    isLoading: isSigningIn2FA,
    error: signIn2FAError,
  } = useSignIn2FAMutation();

  const signIn2FA = useCallback(
    async (data: { otps: I2FAOtp[] }) => {
      if (signInError?.response) {
        await mutateSignIn2FA({
          token: signInError.response.token,
          ...data,
        });
      }
    },
    [signInError],
  );

  const twoFactorAuthState = use2FA({
    inputTypes: signInError?.response?.twoFactorAuthChecks,
    callback: signIn2FA,
  });

  const handleSubmit = async (data: ISignInFormData) => {
    try {
      await signIn(data);
    } catch (e) {
      const error = e as ApiError<ISignIn2FAError>;

      if (error.response?.code !== API_ERROR_CODES.REQUIRE_2FA) {
        throw e;
      }
    }
  };

  const getFormError = () => {
    if (signIn2FAError && signIn2FAError?.response?.code !== API_ERROR_CODES.INVALID_OTP) {
      return t('signIn.errorCommon');
    }
  };

  return (
    <AuthLayout title={t('authLayout.welcome')} message={t('signIn.title')}>
      <SignInForm onSubmit={handleSubmit} error={getFormError()} />
      <TwoFactorAuth {...twoFactorAuthState} isVerifying={isSigningIn2FA} />
      <TradingRules/>
    </AuthLayout>
  );
};

export default React.memo(SignInPage);
