import React, { useEffect } from 'react';

import routes from '@config/routes';
import { useVerifyEmailMutation } from '@hooks/mutations/useVerifyEmailMutation';
import { useQueryParam } from '@hooks/useQueryParam';
import { Navigate } from 'react-router-dom';

const EmailVerificationPage = () => {
  const token = useQueryParam('token');

  const { mutate, isLoading } = useVerifyEmailMutation();

  useEffect(() => {
    if (token && !isLoading) {
      mutate(token);
    }
  }, [token]);

  if (!token) {
    return <Navigate to={routes.HOME} />;
  }

  return null;
};

export default React.memo(EmailVerificationPage);
