import { referralApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export interface IDeleteReferralCodePayload {
  id: number;
}

export const useDeleteReferralLinkMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (payload: IDeleteReferralCodePayload) => referralApi.deleteLink(payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERIES.REFERRALS_CODES });
    },
    onError: () => {
      notify(t('errors.common'), 'error');
    },
  });
};
