import React from 'react';

import { OperationIcon } from '@components/Common';
import { CoinIcon } from '@components/Icons';
import { setTextPath } from '@hooks/useText';
import { ICashbackHistory } from '@interfaces/cashback';
import { ICoin } from '@interfaces/coin';
import { INetwork } from '@interfaces/network';
import { IOrder } from '@interfaces/order';
import { IUserVerification, VERIFICATION_RESULTS } from '@interfaces/user';
import { IWalletAsset } from '@interfaces/wallet-asset';
import { Typography, TypographyProps } from '@material-tailwind/react';
import { Trans } from 'react-i18next';

import {
  HISTORY_STATUS,
  HISTORY_STATUS_LABELS,
  OPERATIONS_TYPES,
  HISTORY_TYPE_LABELS,
  ORDER_SIDE,
  ORDER_TYPE_LABELS,
  POSITION_SIDE,
  POSITION_SIDE_LABELS,
} from '../constants';

import { roundAmount } from './amount';

export const renderUserVerification = (verification: IUserVerification | null) => {
  if (verification && verification.result === VERIFICATION_RESULTS.APPROVED) {
    return setTextPath('user.verified');
  }

  return setTextPath('user.notVerified');
};

export const renderCoin = ({ coin, name, coinLogo }: ICoin) => (
  <div className="flex items-center">
    <CoinIcon url={coinLogo} name={coin} className="mr-4" />
    <p className="text-base">
      {coin} {name && <span className="text-xs text-blue-300">{name}</span>}
    </p>
  </div>
);

export const renderAmount = (amount: string | number, coin: string) => {
  return (
    <span>
      {amount} {coin}
    </span>
  );
};

interface IRenderNetworkParams {
  isWithdrawal?: boolean;
  coin?: string;
}

export const renderNetwork =
  (params?: IRenderNetworkParams) =>
  ({ network, name, withdrawFee, estimatedArrivalTime }: INetwork) => {
    return (
      <p className="text-base">
        <span>
          {network} <span className="text-xs text-blue-300">{name}</span>
        </span>
        {params?.isWithdrawal && params.coin && (
          <span className="text-xs text-white flex flex-col">
            <span>
              <Trans i18nKey="wallet.fee" />: {renderAmount(withdrawFee, params.coin)}
            </span>
            <span>
              <Trans
                i18nKey="wallet.estimatedArrivalTime"
                values={{ time: estimatedArrivalTime }}
              />
            </span>
          </span>
        )}
      </p>
    );
  };

export const renderAsset = ({ amount, coin, coinLogo }: IWalletAsset) => {
  return (
    <div className="flex items-center ">
      <CoinIcon url={coinLogo} name={coin} className="mr-4" />
      <p className="text-base flex justify-between w-full">
        <span className="mr-4">{coin}</span>{' '}
        <span className="text-sm">{renderAmount(amount, coin)}</span>
      </p>
    </div>
  );
};

export const renderIPAddresses = (ips: string[]) => {
  return ips.join(', ');
};

export const renderOrderType = (order: IOrder) => {
  const side =
    order.side === ORDER_SIDE.BUY ? setTextPath('market.buy') : setTextPath('market.sell');
  const type = ORDER_TYPE_LABELS[order.type] || order.type;

  return (
    <Typography
      variant="small"
      className="font-semibold whitespace-nowrap"
      color={order.side === ORDER_SIDE.BUY ? 'green' : 'red'}
    >
      <Trans i18nKey={side} /> - <Trans i18nKey={type} />
    </Typography>
  );
};

export const renderPNL = (pnl: string, pnlPercentage: string) => {
  const color = pnl.startsWith('-') ? 'red' : 'green';

  return (
    <Typography variant="small" className="font-semibold whitespace-nowrap" color={color}>
      {renderAmount(roundAmount(pnl), 'USDT')} ({pnlPercentage}%)
    </Typography>
  );
};

export const renderPositionSide = (side: POSITION_SIDE) => {
  return (
    <Typography
      variant="small"
      className="font-semibold"
      color={side === POSITION_SIDE.LONG ? 'green' : 'red'}
    >
      <Trans i18nKey={POSITION_SIDE_LABELS[side]} />
    </Typography>
  );
};

export const renderSavedCommission = (cashback: ICashbackHistory) => {
  return (
    <Typography variant="small" className="font-semibold whitespace-nowrap" color="green">
      +{renderAmount(cashback.amount, cashback.asset)} ({cashback.rate}%)
    </Typography>
  );
};

const getHistoryStatusColor = (status: HISTORY_STATUS): TypographyProps['color'] => {
  switch (status) {
    case 'SUCCESS':
      return 'green';
    case 'PENDING':
      return 'blue';
    case 'FAILED':
      return 'red';
    default:
      return 'white';
  }
};

export const renderHistoryStatus = (status: HISTORY_STATUS) => {
  const color = getHistoryStatusColor(status);

  return (
    <Typography className="font-semibold text-sm whitespace-nowrap inline" color={color}>
      <Trans i18nKey={HISTORY_STATUS_LABELS[status]} />
    </Typography>
  );
};

export const renderHistoryTitle = (type: OPERATIONS_TYPES) => {
  return (
    <span className="inline-flex gap-2 md:gap-4 items-center">
      <OperationIcon type={type} className="w-4 md:w-5" />
      <Trans i18nKey={HISTORY_TYPE_LABELS[type]} />
    </span>
  );
};
