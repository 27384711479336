import React, { useState } from 'react';

import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { ConfirmModal, CreateReferralLinkModal } from '@components/Modals';
import { ReferralsAlert, ReferralsCodes } from '@components/Referral';
import {
  IDeleteReferralCodePayload,
  useDeleteReferralLinkMutation,
} from '@hooks/mutations/useDeleteReferralLinkMutation';
import { useMarkIsPrimaryMutation } from '@hooks/mutations/useMarkIsPrimaryMutation';
import { useReferralsCodes } from '@hooks/queries/useReferralsCodes';
import { useConfirm } from '@hooks/useConfirm';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';

const ReferralLinks = () => {
  const [isCreateMode, setIsCreateMode] = useState(false);
  const { t } = useText();

  const {
    query: {
      data: referralCodes,
      isLoading: isReferralCodesLoading,
      isFetching: isReferralCodesFetching,
    },
    pagination: referralCodesPagination,
  } = useReferralsCodes();
  const {
    mutateAsync: markPrimary,
    isLoading: markPrimaryLoading,
    variables: markPrimaryVariables,
  } = useMarkIsPrimaryMutation();
  const {
    mutateAsync: deleteLink,
    isLoading: isDeleting,
    variables: deleteVariables,
  } = useDeleteReferralLinkMutation();

  const {
    confirm: handleDelete,
    isOpen,
    onConfirm,
    onCancel,
  } = useConfirm<IDeleteReferralCodePayload>(deleteLink);

  const enableCreateMode = () => setIsCreateMode(true);

  const disableCreateMode = () => setIsCreateMode(false);

  if (isReferralCodesLoading) {
    return <Preloader />;
  }

  if (!referralCodes) {
    return null;
  }

  return (
    <PageLayout
      title={t('referrals.links')}
      buttonText={t('referrals.createLink')}
      onButtonClick={enableCreateMode}
      showButton
    >
      <ReferralsAlert />
      <div className="mt-4">
        <ReferralsCodes
          data={referralCodes}
          isLoading={isReferralCodesFetching}
          pagination={referralCodesPagination}
          markPrimary={(id) => markPrimary({ id })}
          deleteLink={(id) => handleDelete({ id })}
          markPrimaryId={
            markPrimaryLoading && markPrimaryVariables ? markPrimaryVariables.id : undefined
          }
          deletingId={isDeleting && deleteVariables ? deleteVariables.id : undefined}
        />
      </div>
      <CreateReferralLinkModal isOpen={isCreateMode} onClose={disableCreateMode} />
      <ConfirmModal
        isOpen={isOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={t('referrals.deleteConfirm')}
      >
        <Typography variant="small" className="font-medium">
          {t('referrals.deleteConfirmMessage')}
        </Typography>
      </ConfirmModal>
    </PageLayout>
  );
};

export default ReferralLinks;
