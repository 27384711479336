import { walletApi } from '@api/index';
import { ITransferAssetsPayload } from '@api/wallet/wallet-api.interfaces';
import routes from '@config/routes';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const invalidateQueries = () => {
  return Promise.all([
    queryClient.invalidateQueries([QUERIES.SPOT_BALANCE]),
    queryClient.invalidateQueries([QUERIES.FUTURES_BALANCE]),
  ]);
};

export const useTransferMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: ITransferAssetsPayload) => walletApi.transferAssets(data),
    onSuccess: async () => {
      setTimeout(() => {
        invalidateQueries();
      }, 2000);
      notify(t('transfer.success'), 'success');
      navigate(routes.WALLET);
    },
  });
};
