import React, { useMemo } from 'react';

import { IInfoITem, InfoList, Section } from '@components/Common';
import { useText } from '@hooks/useText';
import { IReferralLastIncome } from '@interfaces/referrals';
import { format } from 'date-fns';

import { renderAmount } from '../../utils/renderers';

type PropsType = {
  lastIncome: IReferralLastIncome | null;
};

const ReferralsLastIncome: React.FC<PropsType> = ({ lastIncome }) => {
  const { t } = useText();

  const items = useMemo<IInfoITem[]>(() => {
    if (lastIncome) {
      return [
        {
          key: t('referrals.income'),
          value: <>+{renderAmount(lastIncome.amount, lastIncome.asset)}</>,
        },
        {
          key: t('cashback.volume'),
          value: renderAmount(lastIncome.volume, lastIncome.asset),
        },
      ];
    }

    return [];
  }, [lastIncome]);

  if (!items.length || !lastIncome) {
    return null;
  }

  return (
    <Section>
      <InfoList
        title={t('referrals.progressFor', {
          date: format(new Date(lastIncome.date), 'MM/dd/yyyy'),
        })}
        items={items}
      />
    </Section>
  );
};

export default React.memo(ReferralsLastIncome);
