import React from 'react';

import { CopyButton, LoadingIconButton } from '@components/Common';
import { BookmarkSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IReferralCode } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';

type PropsType = {
  data: IReferralCode;
  markPrimary: (id: number) => void;
  deleteLink: (id: number) => void;
  deletingId?: number;
  markPrimaryId?: number;
};

const ReferralsRecord: React.FC<PropsType> = ({
  data,
  markPrimary,
  markPrimaryId,
  deletingId,
  deleteLink,
}) => {
  const { t } = useText();
  const { id, isPrimary, code, referralsCount, activeReferralsCount, url, isDefault, isDeleted } =
    data;

  return (
    <div className="flex flex-col">
      <div className="flex gap-2 items-center">
        {isPrimary && <BookmarkSquareIcon className="h-[20px] text-blue-500" />}
        {isDeleted && <TrashIcon className="h-[20px] text-amber-500" />}
        <Typography variant="paragraph" className="font-semibold text-base">
          {code}
        </Typography>
      </div>
      <div className="mt-2">
        <Typography variant="paragraph" className="font-semibold text-sm">
          {t('referrals.invitesCount')}: {referralsCount}
        </Typography>
        <Typography variant="paragraph" className="font-semibold text-sm">
          {t('referrals.usersCount')}: {activeReferralsCount}
        </Typography>
      </div>
      {!isDeleted && (
        <div className="flex gap-3 items-center mt-2">
          <CopyButton text={url} />
          {!isPrimary && (
            <LoadingIconButton
              variant="text"
              color="blue"
              tooltip={t('referrals.markPrimary')}
              onClick={() => markPrimary(id)}
              isLoading={markPrimaryId === id}
            >
              <BookmarkSquareIcon className="h-[20px] text-white " />
            </LoadingIconButton>
          )}
          {!isDefault && (
            <LoadingIconButton
              variant="text"
              color="blue"
              tooltip={t('referrals.delete')}
              onClick={() => deleteLink(id)}
              isLoading={deletingId === id}
            >
              <TrashIcon className="text-white h-[20px]" />
            </LoadingIconButton>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ReferralsRecord);
