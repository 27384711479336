import React, { useState } from 'react';

import { FileBox } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { useTermsOfUseDocumentsQuery } from '@hooks/queries/useTermsOfUseDocumentsQuery';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { Accordion, AccordionBody, AccordionHeader, Typography } from '@material-tailwind/react';
import cn from 'classnames';

const TermsPage = () => {
  const [openedIndex, setOpenedIndex] = useState<number | null>(null);
  const { t } = useText();
  const isMd = useMd();
  const { data: documents, isFetching: isDocumentsLoading } = useTermsOfUseDocumentsQuery();

  const toggle = (index: number) => setOpenedIndex(openedIndex === index ? null : index);

  if (isDocumentsLoading) {
    return <Preloader />;
  }

  if (!documents) {
    return null;
  }

  return (
    <div className="flex flex-col justify-center pt-4">
      <Typography variant="h1" className="mb-10">
        {t('footer.terms')}
      </Typography>
      <div className="bg-gray-900 px-6 py-6 rounded-[30px]">
        {documents.map((doc, index) => (
          <Accordion open={openedIndex === index} key={doc.title}>
            <AccordionHeader
              className="text-white uppercase hover:text-[#fff]"
              onClick={() => toggle(index)}
            >
              {doc.title}
            </AccordionHeader>
            <AccordionBody>
              <div>
                <div className={cn('flex gap-2', isMd && 'justify-end mb-6')}>
                  <FileBox url={doc.urls.en} title="Eng" />
                  <FileBox url={doc.urls.ru} title="Rus" />
                </div>
                {isMd && (
                  <iframe className="w-full h-[800px]" src={doc.urls.en} title={doc.title}></iframe>
                )}
              </div>
            </AccordionBody>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default React.memo(TermsPage);
