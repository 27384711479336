import React, { useEffect, useState } from 'react';

import LeverageSlider from '@components/Leverage/LeverageSlider';
import { useText } from '@hooks/useText';
import { Button, Popover, PopoverContent, PopoverHandler, Tooltip } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  value?: number;
  max?: number;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  onChange?: (value: number) => void;
};

const LeverageSelect: React.FC<PropsType> = ({
  value,
  onChange,
  max = 150,
  size = 'sm',
  disabled,
}) => {
  const { t } = useText();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (disabled) {
      setIsOpen(false);
    }
  }, [disabled]);

  const handler = (value: boolean) => {
    if (!disabled) {
      setIsOpen(value);
    }
  };

  return (
    <div className="w-auto">
      <Popover open={isOpen} handler={handler} placement="top">
        <PopoverHandler>
          <div>
            <Tooltip content={t('leverage.changeLeverage')}>
              <Button
                variant="outlined"
                color="gray"
                size={size}
                className={cn(
                  'min-w-[100px] w-full sm:w-auto',
                  size === 'md' && 'normal-case text-base font-semibold py-[9px]',
                )}
                disabled={disabled}
              >
                {!!value && `${value}x`}
                {!value && t('leverage.leverage')}
              </Button>
            </Tooltip>
          </div>
        </PopoverHandler>
        <PopoverContent color="blue" className=" z-[999] w-[300px] sm:w-[400px]">
          <LeverageSlider max={max} value={value} onChange={onChange} popoverHandler={handler} />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default React.memo(LeverageSelect);
