import { walletApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

const mutationFn = async () => {
  await Promise.all([
    walletApi.closeAllFuturesPositionsAndOrders(),
    walletApi.closeAllSpotOrders(),
  ]);
};

export const useCloseAllOrdersAndPositionsMutation = () => {
  const { t } = useText();
  const { notify } = useNotification();

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([QUERIES.POSITIONS]),
        queryClient.invalidateQueries([QUERIES.ORDERS]),
        queryClient.invalidateQueries([QUERIES.FUTURES_BALANCE]),
      ]);
      notify(t('wallet.closeAllSuccess'), 'success');
    },
    onError: () => {
      notify(t('wallet.closeAllFailed'), 'error');
    },
  });
};
