import { useTranslation } from 'react-i18next';

import { LocalizationType } from '../localization/i18n';

export const setTextPath = <S extends string>(p: LocalizationType<S>) => p;

export const useText = () => {
  const { t, ...rest } = useTranslation();

  const translate = <S extends string>(p: LocalizationType<S>, params?: Record<string, any>) =>
    t(p, params);

  return {
    ...rest,
    t: translate,
    compileText: t,
  };
};
