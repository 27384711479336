import React from 'react';

import { IRequestVerificationData } from '@api/verification/verification-api.interfaces';
import VerificationUrlProvider from '@components/Verification/VerificationUrlProvider';
import { VERIFICATION_STATUS } from '@interfaces/user';

import { VERIFICATION_PROVIDERS } from '../../constants';

type PropsType = {
  data: IRequestVerificationData;
  provider: VERIFICATION_PROVIDERS;
  status: VERIFICATION_STATUS;
};

const VerificationProvider: React.FC<PropsType> = ({ data, provider, status }) => {
  switch (provider) {
    case VERIFICATION_PROVIDERS.BINANCE:
      return <VerificationUrlProvider url={data.url} status={status} />;
    default:
      return null;
  }
};

export default React.memo(VerificationProvider);
