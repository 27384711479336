import { IApiClient } from '@api/core/api.client';
import { ApiError } from '@api/core/api.error';
import env from '@config/env';
import axios, { AxiosError } from 'axios';
import qs from 'qs';

const client = axios.create({
  baseURL: env.API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
});

client.interceptors.response.use(
  (res) => res.data,
  (error: AxiosError) =>
    Promise.reject(new ApiError(error.response?.status, error.message, error.response?.data)),
);

export class AxiosClient implements IApiClient {
  public get<R>(url: string, query: Record<string, any>): Promise<R> {
    return client.get(url, {
      params: query,
    });
  }

  public post<P, R>(url: string, payload: P): Promise<R> {
    return client.post(url, payload);
  }

  public put<P, R>(url: string, payload: P): Promise<R> {
    return client.put(url, payload);
  }

  public patch<P, R>(url: string, payload: P): Promise<R> {
    return client.patch(url, payload);
  }

  public async delete<P, R>(url: string, payload?: P): Promise<R> {
    return client.delete(url, {
      data: payload,
    });
  }
}
