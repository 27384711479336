import React from 'react';

import routes from '@config/routes';
import { setTextPath, useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

interface ILink {
  title: string;
  url: string;
}

const links: ILink[] = [
  {
    title: setTextPath('navbar.wallet'),
    url: routes.WALLET,
  },
  {
    title: setTextPath('navbar.commissions'),
    url: routes.SAVED_COMMISSIONS,
  },
  {
    title: setTextPath('navbar.leverage'),
    url: routes.LEVERAGE,
  },
  {
    title: setTextPath('navbar.api'),
    url: routes.API_KEYS,
  },
  {
    title: setTextPath('navbar.history'),
    url: routes.HISTORY,
  },
];

const Navigation = () => {
  const { compileText } = useText();

  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-3 xl:gap-6">
      {links.map((l) => (
        <li key={l.url}>
          <NavLink to={l.url} className="flex items-center p-1">
            {({ isActive }) => (
              <Typography
                variant="paragraph"
                color="white"
                className={cn(
                  'whitespace-nowrap font-medium hover:text-[#fff] transition-colors delay-50',
                  isActive && '!text-[#fff] relative',
                )}
              >
                <span
                  className={cn(
                    'absolute w-full h-[2px] from-blue-700 to-blue-500 top-[172%] hidden bg-gradient-to-tr',
                    isActive && 'lg:!block',
                  )}
                />
                {compileText(l.title)}
              </Typography>
            )}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default React.memo(Navigation);
