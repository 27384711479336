import React from 'react';

import CashbackDetails from '@components/Cashback/CashbackDetails';
import { useText } from '@hooks/useText';
import { ICashbackHistory } from '@interfaces/cashback';
import { Typography } from '@material-tailwind/react';

import { renderAmount, renderSavedCommission } from '../../utils/renderers';

type PropsType = {
  cashback: ICashbackHistory;
};

const CashbackHistory: React.FC<PropsType> = ({ cashback }) => {
  const { t } = useText();

  return (
    <div>
      <CashbackDetails cashback={cashback} />
      <Typography className="font-semibold mt-3">
        {t('cashback.volume')}: {renderAmount(cashback.totalVolume, cashback.asset)}
      </Typography>
      <Typography className="font-semibold mb-3">
        {t('cashback.commission')}: {renderAmount(cashback.totalFee, cashback.asset)}
      </Typography>
      {renderSavedCommission(cashback)}
    </div>
  );
};

export default React.memo(CashbackHistory);
