import {useAuth} from "@hooks/useAuth";
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import * as reactQuery from 'react-query';
import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';

export const useQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>,
): UseQueryResult<TData, TError> => {
  const { notify } = useNotification();
  const { t } = useText();
  const { logout } = useAuth();

  return reactQuery.useQuery(queryKey, queryFn, {
    ...options,
    onError: (err) => {
      if (options?.onError) {
        options.onError(err);
      }

      // @ts-ignore
      if (err?.statusCode === 401) { // Unauthorized
        logout();
        notify(t('errors.unauthorized'));
      } else {
        notify(t('errors.common'));
      }
    },
  });
};
