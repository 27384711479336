import React from 'react';

import {
  CashbackAlert,
  CashbackHistoryTable,
  CashbackRate,
  CashbackSummary,
  TodayCashback,
} from '@components/Cashback';
import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { useCashbackEstimateQuery } from '@hooks/queries/useCashbackEstimateQuery';
import { useCashbackHistoryQuery } from '@hooks/queries/useCashbackHistoryQuery';
import { useCashbackRatesQuery } from '@hooks/queries/useCashbackRatesQuery';
import { useCashbackTotalQuery } from '@hooks/queries/useCashbackTotalQuery';
import { useText } from '@hooks/useText';

const SavedCommissionsPage = () => {
  const { t } = useText();
  const {
    query: { data: history, isLoading: isHistoryLoading, isFetching: isHistoryFetching },
    pagination: cashbackHistoryPagination,
  } = useCashbackHistoryQuery();
  const { data: estimate, isLoading: isEstimateLoading } = useCashbackEstimateQuery();
  const { data: total, isLoading: isTotalLoading } = useCashbackTotalQuery();
  const { data: rates, isLoading: isRatesLoading } = useCashbackRatesQuery();

  if (isHistoryLoading || isEstimateLoading || isTotalLoading || isRatesLoading) {
    return <Preloader />;
  }

  if (!history || !estimate || !total || !rates) {
    return null;
  }

  return (
    <PageLayout title={t('navbar.commissions')}>
      <CashbackAlert />
      <div className="flex flex-col lg:flex-row gap-6">
        <div className="lg:w-[37%] flex flex-col gap-6">
          <TodayCashback amount={estimate.amount} />
          <CashbackSummary total={total} />
          <CashbackRate rates={rates} />
        </div>
        <div className="w-full">
          <CashbackHistoryTable
            data={history}
            isLoading={isHistoryFetching}
            pagination={cashbackHistoryPagination}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default React.memo(SavedCommissionsPage);
