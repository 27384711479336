import React from 'react';

import { HiddenContent, Pnl } from '@components/Common';
import { useHideMode } from '@hooks/useHideMode';
import { useText } from '@hooks/useText';
import { IPnl } from '@interfaces/pnl';
import { Typography } from '@material-tailwind/react';

import { roundAmount } from '../../utils/amount';

type PropsType = {
  className?: string;
  walletName?: string;
  balance?: string;
  showDailyProfit?: boolean;
  pnl: IPnl;
};

const WalletBalance: React.FC<PropsType> = ({
  className,
  walletName,
  showDailyProfit,
  balance = '0',
  pnl,
}) => {
  const { enabled: isHideModeEnabled } = useHideMode();
  const { t } = useText();

  return (
    <div className={className}>
      <Typography className="text-lg font-semibold mb-2">{walletName}</Typography>
      <Typography variant="h5" className="whitespace-nowrap text-[#fff] mb-2">
        {!isHideModeEnabled && '≈ '}
        <HiddenContent hidden={isHideModeEnabled}>{roundAmount(balance)}</HiddenContent> USDT
      </Typography>
      {showDailyProfit && (
        <div className="flex">
          <Typography className="text-sm text-white font-semibold mr-3">
            {t('wallet.dailyProfit')}
          </Typography>
          <Pnl pnl={pnl.amount} asset={pnl.asset} />
        </div>
      )}
    </div>
  );
};

export default React.memo(WalletBalance);
