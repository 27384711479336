import React from 'react';

import { TextView } from '@components/Common';
import QrCode from '@components/Common/QRCode';
import Modal from '@components/Modals/Modal';
import { useText } from '@hooks/useText';
import { ITwoFactorAuthRegisterData } from '@interfaces/two-factor-auth';
import { Button, Typography } from '@material-tailwind/react';

type PropsType = {
  isOpen: boolean;
  isLoading: boolean;
  onReady?: () => void;
  onCancel: () => void;
  data?: ITwoFactorAuthRegisterData;
};

const EnableTwoFactorAuthModal: React.FC<PropsType> = ({
  isOpen,
  isLoading,
  onReady,
  onCancel,
  data,
}) => {
  const { t } = useText();

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onCancel}
      title={t('settings.connectGoogleAuthTitle')}
      footer={
        <div className="flex gap-3">
          <Button variant="text" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onReady}>{t('common.ready')}</Button>
        </div>
      }
      size="sm"
    >
      <Typography className="mb-7 font-semibold">{t('settings.connectGoogleAuth')}</Typography>
      {data && (
        <>
          {data?.qrCodeUrl && <QrCode src={data.qrCodeUrl} className="mb-7" />}
          <TextView text={data.secret} label={t('common.key')} />
        </>
      )}
    </Modal>
  );
};

export default React.memo(EnableTwoFactorAuthModal);
