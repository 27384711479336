import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IBurnStatus } from '@interfaces/burn-status';
import { IExchangeAccount } from '@interfaces/exchange-account';

export class ExchangeApi extends BaseApi {
  public getAccount() {
    return this.client.get<IExchangeAccount>(endpoints.EXCHANGE.ACCOUNT);
  }

  public getCoinBurnStatus() {
    return this.client.get<IBurnStatus>(endpoints.EXCHANGE.BURN);
  }

  public toggleCoinBurn(enabled: boolean) {
    return this.client.patch(endpoints.EXCHANGE.BURN, {
      enabled,
    });
  }

  public toggleDustTransfer(enabled: boolean) {
    return this.client.patch(endpoints.EXCHANGE.DUST_TRANSFER, {
      enabled,
    });
  }
}
