import React, { useEffect, useRef, useState } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IconButton, Tooltip } from '@material-tailwind/react';

import ls from '../../utils/ls';

const LS_KEY = 'install-button-active';

const InstallAppButton = () => {
  const { t } = useText();
  const deferredPromptRef = useRef<any>();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      const isActiveSaved = ls.get<boolean>(LS_KEY);
      const isActive = isActiveSaved === null ? true : isActiveSaved;

      setIsActive(isActive);
      deferredPromptRef.current = e;
    });
  }, []);

  const handleInstall = async () => {
    if (deferredPromptRef.current) {
      const { outcome } = await deferredPromptRef.current.prompt();

      if (outcome === 'accepted') {
        setIsActive(false);
        deferredPromptRef.current = null;
      } else {
        ls.set(LS_KEY, false);
      }
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <Tooltip content={t('common.installApp')}>
        <IconButton className="rounded-full" size="lg" onClick={handleInstall}>
          <ArrowDownTrayIcon className="w-7 text-blue-500" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default React.memo(InstallAppButton);
