import { setTextPath } from '@hooks/useText';

export enum EXCHANGE_TYPES {
  BINANCE = 'BINANCE',
}

export enum VERIFICATION_PROVIDERS {
  BINANCE = 'BINANCE',
}

export enum WALLET_TYPE {
  SPOT = 'SPOT',
  USDT_FUTURE = 'USDT_FUTURE',
}

export const WALLET_TYPE_LABELS: Record<WALLET_TYPE, string> = {
  [WALLET_TYPE.SPOT]: setTextPath('wallet.spot'),
  [WALLET_TYPE.USDT_FUTURE]: setTextPath('wallet.futuresUSDM'),
};

export enum COIN_TYPES {
  DEPOSIT = 'deposit',
  WITHDRAWAL = 'withdrawal',
}

export enum FORM_MODES {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum ORDER_SIDE {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum ORDER_TYPE {
  LIMIT = 'LIMIT',
  MARKET = 'MARKET',
  STOP = 'STOP',
  STOP_MARKET = 'STOP_MARKET',
  STOP_LOSS = 'STOP_LOSS',
  STOP_LOSS_LIMIT = 'STOP_LOSS_LIMIT',
  TAKE_PROFIT = 'TAKE_PROFIT',
  TAKE_PROFIT_LIMIT = 'TAKE_PROFIT_LIMIT',
  TAKE_PROFIT_MARKET = 'TAKE_PROFIT_MARKET',
  LIMIT_MAKER = 'LIMIT_MAKER',
  TRAILING_STOP_MARKET = 'TRAILING_STOP_MARKET',
}

export const ORDER_TYPE_LABELS: Record<ORDER_TYPE, string> = {
  [ORDER_TYPE.LIMIT]: setTextPath('orderType.limit'),
  [ORDER_TYPE.MARKET]: setTextPath('orderType.market'),
  [ORDER_TYPE.STOP]: setTextPath('orderType.stop'),
  [ORDER_TYPE.STOP_MARKET]: setTextPath('orderType.stopMarket'),
  [ORDER_TYPE.STOP_LOSS]: setTextPath('orderType.stopLoss'),
  [ORDER_TYPE.STOP_LOSS_LIMIT]: setTextPath('orderType.stopLossLimit'),
  [ORDER_TYPE.TAKE_PROFIT]: setTextPath('orderType.takeProfit'),
  [ORDER_TYPE.TAKE_PROFIT_LIMIT]: setTextPath('orderType.takeProfitLimit'),
  [ORDER_TYPE.TAKE_PROFIT_MARKET]: setTextPath('orderType.takeProfitMarket'),
  [ORDER_TYPE.LIMIT_MAKER]: setTextPath('orderType.limitMaker'),
  [ORDER_TYPE.TRAILING_STOP_MARKET]: setTextPath('orderType.trailingStopMarket'),
};

export enum ORDERS_SOURCE {
  SPOT = 'SPOT',
  FUTURES = 'FUTURES',
}

export enum POSITION_SIDE {
  LONG = 'LONG',
  SHORT = 'SHORT',
}

export const POSITION_SIDE_LABELS: Record<POSITION_SIDE, string> = {
  [POSITION_SIDE.LONG]: setTextPath('position.long'),
  [POSITION_SIDE.SHORT]: setTextPath('position.short'),
};

export enum CASHBACK_TYPE {
  SPOT = 'SPOT',
  FUTURE_USD_M = 'FUTURE_USD_M',
}

export const CASHBACK_TYPE_LABELS: Record<CASHBACK_TYPE, string> = {
  [CASHBACK_TYPE.SPOT]: setTextPath('wallet.spot'),
  [CASHBACK_TYPE.FUTURE_USD_M]: setTextPath('wallet.futuresUSDM'),
};

export enum MARGIN_TYPE {
  ISOLATED = 'ISOLATED',
  CROSSED = 'CROSSED',
}

export const MARGIN_TYPE_LABELS: Record<MARGIN_TYPE, string> = {
  [MARGIN_TYPE.CROSSED]: setTextPath('leverage.crossed'),
  [MARGIN_TYPE.ISOLATED]: setTextPath('leverage.isolated'),
};

export enum OTP_TYPES {
  AUTHENTICATOR = 'AUTHENTICATOR',
  EMAIL = 'EMAIL',
}

export enum TWO_FACTOR_AUTH_ACTIONS {
  WITHDRAW = 'WITHDRAW',
  API_KEY_CREATE = 'API_KEY_CREATE',
  API_KEY_UPDATE = 'API_KEY_UPDATE',
  API_KEY_DELETE = 'API_KEY_DELETE',
}

export enum OPERATIONS_TYPES {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  TRANSFER = 'TRANSFER',
  DUST_TRANSFER = 'DUST_TRANSFER',
}

export const HISTORY_TYPE_LABELS: Record<OPERATIONS_TYPES, string> = {
  [OPERATIONS_TYPES.DEPOSIT]: setTextPath('history.deposit'),
  [OPERATIONS_TYPES.WITHDRAW]: setTextPath('history.withdraw'),
  [OPERATIONS_TYPES.TRANSFER]: setTextPath('history.transfer'),
  [OPERATIONS_TYPES.DUST_TRANSFER]: setTextPath('history.dust_transfer'),
};

export enum HISTORY_STATUS {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export const HISTORY_STATUS_LABELS: Record<HISTORY_STATUS, string> = {
  [HISTORY_STATUS.PENDING]: setTextPath('history.pending'),
  [HISTORY_STATUS.SUCCESS]: setTextPath('history.success'),
  [HISTORY_STATUS.FAILED]: setTextPath('history.failed'),
};

export enum LANGUAGES {
  EN = 'en',
  RU = 'ru',
}

export enum HOT_ASSETS {
  USDT = 'USDT',
  USDC = 'USDC',
  BNB = 'BNB',
}
