import { referralApi } from '@api/index';
import { usePaginationQuery } from '@hooks/usePaginationQuery';
import { QUERIES } from '@query/queries';

export const useReferralsHistoryQuery = (enabled?: boolean) => {
  return usePaginationQuery(
    [QUERIES.REFERRALS_HISTORY],
    (query) => referralApi.getReferralsHistory(query),
    { enabled },
  );
};
