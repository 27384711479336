import { IApiPaginationQuery, IApiPaginationResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IHistory } from '@interfaces/history';

import { OPERATIONS_TYPES } from '../../constants';

interface IGetHistoryQuery extends IApiPaginationQuery {
  type?: OPERATIONS_TYPES;
}

export class HistoryApi extends BaseApi {
  public get(query?: IGetHistoryQuery) {
    return this.client.get<IApiPaginationResponse<IHistory>>(endpoints.HISTORY.INDEX, query);
  }
}
