import { authApi } from '@api/index';
import { IUser } from '@interfaces/user';
import { useMutation } from 'react-query';

export interface IUseSignUpPayload {
  email: string;
  password: string;
  referralCode?: string;
}

export const useSignUpMutation = (onSuccess?: (data?: IUser | null) => void) => {
  return useMutation({
    mutationFn: (data: IUseSignUpPayload) => authApi.signUp(data),
    onSuccess,
  });
};
