import React from 'react';

import { useText } from '@hooks/useText';
import { IPosition } from '@interfaces/position';
import { Typography } from '@material-tailwind/react';

import { roundAmount } from '../../utils/amount';
import { renderPNL, renderPositionSide } from '../../utils/renderers';

type PropsType = {
  position: IPosition;
};

const Position: React.FC<PropsType> = ({ position }) => {
  const { t } = useText();

  return (
    <div>
      <div className="flex gap-2 items-center">
        <Typography className="font-bold">{position.symbol}</Typography>
        {renderPositionSide(position.side)}
      </div>
      <Typography variant="small" className="font-semibold">
        {t('position.price')}: {roundAmount(position.entryPrice, 5)}
      </Typography>
      <Typography variant="small" className="font-semibold">
        {t('position.volume')}: {roundAmount(position.quantity, 5)}
      </Typography>
      <Typography variant="small" className="font-semibold">
        {t('position.liquidation')}: {roundAmount(position.liquidationPrice, 5)}
      </Typography>
      <Typography
        variant="small"
        className="font-semibold flex gap-1 whitespace-nowrap items-center"
      >
        {t('position.pnlSmall')}: {renderPNL(position.pnl, position.pnlPercentage)}
      </Typography>
    </div>
  );
};

export default React.memo(Position);
