import React from 'react';

import env from '@config/env';
import { EnvelopeIcon } from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

const HelpBox = () => {
  const isMd = useMd();

  return (
    <NavLink to={`mailto:${env.SUPPORT_EMAIL}`}>
      <Typography
        as="span"
        color="white"
        variant="small"
        className="whitespace-nowrap transition-all hover:brightness-110 hover:text-[#fff] focus:text-[#fff] bg-gray-900 px-3 md:px-5 py-2 rounded-full inline-flex items-center"
      >
        <EnvelopeIcon className={cn('h-4 w-4 ml-1 mr-1', isMd && 'ml-2')} />
        {env.SUPPORT_EMAIL}
      </Typography>
    </NavLink>
  );
};

export default HelpBox;
