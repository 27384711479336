import React, { useMemo } from 'react';

import { Chart } from '@components/Common';
import { IPeriod } from '@interfaces/period';
import { IPnlForDay } from '@interfaces/pnl';
import { format } from 'date-fns';

type PropsType = {
  data: IPnlForDay[];
  period: IPeriod;
};

const DATE_FORMAT = 'MM/dd/yyyy';

const PnlsChart: React.FC<PropsType> = ({ data, period }) => {
  const xLabels = useMemo(() => {
    if (!data.length) {
      return [format(period.fromDate, DATE_FORMAT), format(period.toDate, DATE_FORMAT)];
    }

    return data.map((pnl) => pnl.date);
  }, [data, period]);

  const chartData = useMemo<number[]>(() => {
    if (!data.length) {
      return [0, 0];
    }

    return data.map((pnl) => parseFloat(pnl.amount));
  }, [data]);

  const dataPeriod = useMemo<IPeriod>(() => {
    const first = data[0];
    const last = data[data.length - 1];

    return {
      fromDate: first ? new Date(first.date) : period.fromDate,
      toDate: last ? new Date(last.date) : period.toDate,
    };
  }, [data]);

  return (
    <Chart
      label={data[0]?.asset || 'USDT'}
      xLabels={xLabels}
      data={chartData}
      period={dataPeriod}
    />
  );
};

export default React.memo(PnlsChart);
