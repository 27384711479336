import React from 'react';

import { useText } from '@hooks/useText';
import { IOrder } from '@interfaces/order';
import { Typography } from '@material-tailwind/react';

import { roundAmount } from '../../utils/amount';
import { renderOrderType } from '../../utils/renderers';

type PropsType = {
  order: IOrder;
};

const Order: React.FC<PropsType> = ({ order }) => {
  const { t } = useText();

  return (
    <div>
      <Typography className="font-semibold">{order.symbol}</Typography>
      {renderOrderType(order)}
      <Typography variant="small" className="font-semibold">
        {t('order.price')}: {roundAmount(order.price, 5)}
      </Typography>
      <Typography variant="small" className="font-semibold whitespace-nowrap">
        {t('order.quantity')}: {roundAmount(order.quantity, 5)}
      </Typography>
    </div>
  );
};

export default React.memo(Order);
