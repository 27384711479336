import { IApiEmptyResponse } from '@api/api.interfaces';
import { IEmailVerifyPayload, ISignIn2FAPayload } from '@api/auth/auth-api.interfaces';
import { BaseApi } from '@api/core/base.api';
import { ISignInPayload, ISignUpPayload } from '@api/users/users-api.interfaces';
import { IResetPasswordRequestFormData } from '@components/ResetPassword/ResetPasswordRequestForm';
import endpoints from '@config/endpoints';
import { IResetPasswordPayload } from '@hooks/mutations/useResetPasswordMutation';
import { IUser } from '@interfaces/user';

export class AuthApi extends BaseApi {
  public logout() {
    return this.client.delete<void, IApiEmptyResponse>(endpoints.AUTH.LOGOUT);
  }

  public signUp(data: ISignUpPayload) {
    return this.client.post<ISignUpPayload, IUser>(endpoints.AUTH.SIGN_UP, data);
  }

  public signIn(data: ISignInPayload) {
    return this.client.post<ISignInPayload, IUser>(endpoints.AUTH.SIGN_IN, data);
  }

  public signIn2FA(data: ISignIn2FAPayload) {
    return this.client.post<ISignIn2FAPayload, IUser>(endpoints.AUTH.SIGN_IN_2FA, data);
  }

  public requestResetPassword(data: IResetPasswordRequestFormData) {
    return this.client.post<IResetPasswordRequestFormData, void>(
      endpoints.AUTH.RESET_PASSWORD_REQUEST,
      data,
    );
  }

  public resetPassword(data: IResetPasswordPayload) {
    return this.client.put<IResetPasswordPayload, void>(endpoints.AUTH.RESET_PASSWORD, data);
  }

  public verifyEmail(token: string) {
    return this.client.put<IEmailVerifyPayload, IApiEmptyResponse>(endpoints.AUTH.VERIFY_EMAIL, {
      token,
    });
  }
}
