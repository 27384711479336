import React, { useEffect, useMemo, useState } from 'react';

import { validatePasswordComponents } from '@components/Form/Fields/NewPasswordField/validation';
import PasswordInput from '@components/Form/Fields/PasswordField/PasswordInput';
import FormField, { FormFieldProps } from '@components/Form/FormField';
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { Alert, Typography } from '@material-tailwind/react';

export interface INewPasswordFieldValue {
  password?: string;
  passwordRepeat?: string;
}

export type NewPasswordFieldPropsType = FormFieldProps & {
  value?: INewPasswordFieldValue;
  onChange?: (value: INewPasswordFieldValue) => void;
  name: string;
};

const NewPasswordField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  NewPasswordFieldPropsType
> = ({ className, error, value, onChange }, ref) => {
  const [isAlertShown, setIsAlertShown] = useState(false);
  const {
    isMinLengthValid,
    isLowerCaseValid,
    isUpperCaseValid,
    isNumbersValid,
    isSpecialCharactersValid,
    isScoreValid,
    isPasswordsMatch,
  } = useMemo(() => validatePasswordComponents(value?.password, value?.passwordRepeat), [value]);

  const { t } = useText();

  useEffect(() => {
    if (value?.password && !isAlertShown) {
      setIsAlertShown(true);
    }
  }, [value?.password]);

  const handleChange = (name: keyof INewPasswordFieldValue) => (e: React.BaseSyntheticEvent) => {
    if (onChange) {
      const text = e.target.value;

      onChange({ ...value, [name]: text });
    }
  };

  const renderValidation = (text: string, trigger: boolean) => {
    const color = trigger ? 'green' : error ? 'red' : 'white';

    return (
      <li>
        <div className="flex items-center min-h-[24px]">
          {!trigger && (
            <Typography as="span" className="mr-2 text-[24px] w-[13px]" color={color}>
              &#x2022;
            </Typography>
          )}
          {trigger && <CheckIcon className="w-[13px] text-green-500 mr-2" />}
          <Typography as="span" variant="small" className="font-medium" color={color}>
            {text}
          </Typography>
        </div>
      </li>
    );
  };

  return (
    <FormField className={className}>
      <div className="mb-7">
        <PasswordInput
          error={!!error}
          value={value?.password}
          ref={ref as any}
          autoComplete="new-password"
          label={t('signUp.passwordLabel')}
          name="password"
          onChange={handleChange('password')}
        />
      </div>
      <div>
        <PasswordInput
          error={!!error}
          value={value?.passwordRepeat}
          ref={ref as any}
          autoComplete="new-password"
          label={t('signUp.passwordRepeatLabel')}
          name="passwordRepeat"
          onChange={handleChange('passwordRepeat')}
        />
      </div>
      {(value?.password || isAlertShown || error) && (
        <Alert
          icon={<InformationCircleIcon className="text-white w-[24px]" />}
          variant="gradient"
          color="gray"
          className="mt-4 px-3"
        >
          <Typography className="font-medium" variant="small" color="white">
            {t('passwordValidation.title')}
          </Typography>
          <ul className="mt-2 list-inside list-none">
            {renderValidation(t('passwordValidation.minLength'), isMinLengthValid)}
            {renderValidation(t('passwordValidation.passwordsMatch'), isPasswordsMatch)}
            {renderValidation(t('passwordValidation.scoreValid'), isScoreValid)}
            <ul className="list-inside ml-5 list-none">
              {renderValidation(t('passwordValidation.lowerCaseValid'), isLowerCaseValid)}
              {renderValidation(t('passwordValidation.UpperCaseValid'), isUpperCaseValid)}
              {renderValidation(t('passwordValidation.numbersValid'), isNumbersValid)}
              {renderValidation(
                t('passwordValidation.specialCharactersValid'),
                isSpecialCharactersValid,
              )}
            </ul>
          </ul>
        </Alert>
      )}
    </FormField>
  );
};

export default React.memo(React.forwardRef(NewPasswordField));
