import React from 'react';

import FormField, { FormFieldProps } from '@components/Form/FormField';
import { Input, InputProps } from '@material-tailwind/react';

export type InputFieldPropsType = FormFieldProps &
  Omit<InputProps, 'error'> & {
    inputClassName?: string;
  };

const InputField: React.ForwardRefRenderFunction<HTMLInputElement, InputFieldPropsType> = (
  { error, className, helperText, inputClassName, hideError, ...props },
  ref,
) => {
  return (
    <FormField error={error} helperText={helperText} className={className} hideError={hideError}>
      <Input
        crossOrigin
        {...(props as any)}
        inputRef={ref}
        error={!!error}
        className={inputClassName}
      />
    </FormField>
  );
};

export default React.forwardRef(InputField);
