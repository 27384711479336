import React from 'react';

import cn from 'classnames';

type PropsType = {
  src: string;
  className?: string;
};

const QrCode: React.FC<PropsType> = ({ src, className }) => {
  return (
    <div className={cn('flex justify-center w-full', className)}>
      <div className="rounded-lg overflow-hidden">
        <img src={src} alt="QR Code" />
      </div>
    </div>
  );
};

export default React.memo(QrCode);
