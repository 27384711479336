import React from 'react';

import cn from 'classnames';

type PropsType = {
  label: string | React.ReactNode;
  text: string | React.ReactNode;
  icon?: any;
  className?: string;
  labelClassName?: string;
  size?: 'medium' | 'large';
};

const InfoBlock: React.FC<PropsType> = ({
  label,
  text,
  className,
  labelClassName,
  icon: Icon,
  size = 'medium',
}) => {
  return (
    <div className={cn('flex gap-3 items-center', className)}>
      {Icon && <Icon className="h-8 text-white" />}
      <div>
        <p
          className={cn(
            'text-blue-300 font-semibold',
            size === 'medium' && 'text-sm',
            size === 'large' && 'text-base',
            labelClassName,
          )}
        >
          {label}
        </p>
        <p
          className={cn(
            'text-base text-white font-semibold break-all',
            size === 'medium' && 'text-base',
            size === 'large' && 'text-lg',
          )}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default React.memo(InfoBlock);
