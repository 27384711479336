import React from 'react';

import { Section } from '@components/Common';
import OrdersTable from '@components/Wallet/OrdersTable';
import { useDeleteOrdersMutation } from '@hooks/mutations/useDeleteOrdersMutation';
import { IOrder } from '@interfaces/order';

import { ORDERS_SOURCE } from '../../constants';

type PropsType = {
  orders: IOrder[];
  source: ORDERS_SOURCE;
};

const OrdersSection: React.FC<PropsType> = ({ orders, source }) => {
  const {
    mutate: deleteOrder,
    isLoading: isDeleting,
    variables: ordersToDelete,
  } = useDeleteOrdersMutation(source);

  return (
    <Section>
      <OrdersTable
        orders={orders}
        onDelete={deleteOrder}
        ordersIdsToDelete={ordersToDelete?.map((order) => order.id)}
        isDeleting={isDeleting}
      />
    </Section>
  );
};

export default React.memo(OrdersSection);
