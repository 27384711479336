import { use as useI18n } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './lang/en.json';
import ru from './lang/ru.json';

type DeepKeys<T, S extends string> = T extends object
  ? S extends `${infer I1}.${infer I2}`
    ? I1 extends keyof T
      ? // fix issue allowed last dot
        T[I1] extends object
        ? `${I1}.${DeepKeys<T[I1], I2>}`
        : keyof T & string
      : keyof T & string
    : S extends keyof T
    ? `${S}`
    : keyof T & string
  : '';

export type LocalizationType<S extends string> = DeepKeys<typeof en, S>;

export const i18n = useI18n(initReactI18next);

i18n.init({
  resources: {
    en: {
      translation: en,
    },
    ru: {
      translation: ru,
    },
  },
  lng: 'ru',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});
