import { setTextPath } from '@hooks/useText';
import { IOption } from '@interfaces/option';

import { WALLET_TYPE } from '../constants';

export const walletTypes: IOption<WALLET_TYPE>[] = [
  {
    label: setTextPath('wallet.spot'),
    value: WALLET_TYPE.SPOT,
  },
  {
    label: setTextPath('wallet.futuresUSDM'),
    value: WALLET_TYPE.USDT_FUTURE,
  },
];
