import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { Alert } from '@material-tailwind/react';

const ReferralsAlert = () => {
  const { t } = useText();

  return (
    <Alert
      variant="gradient"
      color="gray"
      icon={<InformationCircleIcon className="text-white w-[24px]" />}
    >
      {t('referrals.alert')}
    </Alert>
  );
};

export default React.memo(ReferralsAlert);
