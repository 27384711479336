import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IFuturesPair } from '@interfaces/futures-pair';

import { MARGIN_TYPE } from '../../constants';

export class PairsApi extends BaseApi {
  public get() {
    return this.client.get<IFuturesPair[]>(endpoints.FUTURES.PAIRS);
  }

  public updateMarginType(pairs: string[], marginType: MARGIN_TYPE) {
    return this.client.patch(endpoints.FUTURES.PAIRS_MARGIN_TYPE, {
      pairs,
      marginType,
    });
  }

  public updateLeverage(pairs: string[], leverage: number) {
    return this.client.patch(endpoints.FUTURES.PAIRS_LEVERAGE, {
      pairs,
      leverage,
    });
  }

  public setMaxLeverage(pairs: string[]) {
    return this.client.patch(endpoints.FUTURES.PAIRS_SET_MAX_LEVERAGE, {
      pairs,
    });
  }
}
