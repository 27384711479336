import { statisticApi } from '@api/index';
import { QUERIES } from '@query/queries';
import { startOfToday } from 'date-fns';
import { useQuery } from 'react-query';

export const useFuturesPnlForTodayQuery = () => {
  return useQuery([QUERIES.FUTURES_PNL_FOR_TODAY], () =>
    statisticApi.getPnl({
      fromDate: startOfToday().toISOString(),
      toDate: new Date().toISOString(),
    }),
  );
};
