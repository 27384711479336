import React from 'react';

import Modal from '@components/Modals/Modal';
import { use2Xl } from '@hooks/responsive/use2Xl';
import { useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';

type PropsType = {
  isOpen: boolean;
  title: string;
  onConfirm?: () => void;
  onCancel: () => void;
};

const ConfirmModal: React.FC<React.PropsWithChildren<PropsType>> = ({
  isOpen,
  title,
  children,
  onConfirm,
  onCancel,
}) => {
  const { t } = useText();
  const is2XL = use2Xl();

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      size={is2XL ? 'xs' : 'sm'}
      onClose={onCancel}
      footer={
        <>
          <Button variant="text" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button onClick={onConfirm}>{t('common.confirm')}</Button>
        </>
      }
    >
      {children}
    </Modal>
  );
};

export default React.memo(ConfirmModal);
