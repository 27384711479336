import React from 'react';

import { IOption } from '@interfaces/option';
import { Select, Option, Spinner, SelectProps } from '@material-tailwind/react';
import cn from 'classnames';

import { LANGUAGES } from '../../constants';

type PropsType = {
  className?: string;
  value: LANGUAGES;
  onChange?: (lng: LANGUAGES) => void;
  isLoading?: boolean;
};

const options: IOption<LANGUAGES>[] = [
  {
    label: 'English',
    value: LANGUAGES.EN,
  },
  {
    label: 'Русский',
    value: LANGUAGES.RU,
  },
];

const LanguagePicker: React.FC<PropsType> = ({ className, value, isLoading, onChange }) => {
  return (
    <div className={cn('language-picker', className)}>
      {isLoading && (
        <div className="px-2">
          <Spinner color="blue" />
        </div>
      )}
      {!isLoading && (
        <Select
          variant="outlined"
          arrow={<div></div>}
          value={value}
          onChange={onChange as SelectProps['onChange']}
          selected={() => <div className="text-sm">{value.toUpperCase()}</div>}
          containerProps={{
            className: 'h-9',
          }}
        >
          {options.map((o) => (
            <Option key={o.value} value={o.value}>
              {o.label}
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default React.memo(LanguagePicker);
