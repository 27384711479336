import React, { useMemo } from 'react';

import { OtpModal } from '@components/Modals';
import { Enable2FaFlow } from '@components/Settings';
import { IUse2FAReturn } from '@hooks/use2FA';

import { OTP_TYPES } from '../../constants';

type PropsType = Omit<IUse2FAReturn<any>, 'activate'> & {
  isVerifying?: boolean;
};

const TwoFactorAuth: React.FC<PropsType> = ({ types, refresh, ...props }) => {
  const otpTypes = useMemo(() => {
    return types.map((t) => t.type);
  }, [types]);

  const isAuthenticatorNotRegistered = useMemo(() => {
    return types.some((t) => t.type === OTP_TYPES.AUTHENTICATOR && !t.isRegistered);
  }, [types]);

  return (
    <>
      <OtpModal {...props} types={otpTypes} />
      <Enable2FaFlow
        isActive={isAuthenticatorNotRegistered && props.isOpen && !props.isLoading}
        onFinish={refresh}
        onCancel={props.onCancel}
      />
    </>
  );
};

export default React.memo(TwoFactorAuth);
