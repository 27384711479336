import { apiKeysApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

const fetch = (id: string | undefined) => {
  if (!id) {
    return Promise.resolve(null);
  }

  return apiKeysApi.getById(parseInt(id));
};

export const useApiKey = (id: string | undefined) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery([QUERIES.API_KEY, id], () => fetch(id), {
    cacheTime: 100,
    enabled: !!id,
    onError: () => notify(t('errors.common')),
  });
};
