import React from 'react';

import PasswordInput from '@components/Form/Fields/PasswordField/PasswordInput';
import FormField, { FormFieldProps } from '@components/Form/FormField';
import { InputProps } from '@material-tailwind/react';

export type PasswordFieldPropsType = FormFieldProps & Omit<InputProps, 'error'>;

const PasswordField: React.ForwardRefRenderFunction<HTMLInputElement, PasswordFieldPropsType> = (
  { error, className, ...props },
  ref,
) => {
  return (
    <FormField error={error} className={className}>
      <PasswordInput {...props} error={!!error} ref={ref} />
    </FormField>
  );
};

export default React.memo(React.forwardRef(PasswordField));
