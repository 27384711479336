import React, { useState } from 'react';

import InputField from '@components/Form/Fields/InputField/InputField';
import { ClipboardDocumentIcon, ClipboardDocumentCheckIcon } from '@heroicons/react/24/solid';
import { useClipboard } from '@hooks/useClipboard';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { useSpring, animated } from '@react-spring/web';

import './TextView.scss';

type PropsType = {
  label: string;
  text: string;
  copyTitle?: string;
  disableCopy?: boolean;
};

const TextView: React.FC<PropsType> = ({ text, label, copyTitle, disableCopy }) => {
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const copy = useClipboard(text);
  const [copyAnimationStyles, copyAnimationApi] = useSpring(() => ({
    from: { opacity: 1 },
    reset: true,
    config: {
      duration: 200,
    },
  }));
  const [copiedAnimationStyles, copiedAnimationApi] = useSpring(() => ({
    from: { opacity: 1 },
    reset: true,
    config: {
      duration: 200,
    },
  }));

  const handleCopy = async () => {
    await copy();
    copyAnimationApi.start({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      onRest() {
        setIsCopied(true);
        copiedAnimationApi.start({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        });
      },
    });
  };

  const handleMouseLeave = () => {
    setIsMouseLeave(true);
  };

  const handleMouseOver = () => {
    if (isMouseLeave) {
      copiedAnimationApi.start({
        from: {
          opacity: 1,
        },
        to: {
          opacity: 0,
        },
        onRest() {
          setIsCopied(false);
          setIsMouseLeave(false);
          copyAnimationApi.start({
            from: {
              opacity: 0,
            },
            to: {
              opacity: 1,
            },
          });
        },
      });
    }
  };

  const copyButton = (
    <IconButton
      variant="text"
      size="sm"
      className="h-[25px]"
      onClick={handleCopy}
      onMouseLeave={handleMouseLeave}
    >
      <ClipboardDocumentIcon className="text-white h-[20px]" />
    </IconButton>
  );

  return (
    <InputField
      readOnly
      label={label}
      value={text}
      className="text-view"
      disabled
      icon={
        !disableCopy ? (
          <>
            {!isCopied && (
              <animated.div style={copyAnimationStyles}>
                {copyTitle && <Tooltip content={copyTitle}>{copyButton}</Tooltip>}
                {!copyTitle && copyButton}
              </animated.div>
            )}
            {isCopied && (
              <animated.div style={copiedAnimationStyles}>
                <IconButton
                  variant="text"
                  size="sm"
                  className="h-[25px]"
                  onMouseOver={handleMouseOver}
                  onMouseLeave={handleMouseLeave}
                >
                  <ClipboardDocumentCheckIcon className="text-white h-[20px]" />
                </IconButton>
              </animated.div>
            )}
          </>
        ) : undefined
      }
    />
  );
};

export default React.memo(TextView);
