import { IApiKeyPayload } from '@api/api-keys/api-keys-api.interfaces';
import { BaseApi } from '@api/core/base.api';
import { I2FAOtp } from '@api/two-factor-auth/two-factor-auth.interfaces';
import endpoints from '@config/endpoints';
import { IApiKey, IApiKeyWithSecret } from '@interfaces/api-key';

export class ApiKeysApi extends BaseApi {
  public getAll() {
    return this.client.get<IApiKey[]>(endpoints.API_KEYS.INDEX);
  }

  public getById(id: number) {
    return this.client.get<IApiKey>(`${endpoints.API_KEYS.INDEX}/${id}`);
  }

  public create(payload: IApiKeyPayload) {
    return this.client.post<IApiKeyPayload, IApiKeyWithSecret>(endpoints.API_KEYS.INDEX, payload);
  }

  public update(id: number, payload: IApiKeyPayload) {
    return this.client.put<IApiKeyPayload, IApiKey>(`${endpoints.API_KEYS.INDEX}/${id}`, payload);
  }

  public async delete(id: number, otps: I2FAOtp[]) {
    return this.client.delete(`${endpoints.API_KEYS.INDEX}/${id}`, {
      otps,
    });
  }
}
