export const pathToUrl = <P extends { [key: string]: string | number }>(
  path: string,
  params: P
) => {
  return Object.keys(params || {}).reduce<string>((result, key) => {
    const param = params[key as keyof typeof params];

    if (param) {
      result = result.split(`:${key}`).join(param.toString());
    }

    return result;
  }, path);
};
