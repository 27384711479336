import { IApiAppErrorResponse } from '@api/api.interfaces';
import { ISignIn2FAPayload } from '@api/auth/auth-api.interfaces';
import { ApiError } from '@api/core/api.error';
import { authApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { IUser } from '@interfaces/user';
import { useMutation } from 'react-query';

export const useSignIn2FAMutation = () => {
  const { setUser } = useAuth();

  return useMutation<IUser, ApiError<IApiAppErrorResponse>, ISignIn2FAPayload>({
    mutationFn: (data: ISignIn2FAPayload) => authApi.signIn2FA(data),
    onSuccess: setUser,
  });
};
