import React, { useMemo, useState } from 'react';

import { Table } from '@components/Common';
import Section from '@components/Common/Section';
import { SelectField } from '@components/Form';
import HistoryRecord from '@components/History/HistoryRecord';
import { HistoryDetailsModal } from '@components/Modals';
import { useMd } from '@hooks/responsive/useMd';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { IHistory } from '@interfaces/history';
import { IOption } from '@interfaces/option';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import { OPERATIONS_TYPES } from '../../constants';
import { historyTypes, IHistoryTypeOption } from '../../data/history-types';
import { roundAmount } from '../../utils/amount';
import { renderAmount, renderHistoryStatus, renderHistoryTitle } from '../../utils/renderers';

type PropsType = {
  data: IHistory[];
  historyType: IHistoryTypeOption;
  setHistoryType: (data: IOption<OPERATIONS_TYPES>) => void;
  isLoading?: boolean;
  pagination: IPaginationData;
};

const columnHelper = createColumnHelper<IHistory>();

const HistoryTable: React.FC<PropsType> = ({
  data,
  setHistoryType,
  historyType,
  isLoading,
  pagination,
}) => {
  const { t } = useText();
  const isMd = useMd();
  const [activeRecord, setActiveRecord] = useState<IHistory | null>(null);

  const columns = useMemo(() => {
    if (!isMd) {
      return [
        columnHelper.display({
          id: 'history',
          header: () => t('history.index'),
          cell: (info) => <HistoryRecord history={info.row.original} />,
        }),
      ];
    }

    return [
      columnHelper.accessor('type', {
        header: () => t('history.type'),
        cell: (info) => (
          <Typography className="font-semibold text-sm whitespace-nowrap">
            {renderHistoryTitle(info.getValue())}
          </Typography>
        ),
      }),
      columnHelper.accessor('status', {
        header: () => t('history.status'),
        cell: (info) => renderHistoryStatus(info.getValue()),
      }),
      columnHelper.accessor('createdAt', {
        header: () => t('history.date'),
        cell: (info) => (
          <Typography className="font-semibold text-sm whitespace-nowrap">
            {format(new Date(info.getValue()), 'MM/dd/yyyy H:mm')}
          </Typography>
        ),
      }),
      columnHelper.display({
        id: 'amount',
        header: () => t('history.amount'),
        cell: (info) => {
          const { amount, asset } = info.row.original;

          if (!amount || !asset) {
            return null;
          }

          return (
            <Typography className="font-semibold text-sm whitespace-nowrap">
              {renderAmount(roundAmount(amount, 5), asset)}
            </Typography>
          );
        },
      }),
    ];
  }, [data, isMd]);

  const handleDetailsClose = () => {
    setActiveRecord(null);
  };

  return (
    <Section>
      <div className="w-full flex justify-end mb-6">
        <SelectField
          label={t('history.type')}
          className="md:max-w-[200px]"
          onChange={setHistoryType}
          value={historyType}
          options={historyTypes}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        emptyText={t('history.empty')}
        rowClassName="hover:from-blue-500 hover:to-blue-400 hover:bg-gradient-to-tr cursor-pointer"
        onRowClick={setActiveRecord}
        onPageChange={pagination.setPage}
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        itemsCount={pagination.totalCount || 0}
        isLoading={isLoading}
        witPagination
      />
      <HistoryDetailsModal record={activeRecord} onClose={handleDetailsClose} />
    </Section>
  );
};

export default React.memo(HistoryTable);
