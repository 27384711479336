import { walletApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useQuery } from 'react-query';

import { COIN_TYPES } from '../../constants';

export const useCoinsQuery = (type: COIN_TYPES, enabled?: boolean) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery(['coins', type], () => walletApi.getCoins(type), {
    enabled,
    onError: () => notify(t('errors.common')),
  });
};
