import React from 'react';

import routes from '@config/routes';
import { useAuth } from '@hooks/useAuth';
import { Navigate } from 'react-router-dom';

interface ICondition {
  isAuth: boolean;
}

export interface IAuthGuardOptions {
  condition?: Partial<ICondition>;
  redirectTo?: string;
}

const defaultOption: IAuthGuardOptions = {
  condition: {
    isAuth: true,
  },
  redirectTo: routes.LOGIN,
};

export default function withAuthGuard<P extends {}>({
  condition,
  redirectTo = '',
}: IAuthGuardOptions = defaultOption) {
  return (Component: React.ComponentType): React.FC<P> => {
    return (props) => {
      const { isAuth } = useAuth();

      if (condition?.isAuth !== isAuth) {
        return <Navigate to={redirectTo} />;
      }

      return <Component {...props} />;
    };
  };
}
