import React from 'react';

import Section from '@components/Common/Section';
import { ReferralsHistoryTable } from '@components/Referral/index';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { IReferralsHistory } from '@interfaces/referrals';
import { IconButton } from '@material-tailwind/react';

type PropsType = {
  historyMode: boolean;
  disableHistoryMode: () => void;
  referralsHistory: IReferralsHistory[];
  isLoading?: boolean;
  pagination: IPaginationData;
};

const ReferralsHistoryBlock: React.FC<PropsType> = ({
  referralsHistory,
  disableHistoryMode,
  historyMode,
  isLoading,
  pagination,
}) => {
  return (
    <div>
      {historyMode && (
        <Section>
          <div className="h-[44px]">
            <IconButton variant="text" color="blue" onClick={disableHistoryMode}>
              <ChevronLeftIcon className="w-8" />
            </IconButton>
          </div>
          <ReferralsHistoryTable
            data={referralsHistory}
            isLoading={isLoading}
            pagination={pagination}
          />
        </Section>
      )}
    </div>
  );
};

export default React.memo(ReferralsHistoryBlock);
