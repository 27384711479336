import React from 'react';

import PasswordField, {
  PasswordFieldPropsType,
} from '@components/Form/Fields/PasswordField/PasswordField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

const PasswordFieldController: ControllerComponent<PasswordFieldPropsType> = ({
  name,
  control,
  ...props
}) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <PasswordField {...props} {...field} />}
  />
);

PasswordFieldController.isController = true;

export default PasswordFieldController;
