import { referralApi } from '@api/index';
import { IReferralForm } from '@components/Referral/ReferralsForm';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

export const useReferralInviteMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (data: IReferralForm) => referralApi.inviteReferral(data),
    onError: () => notify(t('errors.common')),
    onSuccess: () => notify(t('referrals.inviteSuccess')),
  });
};
