import React, { useMemo, useState } from 'react';

import { LoadingButton } from '@components/Common';
import { NumberField } from '@components/Form';
import Modal from '@components/Modals/Modal';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { use2Xl } from '@hooks/responsive/use2Xl';
import { useAuth } from '@hooks/useAuth';
import { useText } from '@hooks/useText';
import { Alert, Button, Typography } from '@material-tailwind/react';
import cn from 'classnames';

import { OTP_TYPES } from '../../constants';

type PropsType = {
  types: OTP_TYPES[];
  isOpen: boolean;
  onConfirm?: (codes: Partial<Record<OTP_TYPES, string>>) => void;
  onCancel: () => void;
  isVerifying?: boolean;
  isLoading?: boolean;
  errors?: Partial<Record<OTP_TYPES, string>>;
};

const DIGITS_COUNT = 6;

const OtpModal: React.FC<PropsType> = ({
  types,
  isOpen,
  isVerifying,
  isLoading,
  onConfirm,
  onCancel,
  errors,
}) => {
  const { t } = useText();
  const { user } = useAuth();
  const [codes, setCodes] = useState<Partial<Record<OTP_TYPES, string>>>({});
  const is2XL = use2Xl();
  const isSingleType = types.length === 1;

  const getLabelByType = (type: OTP_TYPES) => {
    switch (type) {
      case OTP_TYPES.AUTHENTICATOR:
        return t('otp.authenticator', { count: DIGITS_COUNT });
      case OTP_TYPES.EMAIL:
        return t('otp.email', { email: user?.email, count: DIGITS_COUNT });
      default:
        return '';
    }
  };

  const getTitle = () => {
    if (isSingleType) {
      return getLabelByType(types[0]);
    }

    return t('common.confirmAction');
  };

  const isValid = useMemo(() => {
    const values = Object.values(codes);

    if (!values.length) {
      return false;
    }

    return values.every((code) => code.length === DIGITS_COUNT);
  }, [codes]);

  const handleConfirm = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();

    if (onConfirm && isValid) {
      onConfirm(codes);
      setCodes({});
    }
  };

  const handleChange = (type: OTP_TYPES) => (value: string) => {
    setCodes((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onCancel}
      title={getTitle()}
      footer={
        <div className="flex gap-3">
          <Button variant="text" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <LoadingButton isLoading={isVerifying} onClick={handleConfirm} disabled={!isValid}>
            {t('common.confirm')}
          </LoadingButton>
        </div>
      }
      size={is2XL ? 'xs' : 'sm'}
    >
      {isSingleType && errors && errors[types[0]] && (
        <Alert
          icon={<ExclamationTriangleIcon className="text-amber-500 w-[24px]" />}
          variant="gradient"
          color="gray"
          className="mb-7 !text-amber-500 font-semibold"
        >
          {errors[types[0]]}
        </Alert>
      )}
      <form onSubmit={handleConfirm}>
        <div className={cn('mb-3', !isSingleType && 'flex flex-col gap-5')}>
          {types.map((type, index) => (
            <div key={type}>
              {!isSingleType && (
                <Typography variant="small" className="font-medium mb-2">
                  {getLabelByType(type)}
                </Typography>
              )}
              <NumberField
                label={t('otp.code')}
                maxDigits={6}
                value={codes[type] || ''}
                error={errors && errors[type]}
                onChange={handleChange(type)}
                hideError={isSingleType}
                autoFocus={index === 0}
              />
            </div>
          ))}
        </div>
      </form>
    </Modal>
  );
};

export default React.memo(OtpModal);
