import React from 'react';

import { Section, TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import PnlsChart from '@components/Statistic/PnlsChart';
import StatisticHeading from '@components/Statistic/StatisticHeading';
import { IPeriod } from '@interfaces/period';
import { IPnls } from '@interfaces/pnl';

type PropsType = TabPropsType & {
  period: IPeriod;
  pnls?: IPnls;
  isLoading: boolean;
  onPeriodChange: (period: IPeriod) => void;
  isPnlWithFee: boolean;
  setIsPnlWithFee: (checked: boolean) => void;
};

const PAndLTab: React.FC<PropsType> = ({
  period,
  onPeriodChange,
  pnls,
  isLoading,
  isPnlWithFee,
  setIsPnlWithFee,
}) => {
  if (isLoading) {
    return <Preloader />;
  }

  if (!pnls) {
    return null;
  }

  return (
    <Section>
      <StatisticHeading
        pnls={pnls}
        isPnlWithFee={isPnlWithFee}
        setIsPnlWithFee={setIsPnlWithFee}
        period={period}
        onPeriodChange={onPeriodChange}
      />
      <PnlsChart data={pnls.byDays} period={period} />
    </Section>
  );
};

export default React.memo(PAndLTab);
