import React from 'react';

import { Pagination } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { Typography } from '@material-tailwind/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  TableMeta,
  useReactTable,
} from '@tanstack/react-table';
import { Row } from '@tanstack/table-core/build/lib/types';
import cn from 'classnames';

type PropsType = {
  columns: ColumnDef<any, any>[];
  data: any[];
  meta?: TableMeta<any>;
  emptyText?: string;
  rowClassName?: string;
  getRowId?: (originalRow: any, index: number, parent?: Row<any>) => string;
  witPagination?: boolean;
  itemsPerPage?: number;
  page?: number;
  itemsCount?: number;
  isLoading?: boolean;
  onRowClick?: (row: any) => void;
  onPageChange?: (page: number) => void;
};

const Table: React.FC<PropsType> = ({
  data,
  columns,
  meta,
  emptyText = 'No data',
  rowClassName,
  getRowId,
  witPagination,
  itemsPerPage = 10,
  page,
  itemsCount = data.length,
  isLoading,
  onRowClick,
  onPageChange,
}) => {
  const table = useReactTable({
    data,
    columns,
    meta,
    pageCount: data.length,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowClick = (row: any) => () => {
    if (onRowClick) {
      onRowClick(row);
    }
  };

  if (!data.length) {
    return (
      <Typography
        variant="paragraph"
        className="py-10 font-semibold text-center w-full min-h-[100px] md:min-h-[350px] flex items-center justify-center"
      >
        {emptyText}
      </Typography>
    );
  }

  return (
    <div className="relative min-h-[100px] md:min-h-[350px] flex flex-col justify-between">
      <table className={cn('w-full min-w-max table-auto text-left', isLoading && 'opacity-0')}>
        <thead>
          {table.getHeaderGroups().map((group) => (
            <tr key={group.id}>
              {group.headers.map((header) => (
                <th
                  key={header.id}
                  className={cn(
                    'border-b border-gray-600 p-4',
                    header.column.columnDef.meta?.className,
                  )}
                  style={{
                    minWidth: header.getSize(),
                    width: header.getSize(),
                    maxWidth: header.getSize(),
                  }}
                >
                  <Typography variant="small" className="font-semibold whitespace-nowrap">
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </Typography>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, index, array) => {
            const isLast = index === array.length - 1;
            const classes = isLast ? 'p-4' : 'p-4 border-b border-gray-600';

            return (
              <tr key={row.id} className={rowClassName} onClick={handleRowClick(row.original)}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={cn(classes, cell.column.columnDef.meta?.className)}
                    style={{
                      minWidth: cell.column.getSize(),
                      width: cell.column.getSize(),
                      maxWidth: cell.column.getSize(),
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isLoading && (
        <div className="absolute left-0 top-0 w-full h-full flex">
          <Preloader />
        </div>
      )}
      {witPagination && itemsCount > itemsPerPage && (
        <div className="flex justify-center mt-4">
          <Pagination
            itemsCount={itemsCount}
            itemsPerPage={itemsPerPage}
            page={page}
            onPageChange={onPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Table);
