export enum QUERIES {
  API_KEYS = 'api-keys',
  API_KEY = 'api-key',
  ORDERS = 'orders',
  POSITIONS = 'positions',
  FUTURES_BALANCE = 'futures-balance',
  CASHBACK_HISTORY = 'cashback-history',
  CASHBACK_ESTIMATE = 'cashback-estimate',
  CASHBACK_TOTAL = 'cashback-total',
  CASHBACK_RATES = 'cashback-rates',
  FUTURES_PAIRS = 'futures-pairs',
  FUTURES_PNL_FOR_TODAY = 'futures-pnl-for-today',
  FUTURES_PNLS = 'futures-pnls',
  FUTURES_DEALS = 'futures-deals',
  FUTURES_DEALS_SYNC = 'futures-deals-sync',
  BURN_STATUS = 'burn-status',
  EXCHANGE_ACCOUNT = 'exchange-account',
  TWO_FACTOR_AUTH_REGISTER_DATA = 'two-factor-auth-register-data',
  TWO_FACTOR_AUTH_TYPE = 'two-factor-auth-type',
  HISTORY = 'history',
  SPOT_BALANCE = 'spot-balance',
  DOCUMENTS = 'documents',
  INVITE_URL = 'invite-url',
  REFERRALS = 'referrals',
  REFERRALS_HISTORY = 'referrals-history',
  REFERRALS_TOTAL = 'referrals-total',
  REFERRALS_CODES = 'referrals-codes',
}
