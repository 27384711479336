export const quotesAdvices = [
    "«Сколько вы знаете миллионеров, которые разбогатели, вкладывая средства в сберегательные счета? Я все сказал.» — Роберт Г. Аллен",
    "«Я буду продолжать уменьшать свой торговый объем до тех пор, пока я проигрываю… Мои методы управления капиталом чрезвычайно консервативны. Я никогда не рискую ничем, что приближается к общей сумме денег на моем счете, не говоря уже о моих общих средствах» — Рэнди Маккей",
    "«Не то, что мы делаем время от времени, определяет нашу жизнь, а то, что мы делаем постоянно» — Тони Роббинс",
    "«Фондовый рынок заполнен людьми, которые знают цену всему, но ничего не ценят» — Филипп Фишер",
    "«Я очень, очень обеспокоен, когда вижу торговые стратегии со слишком большим количеством правил (вы тоже должны)» — Ларри Коннорс",
    "«Инвестиции в знания приносят наибольший доход» — Бенджамин Франклин",
    "«Выигрывают или проигрывают, каждый получает от рынка то, что хочет. Некоторым людям нравится проигрывать, поэтому они выигрывают, теряя деньги» — Эд Сейкота",
    "«Я не думаю, что вы можете стать хорошим инвестором в широком диапазоне, не читая много. Я не думаю, что какая-то одна книга даст вам всё необходимое» — Чарли Мангер",
    "«Инвестирование должно быть больше похоже на наблюдение за тем, как сохнет краска или растёт трава. Если вы хотите азарта, возьмите 800$ и отправляйтесь в Лас-Вегас» — Пол Самуэльсон",
    "«В этом деле если ты хорош, ты прав в шести случаях из десяти. Ты никогда не будешь прав в девяти случаях из десяти» — Питер Линч",
    "«Готовы ли вы потерять деньги на сделке? Если нет, то не открывайте. Вы можете выиграть, только если не боитесь проиграть. И вы сможете сделать это только в том случае, если действительно примете стоящий перед вами риск» — Сами Абусаад",
    "«В трейдинге невозможное случается примерно два раза в год» — Энрике М. Симойнс",
    "«Рынок – это устройство для передачи денег от нетерпеливого к терпеливому» — Уоррен Баффет",
    "«Вы можете быть свободны. Вы можете жить и работать в любой точке мира. Вы можете быть независимы от рутины и ни перед кем не отчитываться» — Александр Элдер",
    "«Я верю в анализ, а не в прогнозирование» — Николас Дарвас",
    "«Краткосрочная волатильность максимальна в поворотные моменты и уменьшается по мере становления тренда» — Джордж Сорос",
    "«Трейдинг не только раскрывает ваш характер, но и укрепляет его, если вы остаетесь в игре достаточно долго» — Иван Бьяджи",
    "«Научитесь принимать убытки. Самое главное в зарабатывании денег – не позволить убыткам выйти из-под контроля» — Мартин Шварц",
    "«То, что кажется слишком высоким и рискованным большинству, обычно идет выше, а то, что кажется низким и дешевым, обычно идет ниже» — Уильям О’Нил",
    "«Быки делают деньги, медведи делают деньги, свиней забивают» — Неизвестный автор",
    "«Вам не нужно быть ученым-ракетчиком. Инвестирование – это не та игра, в которой парень с IQ 160 побеждает парня с IQ 130» — Уоррен Баффет",
    "«Ошибаться — это нормально; непростительно остаться неправым» — Мартин Цвейг",
    "«Есть время покупать, есть время продавать и есть время ловить рыбу» — Джесси Ливермор",
    "«Каждый день я предполагаю, что каждая моя позиция ошибочна» — Пол Тюдор Джонс",
    "«В краткосрочной перспективе рынок является машиной для голосования, но в долгосрочной – это устройство для взвешивания» — Бенджамин Грэм",
    "«Мы просто пытаемся бояться, когда другие жадны, и быть жадными только тогда, когда другие напуганы» — Уоррен Баффет",
    "«Иногда лучшая сделка — это отсутствие сделки» — Неизвестный автор",
    "«Самое сложное – это дисциплина, терпение и рассудительность» — Сет Кларман",
    "«На протяжении всей своей финансовой карьеры я постоянно наблюдал примеры того, как другие люди, которых я знал, разорялись из-за неуважения к риску. Если вы не будете внимательно смотреть на риск, он поймает вас» — Ларри Хайт",
    "«Акции покупаются не в страхе, а в надежде. Обычно их продают из страха» — Джастин Мамис",
    "«Я думаю, что инвестиционная психология является гораздо более важным элементом, за которым следует контроль риска, причем наименее важным является вопрос о том, где вы покупаете и продаете» — Том Бассо",
    "«Требуется 20 лет, чтобы создать репутацию, и пять минут, чтобы разрушить ее. Если вы подумаете об этом, вы сделаете все по-другому» — Уоррен Баффет",
    "«Не пытайтесь предвидеть и не двигайтесь без подтверждения рынка, небольшое опоздание в вашей сделке – это ваша страховка, что вы правы или не правы» — Джесси Ливермор",
    "«Рынки постоянно находятся в состоянии неопределенности и текучести, а деньги делаются путем дисконтирования очевидного и ставок на неожиданное» — Джордж Сорос",
    "«Деньги, если они не приносят вам счастья, по крайней мере, помогут вам чувствовать себя несчастным в комфорте» — Хелен Герли Браун",
    "«Смотрите на колебания рынка как на своего друга, а не врага» — Уоррен Баффет",
    "«Существует огромная разница между хорошей сделкой и хорошей торговлей» — Стив Бёрнс",
    "«Время – ваш друг; импульс – ваш враг» — Джон Богл",
    "«Честно говоря, я не вижу рынков; я вижу риски, выгоду и деньги» — Ларри Хайт",
    "«Одна из забавных вещей фондового рынка заключается в том, что каждый раз, когда один человек покупает, другой продает, и оба думают, что они проницательны» — Уильям Фезер",
    "«Основная проблема, однако, заключается в необходимости приспосабливать рынки к стилю торговли, а не находить способы торговли, соответствующие рыночному поведению» — Бретт Стинбаргер",
    "«Сосредоточенность, терпение, мудрая проницательность, непривязанность — навыки, которые вы приобретаете в медитации, и навыки, необходимые для процветания в торговле, являются одними и теми же» — Иван Бьяджи",
    "«Многие люди настолько запутываются в рынках, что теряют перспективу. Работать дольше не обязательно означает работать умнее. На самом деле, иногда бывает наоборот» — Мартин Шварц",
    "«Допустить убытки — самая серьезная ошибка большинства инвесторов» — Уильям О’Нил",
    "«Вы должны определить свои слабые стороны и работать, чтобы измениться. Ведите торговый дневник – записывайте причины входа и выхода из каждой сделки. Ищите повторяющиеся паттерны успеха и неудачи» — Александр Элдер",
    "«Извлекайте выгоду из глупости, а не участвуйте в ней» — Уоррен Баффет",
    "«Элементы хорошей торговли — это сокращение потерь, сокращение потерь и сокращение потерь. Если вы сможете следовать этим трем правилам, у вас может появиться шанс» — Эд Сейкота",
    "«Цель успешного трейдера – совершать лучшие сделки. Деньги являются вторичными» — Александр Элдер",
    "«Стремление к постоянному действию, независимо от основных условий, несет ответственность за многие потери на Уолл-стрит даже среди профессионалов, которые чувствуют, что они должны брать домой немного денег каждый день, как если бы они работали за регулярную зарплату» — Джесси Ливермор",
    "«Всю математику, необходимую на фондовом рынке, вы получили в четвертом классе» — Питер Линч",
    "«Я просто жду, пока в углу появятся деньги, и все, что мне нужно сделать — это пойти туда и забрать их. А пока я ничего не делаю» — Джим Роджерс",
    "«Если вы персонализируете убытки, вы не сможете торговать» — Брюс Ковнер",
    "«На самом деле никто не знает, что будет делать рынок; пытаться предсказать это — пустая трата времени, а инвестирование, основанное на этом предсказании — спекулятивное мероприятие» — Сет Кларман",
    "«Потери необходимы до тех пор, пока они связаны с техникой, которая поможет вам учиться на них» — Давид Сихосана",
    "«Самые опасные слова в инвестировании: На этот раз все по-другому» — Джон Темплтон",
    "«Деньги делаются сидя, а не торгуя» — Джесси Ливермор",
    "«Трейдерам нужен распорядок дня, который им нравится. Если вам это не нравится, вы не будете этого делать» — Скотт Редлер",
    "«Важно не то, правы вы или нет, важно то, сколько денег вы зарабатываете, когда вы правы, и сколько теряете, когда ошибаетесь» — Джордж Сорос",
    "«Никогда не вкладывайте деньги в идею, которую вы не можете проиллюстрировать карандашом» — Питер Линч",
    "«Паттерны не работают в 100% случаев. Но они по-прежнему важны, потому что помогают вам определить свой риск. Если вы игнорируете шаблоны и сосредотачиваетесь на интуиции, чувствах и горячих советах, просто забудьте о достижении последовательности» — Ифань Вэй",
    "«В трейдинге/инвестировании речь идет не о том, сколько вы зарабатываете, а о том, сколько вы не теряете» — Бернард Барух",
    "«Секрет успеха с торговой точки зрения — это неутомимая, неугасающая и вечная жажда информации и знаний» — Пол Тюдор Джонс",
    "«Уверенность — это не «Я получу прибыль от этой сделки». Уверенность — это «Я буду в порядке, если не получу прибыль от этой сделки» — Иван Бьяджи",
    "«Чистая воля и решимость не заменят того, что действительно работает» — Джейсон Клатт",
    "«Когда я стал победителем, я сказал: «Я понял это, но если я ошибаюсь, я убираюсь к черту, потому что хочу сэкономить свои деньги и перейти к следующей сделке» — Мартин Шварц",
    "«Если бы большинство трейдеров научились сидеть сложа руки в 50% случаев, они заработали бы гораздо больше денег» — Билл Липшуц",
    "«Колебания цен на акции фактически начинают отражать новые события еще до того, как их признают» — Артур Зейкель",
    "«Я рано понял, что на Уолл-стрит нет ничего нового. Этого не может быть, потому что спекуляция стара, как холмы. Что бы ни происходило сегодня на фондовом рынке, это уже случалось и будет происходить снова. Я никогда этого не забуду» — Джесси Ливермор",
    "«Рынки могут оставаться иррациональными дольше, чем вы можете оставаться платежеспособными» — Джон Мейнард Кейнс",
    "«Начинающие трейдеры торгуют в 5-10 раз больше. Они принимают от 5 до 10 процентов риска, а в торговле они должны принимать от 1 до 2 процентов риска» — Брюс Ковнер",
    "«Мы хотим воспринимать себя победителями, но успешные трейдеры всегда фокусируются на своих потерях» — Питер Бориш",
    "«Все, что вам нужно, — это один паттерн, чтобы зарабатывать на жизнь» — Линда Рашке",
    "«Насколько это возможно, вы не должны рассчитывать на высокую оплату только за то, что вы идёте на большой риск. С этим может справиться любой. Вы должны рассчитывать на высокую оплату из-за того, что вы сделали домашнее задание» — Джоэл Гринблатт",
    "«Тренд — ваш друг до самого конца, пока он не согнется» — Эд Сейкота",
    "«Торговля очень конкурентоспособна, и вы должны быть в состоянии справиться с тем, чтобы получить пинок под зад» — Пол Тюдор Джонс",
    "«В инвестировании то, что удобно, редко бывает прибыльным» — Роберт Арнотт",
    "«Опасность наблюдения за каждым тиком двоякая: чрезмерная торговля и повышенные шансы преждевременно ликвидировать хорошие позиции» — Джек Шваггер",
    "«Принятие убытков является наиболее важным единым инвестиционным инструментом для обеспечения сохранности капитала» — Джеральд М. Леб",
    "«Надежда-это фальшивая эмоция, которая только стоит вам денег» — Джим Крэмер",
    "«Вы никогда не знаете, какую установку вам предложит рынок, ваша цель должна состоять в том, чтобы найти возможность, где соотношение риска и вознаграждения будет наилучшим» — Джеймин Шах",
    "«Вы никогда не найдете удовлетворения, торгуя на рынках, если не научитесь ценить и быть удовлетворенными тем, что у вас уже есть» — Иван Бьяджи",
    "«В чем разница между профессионалом и любителем? Профессионалы ищут, что не так с сетапом. Любители ищут только то, что правильно» — Марк Харила",
    "«Торгуйте на рынке перед вами, а не на том, который вам нравится» — Скотт Редлер",
    "«Я знаю, где выйду, еще до того, как войду» — Брюс Ковнер",
    "«Эта торговля хлопком была для меня чуть ли не решающим фактором. Именно тогда я сказал: «Мистер Глупый, зачем рисковать всем в одной сделке? Почему бы не сделать свою жизнь стремлением к счастью, а не к боли?» — Пол Тюдор Джонс",
    "«Никогда, никогда не спорьте с вашей торговой системой» — Майкл Ковел",
    "«Торгуйте тем, что происходит… а не тем, что, как вы думаете, произойдет» — Даг Грегори",
    "«Фундаментальный закон инвестирования — это неопределенность будущего» — Питер Бернштейн",
    "«Делайте больше того, что работает, и меньше того, что не работает» — Стив Кларк",
    "«Если вы научитесь создавать такое состояние ума, которое не зависит от поведения рынка, борьба прекратится» — Марк Дуглас",
    "«Я всегда смеюсь над людьми, которые говорят: «Я никогда не встречал богатого техника». Мне это нравится! Такой высокомерный, бессмысленный ответ. Я использовал фундаментный анализ в течение девяти лет и разбогател как технический специалист» — Мартин Шварц",
    "«Не следуйте слепо за кем-то, следите за рынком и пытайтесь услышать, что он вам говорит» — Джеймин Шах",
    "«Покупайте то, что растёт. Продавайте то, что идёт вниз. А когда они остановятся, уходите» — Роб Смит",
    "«Бычьи рынки рождаются на пессимизме, растут на скептицизме, созревают на оптимизме и умирают от эйфории» — Джон Темплтон",
    "«Я всегда думаю о потере денег, а не о том, как их заработать. Не сосредотачивайтесь на зарабатывании денег, сосредоточьтесь на защите того, что у вас есть» — Пол Тюдор Джонс",
    "«Я всегда определяю свой риск, и мне не о чем беспокоиться» — Тони Салиба",
    "«Не смущайтесь своими неудачами, извлеките уроки из них и начните заново» — Ричард Брэнсон",
    "«Тот, кто знает, когда он может сражаться, а когда не может, победит» — Сунь Цзы",
    "«Деньги подобны навозу, вам не нужно разбрасывать их повсюду, вы можете просто продать их как удобрение» — Пол Гетти",
    "«Неважно, прав ты или ошибаешься. Важно лишь то, сколько денег зарабатываешь, когда прав, и сколько теряешь, когда ошибаешься» — Джордж Сорос"
];