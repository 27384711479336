import React from 'react';

import FormField, { FormFieldProps } from '@components/Form/FormField';
import { Checkbox, CheckboxProps } from '@material-tailwind/react';
import cn from 'classnames';

export type CheckboxFieldPropsType = FormFieldProps &
  Omit<CheckboxProps, 'error' | 'value'> & {
    value?: boolean;
  };

const CheckboxField: React.ForwardRefRenderFunction<HTMLInputElement, CheckboxFieldPropsType> = (
  { error, className, helperText, value, checked, ...props },
  ref,
) => {
  return (
    <FormField error={error} helperText={helperText} className={cn('w-auto', className)}>
      <Checkbox
        {...(props as any)}
        checked={Boolean(value || checked)}
        color="blue"
        inputRef={ref}
      />
    </FormField>
  );
};

export default React.memo(React.forwardRef(CheckboxField));
