import IPAddressesField, {
  IPAddressesFieldPropsType,
} from '@components/Form/Fields/IPAddressesField/IPAddressesField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

type PropsType = IPAddressesFieldPropsType;

const IPAddressesFieldController: ControllerComponent<PropsType> = ({
  name,
  control,
  ...props
}) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <IPAddressesField {...props} {...field} />}
  />
);

IPAddressesFieldController.isController = true;

export default IPAddressesFieldController;
