const ROUND_SYMBOLS: Record<number, number> = {
  2: 100,
  3: 1000,
  4: 10000,
  5: 100000,
  6: 1000000,
  7: 10000000,
  8: 100000000,
};

const getMultiplier = (num: number, symbols: number = 2) => {
  if (num >= 1000) {
    return ROUND_SYMBOLS[2];
  }

  return ROUND_SYMBOLS[symbols];
};

const createRoundFn =
  (transformer: (num: number) => number) =>
  (amount: string | number, symbols: number = 2) => {
    const number = typeof amount === 'string' ? parseFloat(amount) : amount;
    const multiplier = getMultiplier(number, symbols);
    const rounded = transformer(number * multiplier) / multiplier;

    return rounded.toString();
  };

export const roundAmount = createRoundFn(Math.round);

export const calcAmountToReceive = (amount: string, fee: string) => {
  const result = parseFloat(amount) - parseFloat(fee);

  return roundAmount(result, 5);
};
