import { pairsApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

interface IParams {
  pairs: string[];
  leverage: number;
}

const invalidateFuturesPairQuery = (variables: IParams) => {
  queryClient.setQueriesData<IFuturesPair[]>(QUERIES.FUTURES_PAIRS, (input) => {
    return (
      input?.map((pair) => {
        if (variables.pairs.includes(pair.symbol)) {
          return {
            ...pair,
            leverage: variables.leverage,
          };
        }

        return pair;
      }) || []
    );
  });
};

export const useUpdateLeverageMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: ({ pairs, leverage }: IParams) => pairsApi.updateLeverage(pairs, leverage),
    onMutate: (variables) => {
      invalidateFuturesPairQuery(variables);
    },
    onSuccess: () => {
      notify(t('leverage.leverageUpdateSuccess'), 'success');
    },
    onError: () => {
      notify(t('leverage.leverageUpdateFailed'), 'error');
    },
  });
};
