import React from 'react';

import AuthLayout from '@components/Layout/AuthLayout';
import SignUpForm, { ISignUpFormData } from '@components/SignUpForm/SignUpForm';
import { useAuth } from '@hooks/useAuth';
import { useQueryParam } from '@hooks/useQueryParam';
import { useText } from '@hooks/useText';

const SignUpPage = () => {
  const { t } = useText();
  const referralCode = useQueryParam('referralCode');
  const { signUp } = useAuth();

  const handleSubmit = async (data: ISignUpFormData) => {
    if (!data.password.password) {
      throw new Error('Password is required');
    }

    await signUp({
      email: data.email,
      password: data.password.password,
      referralCode: referralCode || undefined,
    });
  };

  return (
    <AuthLayout title={t('authLayout.welcome')} message={t('signUp.title')}>
      <SignUpForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

export default React.memo(SignUpPage);
