import React from 'react';

import AuthProvider from '@components/Providers/AuthProvider';
import HideModeProvider from '@components/Providers/HideModeProvider';
import { ThemeProvider } from '@material-tailwind/react';
import { queryClient } from '@query/index';
import 'rc-slider/assets/index.css';
import ReactDOM from 'react-dom/client';
import { QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';

import App from './App';
import './localization/i18n';
import reportWebVitals from './reportWebVitals';
import { theme } from './theme';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider value={theme}>
        <AuthProvider>
          <HideModeProvider>
            <App />
          </HideModeProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
