import React, { useEffect, useState } from 'react';

import { EnableTwoFactorAuthModal, OtpModal } from '@components/Modals';
import { useVerify2FAMutation } from '@hooks/mutations/useVerify2FAMutation';
import { useRegister2FAQuery } from '@hooks/queries/useRegister2FAQuery';

import { OTP_TYPES } from '../../constants';

type PropsType = {
  isActive: boolean;
  onFinish: () => void;
  onCancel: () => void;
};

enum STEPS {
  REGISTER = 1,
  VERIFY,
}

const Enable2FaFlow: React.FC<PropsType> = ({ isActive, onFinish, onCancel }) => {
  const [step, setStep] = useState<STEPS>(STEPS.REGISTER);

  const { data: registerData, isLoading: isRegistering } = useRegister2FAQuery(isActive);
  const { mutate: verify, isLoading: isVerifying } = useVerify2FAMutation(onFinish);

  useEffect(() => {
    setStep(STEPS.REGISTER);
  }, [isActive]);

  const handleNextStep = () => setStep((prev) => prev + 1);

  const handleConfirm = (codes: Partial<Record<OTP_TYPES, string>>) => {
    if (codes[OTP_TYPES.AUTHENTICATOR]) {
      verify(codes[OTP_TYPES.AUTHENTICATOR]);
    }
  };

  if (!isActive) {
    return null;
  }

  return (
    <>
      <EnableTwoFactorAuthModal
        isOpen={step === STEPS.REGISTER}
        isLoading={isRegistering}
        data={registerData}
        onReady={handleNextStep}
        onCancel={onCancel}
      />
      <OtpModal
        isOpen={step === STEPS.VERIFY}
        isVerifying={isVerifying}
        types={[OTP_TYPES.AUTHENTICATOR]}
        onConfirm={handleConfirm}
        onCancel={onCancel}
      />
    </>
  );
};

export default React.memo(Enable2FaFlow);
