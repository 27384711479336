import React from 'react';

import { HiddenContent, PrimaryInfoBlock, Section } from '@components/Common';
import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import { useHideMode } from '@hooks/useHideMode';
import { useText } from '@hooks/useText';
import { IconButton } from '@material-tailwind/react';
import cn from 'classnames';

import { roundAmount } from '../../utils/amount';

type PropsType = {
  className?: string;
  balance?: string;
};

const TotalBalance: React.FC<PropsType> = ({ className, balance = 0 }) => {
  const { t } = useText();
  const { toggle, enabled } = useHideMode();

  return (
    <Section contentClassName={cn(className, 'md:min-h-[138px]')}>
      <PrimaryInfoBlock
        title={
          <span className="flex w-full justify-between items-center">
            {t('wallet.total')}
            <IconButton variant="text" color="blue" onClick={toggle}>
              {!enabled && <EyeSlashIcon className="text-white h-5 w-5" />}
              {enabled && <EyeIcon className="text-white h-5 w-5" />}
            </IconButton>
          </span>
        }
        value={
          <>
            <HiddenContent hidden={enabled}>{roundAmount(balance)}</HiddenContent> USDT
          </>
        }
      />
    </Section>
  );
};

export default React.memo(TotalBalance);
