import { statisticApi } from '@api/index';
import { usePaginationQuery } from '@hooks/usePaginationQuery';
import { IPeriod } from '@interfaces/period';
import { QUERIES } from '@query/queries';

export const useFuturesDealsQuery = (period: IPeriod, enabled?: boolean) => {
  return usePaginationQuery(
    [QUERIES.FUTURES_DEALS, period],
    (query) =>
      statisticApi.getDeals({
        ...query,
        fromDate: period.fromDate.toISOString(),
        toDate: period.toDate.toISOString(),
      }),
    {
      enabled,
    },
  );
};
