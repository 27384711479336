import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { Alert } from '@material-tailwind/react';
import { Trans } from 'react-i18next';

type PropsType = {
  coin?: string;
  network?: string;
  tag?: string;
};

const WithdrawalAlert: React.FC<PropsType> = ({ coin, network, tag }) => {
  const { t } = useText();

  return (
    <div className="mb-7">
      <Alert
        variant="outlined"
        color="gray"
        icon={<InformationCircleIcon className="text-white w-[24px]" />}
      >
        {!(coin && network) && t('deposit.initialAlert')}
        {coin && network && (
          <Trans
            i18nKey="deposit.networkAlert"
            components={{ bold: <span className="font-bold text-blue-500"></span> }}
            values={{
              coinName: coin,
              networkName: network,
            }}
          />
        )}
      </Alert>
      {!!tag && (
        <Alert
          variant="outlined"
          color="gray"
          icon={<InformationCircleIcon className="text-white w-[24px]" />}
          className="mt-3"
        >
          {t('deposit.memoAlert')}
        </Alert>
      )}
    </div>
  );
};

export default React.memo(WithdrawalAlert);
