import React, { useEffect } from 'react';

import { API_ERROR_TYPES, API_VALIDATION_CODES } from '@api/api.constants';
import { LoadingButton } from '@components/Common';
import { Form, FormInputField } from '@components/Form';
import Modal from '@components/Modals/Modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreateReferralCodeMutation } from '@hooks/mutations/useCreateReferralCodeMutation';
import { setTextPath, useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { i18n } from '../../localization/i18n';

export interface IReferralLinkFormData {
  code: string;
}

type PropsType = {
  isOpen: boolean;
  onClose: () => void;
};

const FIELD_NAMES: { [key in keyof IReferralLinkFormData]: key } = {
  code: 'code',
};

const schema: yup.ObjectSchema<IReferralLinkFormData> = yup
  .object({
    code: yup
      .string()
      .required(setTextPath('errors.required'))
      .max(200, i18n.t('errors.maxLength', { max: 200 }))
      .min(4, i18n.t('errors.minLength', { min: 4 }))
      .matches(/^[a-zA-Z0-9]+$/, i18n.t('errors.alphaNumeric')),
  })
  .required();

const CreateReferralLinkModal: React.FC<PropsType> = ({ isOpen, onClose }) => {
  const { t } = useText();
  const {
    mutate: createReferralCode,
    isLoading: isCreating,
    error: createError,
    reset: resetMutation,
  } = useCreateReferralCodeMutation(onClose);

  const formMethods = useForm<IReferralLinkFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.reset();
    resetMutation();
  }, [isOpen]);

  const handleSubmit = (data: IReferralLinkFormData) => {
    createReferralCode(data);
  };

  const gerFormError = () => {
    if (!createError) {
      return undefined;
    }

    const { response } = createError;

    if (
      response?.code === API_ERROR_TYPES.VALIDATION_ERROR &&
      response.fields[FIELD_NAMES.code] &&
      response.fields[FIELD_NAMES.code].code === API_VALIDATION_CODES.REFERRAL_CODE_ALREADY_EXIST
    ) {
      return t('referrals.codeExist');
    }

    return t('referrals.failed');
  };

  return (
    <Modal
      title={t('referrals.createLinkExtended')}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className="flex gap-3">
          <Button variant="text" onClick={onClose}>
            {t('common.cancel')}
          </Button>
          <LoadingButton isLoading={isCreating} onClick={formMethods.handleSubmit(handleSubmit)}>
            {t('referrals.createLink')}
          </LoadingButton>
        </div>
      }
    >
      <Form
        formMethods={formMethods}
        error={gerFormError()}
        errorVariant="filled"
        onSubmit={handleSubmit}
      >
        <FormInputField name={FIELD_NAMES.code} label={t('referrals.code')} className="!mb-3" />
      </Form>
    </Modal>
  );
};

export default React.memo(CreateReferralLinkModal);
