import React, { useState } from 'react';

import { IPView } from '@components/Common';
import FormField, { FormFieldProps } from '@components/Form/FormField';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IconButton, Input, InputProps } from '@material-tailwind/react';
import { flowRight, property } from 'lodash';

export type IPAddressesFieldPropsType = FormFieldProps &
  Omit<InputProps, 'onChange' | 'value' | 'error'> & {
    onChange?: (value: string[]) => void;
    value?: string[];
  };

const ipv4RegExp =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const IPAddressesField: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IPAddressesFieldPropsType
> = ({ error, className, helperText, value = [], onChange, onBlur, ...props }, ref) => {
  const { t } = useText();
  const [values, setValues] = useState<string[]>(value);
  const [text, setText] = useState('');
  const [validationError, setValidationError] = useState<string | undefined>();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      handleAdd();
    }
  };

  const handleBlur = (e: React.FocusEvent<any>) => {
    if (onBlur) {
      onBlur(e);
    }

    handleAdd();
  };

  const validate = (text: string) => {
    const isExist = values.some((v) => v === text);

    if (isExist) {
      setValidationError(t('api.ipAlreadyExist'));

      return false;
    }

    const isValidFormat = ipv4RegExp.test(text);

    if (!isValidFormat) {
      setValidationError(t('api.ipValidationError'));
    }

    return isValidFormat;
  };

  const changeValues = (callback: (prev: string[]) => string[]) => {
    setValues((prev) => {
      const newValues = callback(prev);

      if (onChange) {
        onChange(newValues);
      }

      return newValues;
    });
  };

  const handleAdd = () => {
    if (!text) {
      return null;
    }

    const isValid = validate(text);

    if (!isValid) {
      return null;
    }

    setValidationError(undefined);
    changeValues((prev) => [...prev, text]);
    setText('');
  };

  const handleDelete = (text: string) => {
    changeValues((prev) => prev.filter((value) => value !== text));
  };

  const errorText = error || validationError;

  return (
    <FormField error={errorText} helperText={helperText} className={className}>
      <Input
        crossOrigin
        {...(props as any)}
        inputRef={ref}
        error={!!errorText}
        value={text}
        onChange={flowRight(setText, property('target.value'))}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        icon={
          <IconButton
            onClick={handleAdd}
            variant="text"
            size="sm"
            className="h-[25px]"
            color="blue"
          >
            <PlusIcon className="text-white h-[20px]" />
          </IconButton>
        }
      />
      {!!values.length && <IPView ips={values} onDelete={handleDelete} className="mt-4" />}
    </FormField>
  );
};

export default React.memo(React.forwardRef(IPAddressesField));
