import { walletApi } from '@api/index';
import { IWithdrawAssetPayload } from '@api/wallet/wallet-api.interfaces';
import routes from '@config/routes';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useWithdrawMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: IWithdrawAssetPayload) => walletApi.withdrawAsset(data),
    onSuccess: () => {
      notify(t('withdraw.success'), 'success');
      navigate(routes.WALLET);
    },
  });
};
