import React from 'react';

import {
  ArrowDownLeftIcon,
  ArrowUpRightIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/solid';

import { OPERATIONS_TYPES } from '../../constants';

type PropsType = {
  type: OPERATIONS_TYPES;
  className?: string;
};

const iconsMap: Record<OPERATIONS_TYPES, React.ForwardRefExoticComponent<any>> = {
  [OPERATIONS_TYPES.DEPOSIT]: ArrowDownLeftIcon,
  [OPERATIONS_TYPES.WITHDRAW]: ArrowUpRightIcon,
  [OPERATIONS_TYPES.TRANSFER]: ArrowsRightLeftIcon,
  [OPERATIONS_TYPES.DUST_TRANSFER]: ArrowsRightLeftIcon,
};

const OperationIcon: React.FC<PropsType> = ({ type, className }) => {
  const Icon = iconsMap[type];

  return <Icon className={className} />;
};

export default React.memo(OperationIcon);
