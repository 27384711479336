import { usersApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

import { LANGUAGES } from '../../constants';

export const useChangeLanguageMutation = () => {
  const { refreshUser } = useAuth();
  const { t } = useText();
  const { notify } = useNotification();

  return useMutation({
    mutationFn: (lng: LANGUAGES) => usersApi.changeLanguage(lng),
    onSuccess: async () => {
      await refreshUser();
    },
    onError: () => {
      notify(t('errors.updateLng'), 'error');
    },
  });
};
