import React, { useMemo } from 'react';

import { LoadingIconButton, Table } from '@components/Common';
import Position from '@components/Wallet/Position';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useLg } from '@hooks/responsive/useLg';
import { useDeleteAll } from '@hooks/useDeleteAll';
import { useText } from '@hooks/useText';
import { IPosition } from '@interfaces/position';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

import { roundAmount } from '../../utils/amount';
import { renderPNL, renderPositionSide } from '../../utils/renderers';

type PropsType = {
  positions: IPosition[];
  onClose: (symbols: string[]) => void;
  isDeleting?: boolean;
  symbolsToClose?: string[];
};

const columnHelper = createColumnHelper<IPosition>();

const OrdersTable: React.FC<PropsType> = ({ positions, symbolsToClose, onClose, isDeleting }) => {
  const { t } = useText();
  const isLg = useLg();

  const { deleteAll, isAllDeleting } = useDeleteAll(() => {
    const symbols = positions.map((p) => p.symbol);

    onClose(symbols);
  }, isDeleting);

  const columns = useMemo(() => {
    const actions = columnHelper.display({
      id: 'actions',
      header: () => (
        <LoadingIconButton
          variant="text"
          color="blue"
          tooltip={t('position.closeAll')}
          isLoading={isAllDeleting}
          onClick={deleteAll}
        >
          <TrashIcon className="text-white h-[18px]" />
        </LoadingIconButton>
      ),
      cell: (info) => {
        const isLoading =
          !isAllDeleting && isDeleting && symbolsToClose?.includes(info.row.original.symbol);

        return (
          <LoadingIconButton
            variant="text"
            color="blue"
            tooltip={t('position.close')}
            isLoading={isLoading}
            onClick={() => onClose([info.row.original.symbol])}
          >
            <TrashIcon className="text-white h-[18px]" />
          </LoadingIconButton>
        );
      },
      size: isLg ? 50 : 30,
    });

    if (!isLg) {
      return [
        columnHelper.display({
          id: 'order',
          header: () => t('position.position'),
          cell: (info) => <Position position={info.row.original} />,
        }),
        actions,
      ];
    }

    return [
      columnHelper.accessor('symbol', {
        header: () => t('position.pair'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {info.getValue()}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('side', {
        header: () => t('position.side'),
        cell: (info) => renderPositionSide(info.getValue()),
        size: 80,
      }),
      columnHelper.accessor('entryPrice', {
        header: () => t('position.price'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 120,
      }),
      columnHelper.accessor('quantity', {
        header: () => t('position.volume'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('liquidationPrice', {
        header: () => t('position.liquidation'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 120,
      }),
      columnHelper.display({
        id: 'pnl',
        header: () => t('position.pnl'),
        cell: (info) => renderPNL(info.row.original.pnl, info.row.original.pnlPercentage),
        size: 150,
      }),
      actions,
    ];
  }, [isAllDeleting, symbolsToClose, isDeleting, isLg, positions]);

  return <Table columns={columns} data={positions} emptyText={t('position.empty')} />;
};

export default React.memo(OrdersTable);
