import React from 'react';

import { useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';

type PropsType = {
  onRepeat: () => void;
};

const ResetPasswordRequestRepeat: React.FC<PropsType> = ({ onRepeat }) => {
  const { t } = useText();

  return (
    <div className="mt-4 flex justify-center">
      <Button onClick={onRepeat}>{t('resetPasswordRequest.sendEmailAgain')}</Button>
    </div>
  );
};

export default React.memo(ResetPasswordRequestRepeat);
