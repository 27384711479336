import React, { useState } from 'react';

import { ITab, LoadingButton } from '@components/Common';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { useXl } from '@hooks/responsive/useXl';
import { useText } from '@hooks/useText';
import { IWalletsBalance } from '@interfaces/balances';
import { IPnl } from '@interfaces/pnl';
import {
  Tab,
  TabPanel,
  Tabs as MaterialTabs,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react';
import cn from 'classnames';

import { WALLET_TYPE } from '../../constants';
import { getBalanceByWalletType } from '../../utils/get-balance-by-wallet-type';

import TotalBalance from './TotalBalance';
import WalletBalance from './WalletBalance';

type PropsType = {
  className?: string;
  tabs: ITab[];
  balance: IWalletsBalance;
  onCloseAll?: () => void;
  isAllClosing?: boolean;
  futuresPnl: IPnl;
};

const WalletLayout: React.FC<PropsType> = ({
  className,
  tabs,
  balance,
  onCloseAll,
  isAllClosing,
  futuresPnl,
}) => {
  const { t } = useText();
  const [activeTab, setActiveTab] = useState<string>(WALLET_TYPE.USDT_FUTURE);
  const isMd = useMd();
  const isXl = useXl();

  return (
    <MaterialTabs
      value={activeTab}
      className={cn('w-full overflow-visible', className)}
      orientation={isXl ? 'vertical' : 'horizontal'}
    >
      <TabsHeader
        className="bg-transparent w-full flex justify-between md:justify-start flex-wrap md:flex-nowrap flex-row xl:flex-col mb-3 md:mb-4 xl:mb-0 gap-3 items-center"
        indicatorProps={{
          className: 'bg-panelActive shadow-gray-900/20 shadow-md',
        }}
      >
        {isXl && (
          <li className="w-full pb-[10px]">
            <LoadingButton
              isLoading={isAllClosing}
              onClick={onCloseAll}
              className="w-full"
              contentClassName="flex items-center justify-between gap-2"
              color="gray"
            >
              {t('wallet.closeAll')} <XMarkIcon className="w-5" />
            </LoadingButton>
          </li>
        )}
        <li className="w-full">
          <TotalBalance balance={balance.total} />
          {(!isMd || isXl) && (
            <p className="text-white text-lg font-semibold mt-6 md:h-[125px] xl:h-auto">
              {t('wallet.wallets')}
            </p>
          )}
        </li>
        {tabs.map(({ label, value }) => {
          const isActive = value === activeTab;

          return (
            <Tab
              key={value}
              value={value}
              className={cn(
                'justify-start px-6 py-2 border border-transparent md:min-h-[138px] xl:min-h-[100%] w-full',
                !isActive && 'border border-border-nav rounded-xl',
              )}
              onClick={() => setActiveTab(value)}
            >
              <WalletBalance
                walletName={t(label as never)}
                showDailyProfit={value === WALLET_TYPE.USDT_FUTURE}
                balance={getBalanceByWalletType(value as WALLET_TYPE, balance)}
                pnl={futuresPnl}
                className="text-left"
              />
            </Tab>
          );
        })}
      </TabsHeader>
      <TabsBody className="xl:w-[74%] w-full overflow-visible">
        {tabs.map(({ value, content }) => {
          const isActive = value === activeTab;

          return (
            <TabPanel
              key={value}
              value={value}
              className={cn('py-0 px-0 md:px-4', !isActive && 'h-full overflow-hidden')}
            >
              {React.cloneElement(content as any, {
                isActive,
              })}
            </TabPanel>
          );
        })}
      </TabsBody>
    </MaterialTabs>
  );
};

export default React.memo(WalletLayout);
