import React from 'react';

import { TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { CoinsSection, OrdersSection } from '@components/Wallet/index';
import WalletTab from '@components/Wallet/WalletTab';
import { useOrders } from '@hooks/queries/useOrders';
import { useSpotAssets } from '@hooks/queries/useSpotAssets';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';

import { ORDERS_SOURCE } from '../../constants';

type PropsType = TabPropsType & {
  onCloseAll?: () => void;
  isAllClosing?: boolean;
};

const SpotTab: React.FC<PropsType> = ({ isActive, onCloseAll, isAllClosing }) => {
  const { t } = useText();
  const { data: assets, isLoading: isAssetsLoading } = useSpotAssets(isActive);
  const { data: orders, isLoading: isOrdersLoading } = useOrders(ORDERS_SOURCE.SPOT, isActive);

  if (isAssetsLoading || isOrdersLoading) {
    return <Preloader />;
  }

  if (!assets || !orders) {
    return null;
  }

  return (
    <WalletTab
      leftSide={<Typography variant="h3">{t('wallet.coins')}</Typography>}
      isAllClosing={isAllClosing}
      onCloseAll={onCloseAll}
    >
      <CoinsSection assets={assets} />
      <Typography variant="h3" className="mb-6 mt-6">
        {t('wallet.orders')}
      </Typography>
      <OrdersSection source={ORDERS_SOURCE.SPOT} orders={orders} />
    </WalletTab>
  );
};

export default React.memo(SpotTab);
