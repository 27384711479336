export enum API_ERROR_TYPES {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
}

export enum API_ERROR_CODES {
  NO_AVAILABLE_AMOUNT = 1000,
  INSUFFICIENT_BALANCE,
  API_KEYS_LIMIT_EXCEEDED,
  INVALID_OTP,
  REQUIRE_2FA,
}

export enum API_VALIDATION_CODES {
  EMAIL_IN_USE = 'EMAIL_IN_USE',
  REFERRAL_CODE_ALREADY_EXIST = 'REFERRAL_CODE_ALREADY_EXIST',
}
