import { walletApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useQuery } from 'react-query';

const fetch = (coin?: string, network?: string) => {
  if (!coin || !network) {
    return Promise.reject('Coin or Network are not specified');
  }

  return walletApi.getDepositAddressCoins({ coin, network });
};

export const useDepositAddressQuery = (coin?: string, network?: string) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery(['deposit-address', coin, network], () => fetch(coin, network), {
    enabled: !!(coin && network),
    onError: () => notify(t('errors.common')),
  });
};
