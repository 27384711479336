import React from 'react';

import { AlertsList } from '@components/Common';
import { setTextPath } from '@hooks/useText';

const texts = [
  setTextPath('cashback.alert1'),
  setTextPath('cashback.alert2'),
  setTextPath('cashback.alert3'),
];

const CashbackAlert = () => {
  return <AlertsList texts={texts} className="mb-7" />;
};

export default React.memo(CashbackAlert);
