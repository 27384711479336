import React, { useCallback, useEffect, useState } from 'react';

import ls from '../../utils/ls';

export interface HideModeContextValue {
  enabled: boolean;
  toggle: () => void;
}

export const HideModeContext = React.createContext<HideModeContextValue>({
  enabled: false,
  toggle: () => {},
});

const LOCAL_STORAGE_KEY = 'hide-mode';

const HideModeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [enabled, setHideMode] = useState(() => ls.get<boolean>(LOCAL_STORAGE_KEY) || false);

  const toggle = useCallback(() => {
    setHideMode((prev) => !prev);
  }, []);

  useEffect(() => {
    ls.set(LOCAL_STORAGE_KEY, enabled);
  }, [enabled]);

  return (
    <HideModeContext.Provider
      value={{
        enabled,
        toggle,
      }}
    >
      {children}
    </HideModeContext.Provider>
  );
};

export default React.memo(HideModeProvider);
