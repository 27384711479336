import * as yup from 'yup';

export const validatePasswordComponents = (password: string = '', passwordRepeat: string = '') => {
  const isMinLengthValid = password.length >= 8;
  const isLowerCaseValid = /.*[a-z].*/.test(password);
  const isUpperCaseValid = /.*[A-Z].*/.test(password);
  const isNumbersValid = /.*[0-9].*/.test(password);
  const isSpecialCharactersValid = /.*[!@#$%^&*].*/.test(password);
  const score = [
    isLowerCaseValid,
    isUpperCaseValid,
    isNumbersValid,
    isSpecialCharactersValid,
  ].filter(Boolean).length;
  const isScoreValid = score >= 3;
  const isPasswordsMatch = Boolean(password && passwordRepeat && password === passwordRepeat);

  return {
    isMinLengthValid,
    isLowerCaseValid,
    isUpperCaseValid,
    isNumbersValid,
    isSpecialCharactersValid,
    isScoreValid,
    isPasswordsMatch,
  };
};

export const passwordValidationSchema = yup
  .object()
  .required()
  .test({
    name: 'isPassword',
    test(value: any) {
      const { isMinLengthValid, isScoreValid, isPasswordsMatch } = validatePasswordComponents(
        value.password,
        value.passwordRepeat,
      );

      return isMinLengthValid && isScoreValid && isPasswordsMatch;
    },
  });
