import React, { useEffect } from 'react';

import { InstallAppButton } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { useAuth } from '@hooks/useAuth';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { i18n } from './localization/i18n';
import { router } from './router';

const App = () => {
  const { isLoading, user } = useAuth();

  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.lng);
      document.documentElement.lang = user.lng;
    }
  }, [user]);

  return (
    <div className="max-w-[100vw] min-h-[100vh] from-blue-gray-800 to-blue-gray-900 bg-gradient-to-tr flex flex-col ">
      {isLoading && <Preloader fullScreen />}
      {!isLoading && <RouterProvider router={router} />}
      <ToastContainer
        toastClassName="!p-0 !min-h-full !shadow-none !rounded-lg !bg-transparent"
        bodyClassName="!p-0 !m-0"
        icon={() => null}
        closeButton={() => null}
        hideProgressBar
      />
      <InstallAppButton />
    </div>
  );
};

export default App;
