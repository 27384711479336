import React from 'react';

import { Section } from '@components/Common';
import { Spinner, Switch, Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  title: string;
  description?: string;
  icon?: React.ReactNode;
  enabled?: boolean;
  disabled?: boolean;
  oneSide?: boolean;
  isLoading?: boolean;
  onChange?: (enabled: boolean) => void;
};

const SettingsBlock: React.FC<PropsType> = ({
  title,
  description,
  icon,
  enabled,
  disabled,
  oneSide,
  isLoading,
  onChange,
}) => {
  const isDisabled = (enabled && oneSide) || disabled;

  const handleChange = () => {
    if (isDisabled) {
      return null;
    }

    if (onChange) {
      onChange(!enabled);
    }
  };

  return (
    <Section
      contentClassName="flex justify-between items-center gap-6"
      className={cn({
        'cursor-pointer': (!oneSide || (!enabled && oneSide)) && !disabled,
      })}
      onClick={handleChange}
    >
      <div className="flex gap-3 items-center">
        {icon && <div className="h-[45px] w-[45px]">{icon}</div>}
        <div>
          <Typography variant="h5">{title}</Typography>
          {description && (
            <Typography variant="paragraph" className="text-blue-300 font-medium">
              {description}
            </Typography>
          )}
        </div>
      </div>
      <div className="pr-8 min-w-[64px]">
        {!isLoading && (
          <Switch color="blue" checked={enabled} disabled={isDisabled} ripple crossOrigin />
        )}
        {isLoading && <Spinner color="blue" />}
      </div>
    </Section>
  );
};

export default React.memo(SettingsBlock);
