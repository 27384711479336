import React from 'react';

import { ApiKeyAccess, ApiKeysAlert, ApiKeysTable } from '@components/ApiKeys';
import { InfoBlock } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import TwoFactorAuth from '@components/Common/TwoFactorAuth';
import { PageLayout } from '@components/Layout';
import { ConfirmModal } from '@components/Modals';
import routes from '@config/routes';
import { useDeleteApiKeyMutation } from '@hooks/mutations/useDeleteApiKeyMutation';
import { useApiKeysQuery } from '@hooks/queries/useApiKeysQuery';
import { use2FA } from '@hooks/use2FA';
import { useConfirm } from '@hooks/useConfirm';
import { useText } from '@hooks/useText';
import { IApiKey } from '@interfaces/api-key';
import { useNavigate } from 'react-router-dom';

import { TWO_FACTOR_AUTH_ACTIONS } from '../../constants';
import { renderIPAddresses } from '../../utils/renderers';

const APIKeysPage = () => {
  const { t } = useText();
  const navigate = useNavigate();
  const { data: apiKeys, isLoading: isApiKeysLoading } = useApiKeysQuery();

  const handleAdd = () => navigate(routes.API_KEYS_CREATE);

  const {
    mutateAsync: deleteApiKey,
    isLoading: isDeleting,
    variables: deleteVariables,
  } = useDeleteApiKeyMutation();

  const { activate: activate2FA, ...twoFactorAuthState } = use2FA<IApiKey>({
    callback: (data) => {
      return deleteApiKey({
        apiKey: data,
        otps: data.otps,
      });
    },
    mapDataTo2FAPayload: (data) => {
      return {
        action: TWO_FACTOR_AUTH_ACTIONS.API_KEY_DELETE,
        payload: {
          name: data.title,
        },
      };
    },
  });

  const {
    confirm: handleDelete,
    isOpen,
    onConfirm,
    onCancel,
    data: apiKeyToDelete,
  } = useConfirm<IApiKey>(activate2FA);

  if (isApiKeysLoading) {
    return <Preloader />;
  }

  if (!apiKeys) {
    return null;
  }

  return (
    <PageLayout
      title={t('api.title')}
      buttonText={t('api.create')}
      onButtonClick={handleAdd}
      showButton
    >
      <ApiKeysAlert />
      <ApiKeysTable
        apiKeys={apiKeys}
        className="mt-7"
        onDelete={handleDelete}
        deletingId={isDeleting && deleteVariables ? deleteVariables.apiKey.id : undefined}
      />
      <ConfirmModal
        isOpen={isOpen}
        onConfirm={onConfirm}
        onCancel={onCancel}
        title={t('api.deleteConfirm')}
      >
        {apiKeyToDelete && (
          <>
            <InfoBlock label={t('api.name')} text={apiKeyToDelete?.title} className="mb-2" />
            <InfoBlock
              label={t('api.access')}
              text={<ApiKeyAccess apiKey={apiKeyToDelete} asP={false} />}
              className="mb-2"
            />
            {!!apiKeyToDelete.ips?.length && (
              <InfoBlock label={t('api.ips')} text={renderIPAddresses(apiKeyToDelete.ips)} />
            )}
          </>
        )}
      </ConfirmModal>
      <TwoFactorAuth {...twoFactorAuthState} isVerifying={isDeleting} />
    </PageLayout>
  );
};

export default React.memo(APIKeysPage);
