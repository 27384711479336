import React from 'react';

import { HiddenContent, InfoBlock } from '@components/Common';
import routes from '@config/routes';
import { CurrencyDollarIcon, ArrowUpCircleIcon } from '@heroicons/react/24/solid';
import { useHideMode } from '@hooks/useHideMode';
import { useText } from '@hooks/useText';
import { IFuturesBalance } from '@interfaces/balances';
import { Button } from '@material-tailwind/react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { roundAmount } from '../../utils/amount';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  balance: IFuturesBalance;
  className?: string;
  onAssetsMode?: () => void;
};

const PositionsBalance: React.FC<PropsType> = ({ balance, className, onAssetsMode }) => {
  const { t } = useText();
  const hideMode = useHideMode();
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'border-b border-gray-600 pb-4 flex justify-between items-center gap-3',
        className,
      )}
    >
      <div className="flex gap-3 md:gap-[40px] flex-col md:flex-row">
        <InfoBlock
          icon={CurrencyDollarIcon}
          label={t('position.freeMargin')}
          text={
            <HiddenContent hidden={hideMode.enabled}>
              {renderAmount(roundAmount(balance.availableBalance), 'USDT')}
            </HiddenContent>
          }
        />
        <InfoBlock
          icon={ArrowUpCircleIcon}
          label={t('position.unrealizedPnl')}
          text={renderAmount(roundAmount(balance.totalUnrealizedProfit), 'USDT')}
        />
      </div>
      <div className="flex gap-5 flex-col lg:flex-row">
        <Button size="sm" onClick={() => navigate(routes.STATISTIC)}>
          {t('wallet.analysis')}
        </Button>
        <Button size="sm" onClick={onAssetsMode}>
          {t('wallet.assets')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(PositionsBalance);
