import React, { useEffect, useMemo } from 'react';

import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useMd } from '@hooks/responsive/useMd';
import { useControlledField } from '@hooks/useControlledField';
import { Button, IconButton } from '@material-tailwind/react';

type PropsType = {
  page?: number;
  itemsCount: number;
  itemsPerPage?: number;
  onPageChange?: (page: number) => void;
};

const Pagination: React.FC<PropsType> = ({
  itemsCount,
  itemsPerPage = 10,
  onPageChange,
  page: outsidePage = 1,
}) => {
  const isMd = useMd();
  const [page, setPage] = useControlledField(outsidePage, outsidePage, onPageChange);

  useEffect(() => {
    if (onPageChange) {
      onPageChange(page);
    }
  }, [page]);

  const sideButtonsCount = useMemo(() => {
    if (isMd) {
      return 6;
    }

    return 4;
  }, [isMd]);

  const totalPages = useMemo(() => {
    return Math.ceil(itemsCount / itemsPerPage);
  }, [itemsCount, itemsPerPage]);

  const getItemProps = (index: number) =>
    ({
      variant: page === index ? 'filled' : 'text',
      color: 'gray',
      onClick: () => setPage(index),
    }) as any;

  const next = () => {
    setPage((prev) => Math.min(totalPages, prev + 1));
  };

  const prev = () => {
    setPage((prev) => Math.max(1, prev - 1));
  };

  const handleClick = (page: string | number) => () => {
    if (typeof page === 'number') {
      setPage(page);
    }
  };

  const getLeftButtons = (allPages: number[]) => {
    if (page > sideButtonsCount) {
      return [allPages[0]];
    }

    return allPages.slice(0, sideButtonsCount + 1);
  };

  const getRightButtons = (allPages: number[]) => {
    const tail = allPages[allPages.length - sideButtonsCount];

    if (page < tail) {
      return [allPages[allPages.length - 1]];
    }

    return allPages.slice(allPages.length - sideButtonsCount - 1);
  };

  const getCenterButtons = (allPages: number[], left: number[], right: number[]) => {
    const separator = '...';

    if (left.length > 1 || right.length > 1) {
      return [separator];
    }

    const pageIndex = allPages.findIndex((p) => page === p);

    if (pageIndex === -1) {
      return [separator];
    }

    return [
      separator,
      allPages[pageIndex - 1],
      allPages[pageIndex],
      allPages[pageIndex + 1],
      separator,
    ];
  };

  const getPages = (allPages: number[]) => {
    if (allPages.length <= sideButtonsCount) {
      return allPages;
    }

    const left = getLeftButtons(allPages);
    const right = getRightButtons(allPages);
    const center = getCenterButtons(allPages, left, right);

    return [...left, ...center, ...right];
  };

  const renderPages = () => {
    const allPages = Array(totalPages)
      .fill(undefined)
      .map((_, index) => index + 1);
    const pages = getPages(allPages);

    return pages.map((p, index) => {
      const isActive = p === page;

      return (
        <IconButton
          key={`${p}-${index}`}
          {...getItemProps(1)}
          color={isActive ? 'blue' : 'gray'}
          variant={isActive ? 'filled' : 'outlined'}
          size={isMd ? 'md' : 'sm'}
          onClick={handleClick(p)}
        >
          {p}
        </IconButton>
      );
    });
  };

  return (
    <div className="flex items-center gap-4">
      {isMd && (
        <Button
          variant="text"
          className="flex items-center gap-2"
          onClick={prev}
          disabled={page === 1}
        >
          <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
      )}
      <div className="flex items-center gap-2">{renderPages()}</div>
      {isMd && (
        <Button
          variant="text"
          className="flex items-center gap-2"
          onClick={next}
          disabled={page === totalPages}
        >
          <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};

export default React.memo(Pagination);
