import { usersApi } from '@api/index';
import { IUser } from '@interfaces/user';
import { useQuery } from 'react-query';

export const useMeQuery = (onSuccess?: (data?: IUser | null) => void) => {
  return useQuery(['currentUser'], () => usersApi.getMe(), {
    retry: false,
    refetchOnMount: false,
    onSuccess,
  });
};
