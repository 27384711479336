import React from 'react';

import { Button, ButtonProps, Spinner } from '@material-tailwind/react';
import cn from 'classnames';

export type LoadingButtonPropsType = ButtonProps & {
  isLoading?: boolean;
  contentClassName?: string;
};

const LoadingButton: React.FC<LoadingButtonPropsType> = ({
  children,
  isLoading,
  disabled,
  className,
  contentClassName,
  ...props
}) => {
  return (
    <Button
      {...(props as any)}
      disabled={isLoading || disabled}
      className={cn('relative', className)}
    >
      {isLoading && (
        <div className="absolute left-0 top-0 w-full h-full bg-inherit z-50 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className={cn(isLoading && 'opacity-0', contentClassName)}>{children}</div>
    </Button>
  );
};

export default React.memo(LoadingButton);
