import React, { useMemo, useState } from 'react';

import { useLogoutMutation } from '@hooks/mutations/useLogoutMutation';
import { IUseSignUpPayload, useSignUpMutation } from '@hooks/mutations/useSignUpMutation';
import { useMeQuery } from '@hooks/queries/useMeQuery';
import { IUser } from '@interfaces/user';
import { QueryObserverResult, UseMutateAsyncFunction } from 'react-query';

export interface IAuthContextValue {
  user: IUser | null;
  isAuth: boolean;
  isLoading: boolean;
  refreshUser: () => Promise<QueryObserverResult<IUser | null | undefined>>;
  logout: () => void;
  signUp: UseMutateAsyncFunction<IUser, unknown, IUseSignUpPayload, unknown>;
  setUser: (user: IUser) => void;
}

export const AuthContext = React.createContext<IAuthContextValue>({
  user: null,
  isAuth: false,
  isLoading: false,
  refreshUser: () => Promise.reject('Not implemented'),
  logout: () => {},
  signUp: () => Promise.reject('Not implemented'),
  setUser: () => {},
});

const AuthProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null);
  const { isLoading, refetch } = useMeQuery((user) => setUser(user || null));
  const isAuth = useMemo(() => !!user, [user]);
  const { mutate: logout } = useLogoutMutation(() => setUser(null));
  const { mutateAsync: signUp } = useSignUpMutation((user) => setUser(user || null));

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuth,
        isLoading,
        logout,
        signUp,
        refreshUser: refetch,
        setUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default React.memo(AuthProvider);
