import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IDocument } from '@interfaces/document';

import { LANGUAGES } from '../../constants';

export class DocumentsApi extends BaseApi {
  public get(lang?: LANGUAGES) {
    return this.client.get<IDocument[]>(endpoints.DOCUMENTS.INDEX, {
      lang,
    });
  }
}
