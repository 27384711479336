import React from 'react';

import { AuthLink } from '@components/Common';
import TimerButton from '@components/Common/TimerButton';
import { Form, FormInputField } from '@components/Form';
import routes from '@config/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { setTextPath, useText } from '@hooks/useText';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface IResetPasswordRequestFormData {
  email: string;
}

const FIELD_NAMES: { [key in keyof IResetPasswordRequestFormData]: key } = {
  email: 'email',
};

type PropsType = {
  onSubmit: (data: IResetPasswordRequestFormData) => void;
  isError?: boolean;
};

const schema: yup.ObjectSchema<IResetPasswordRequestFormData> = yup
  .object({
    email: yup.string().required(setTextPath('errors.required')),
  })
  .required();

const ResetPasswordRequestForm: React.FC<PropsType> = ({ onSubmit, isError }) => {
  const { t } = useText();

  const formMethods = useForm<IResetPasswordRequestFormData>({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} errorText={t('resetPasswordRequest.error')}>
      <FormInputField name={FIELD_NAMES.email} label={t('resetPasswordRequest.emailAddress')} />
      <TimerButton
        fullWidth
        type="submit"
        isLoading={formMethods.formState.isSubmitting}
        disableTimer={isError}
        className="w-full"
      >
        {t('common.continue')}
      </TimerButton>
      <AuthLink to={routes.LOGIN} className="mt-4 text-center">
        {t('resetPasswordRequest.back')}
      </AuthLink>
    </Form>
  );
};

export default React.memo(ResetPasswordRequestForm);
