import React, { useState } from 'react';

import { API_ERROR_TYPES } from '@api/api.constants';
import { IApiValidationErrorResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import { ISignUpFormData } from '@components/SignUpForm/SignUpForm';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { Alert } from '@material-tailwind/react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

type PropsType = {
  onSubmit: SubmitHandler<any>;
  formMethods: UseFormReturn<any>;
  errorText?: string;
  serverErrorText?: string;
  errorVariant?: 'filled' | 'gradient' | 'outlined' | 'ghost';
  error?: string;
};

const Form: React.FC<React.PropsWithChildren<PropsType>> = ({
  onSubmit,
  formMethods,
  children,
  errorText,
  serverErrorText,
  errorVariant = 'gradient',
  error,
}) => {
  const [formError, setFormError] = useState<string | undefined>();

  const handleSubmit = async (data: ISignUpFormData) => {
    try {
      setFormError(undefined);
      await onSubmit(data);
    } catch (e: any) {
      const error = e as ApiError<IApiValidationErrorResponse>;

      if (error.statusCode === 400 && error.response?.code === API_ERROR_TYPES.VALIDATION_ERROR) {
        for (const key in error.response.fields) {
          const message = error.response.fields[key]?.message;

          if (message) {
            formMethods.setError(key as any, { message });
          }
        }
      } else if (error.statusCode === 401) {
        setFormError(errorText);
      }
      else {
        setFormError(serverErrorText);
      }
    }
  };

  return (
    <FormProvider {...formMethods}>
      {(error || formError) && (
        <Alert
          icon={<ExclamationTriangleIcon className="text-amber-500 w-[24px]" />}
          variant={errorVariant}
          color="gray"
          className="mb-7 !text-amber-500 font-semibold"
        >
          {error || formError}
        </Alert>
      )}
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>{children}</form>
    </FormProvider>
  );
};

export default React.memo(Form);
