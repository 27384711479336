import React, { useCallback, useMemo } from 'react';

import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { PairsList } from '@components/Leverage';
import { useSetMaxLeverageMutation } from '@hooks/mutations/useSetMaxLeverageMutation';
import { useUpdateLeverageMutation } from '@hooks/mutations/useUpdateLeverageMutation';
import { useUpdateMarginTypeMutation } from '@hooks/mutations/useUpdateMarginTypeMutation';
import { useFuturesPairs } from '@hooks/queries/useFuturesPairs';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { debounce } from 'lodash';

import { MARGIN_TYPE } from '../../constants';

const LeveragePage = () => {
  const { t } = useText();
  const { data: pairs, isLoading: isPairsLoading } = useFuturesPairs();
  const {
    mutate: updateMarginType,
    isLoading: isMarginTypeUpdating,
    variables: updateMarginTypeVariables,
  } = useUpdateMarginTypeMutation();
  const {
    mutate: updateLeverage,
    isLoading: isLeverageUpdating,
    variables: updateLeverageVariables,
  } = useUpdateLeverageMutation();
  const { mutate: setMaxLeverage, isLoading: isMaxLeverageSetting } = useSetMaxLeverageMutation();

  const symbolsMarginTypeChanging = useMemo(() => {
    if (isMarginTypeUpdating && updateMarginTypeVariables) {
      return updateMarginTypeVariables.pairs.map((p) => p.symbol);
    }
  }, [updateMarginTypeVariables, isMarginTypeUpdating]);

  const handleMarginTypeChange = (pairs: IFuturesPair[], marginType: MARGIN_TYPE) => {
    updateMarginType({
      pairs,
      marginType,
    });
  };

  const handleLeverageUpdate = useCallback(
    debounce((pairs: string[], leverage: number) => {
      updateLeverage({
        pairs,
        leverage,
      });
    }, 600),
    [],
  );

  if (isPairsLoading) {
    return <Preloader />;
  }

  if (!pairs) {
    return null;
  }

  return (
    <PageLayout title={t('leverage.title')}>
      <PairsList
        pairs={pairs}
        symbolsMarginTypeChanging={symbolsMarginTypeChanging}
        symbolsLeverageChanging={isLeverageUpdating ? updateLeverageVariables?.pairs : undefined}
        isMaxLeverageSetting={isMaxLeverageSetting}
        onMarginTypeChange={handleMarginTypeChange}
        onLeverageChange={handleLeverageUpdate}
        onSetMaxLeverage={setMaxLeverage}
      />
    </PageLayout>
  );
};

export default React.memo(LeveragePage);
