export default {
  HOME: '/',
  WALLET: '/wallet',
  SAVED_COMMISSIONS: '/saved-commissions',
  LEVERAGE: '/leverage',
  API_KEYS: '/api-keys',
  API_KEYS_CREATE: '/api-keys/create',
  API_KEYS_UPDATE: '/api-keys/:id/update',
  HISTORY: '/history',
  TERMS: '/terms',
  RESET_PASSWORD: '/reset-password',
  RESET_PASSWORD_REQUEST: '/reset-password-request',
  VERIFY_EMAIL: '/verify-email',
  LOGIN: '/login',
  SIGN_UP: '/sign-up',
  VERIFICATION: '/kyc',
  REFERRALS: '/referrals',
  REFERRALS_LINKS: '/referrals/links',
  OPERATIONS: '/operations',
  OPERATIONS_DEPOSIT: '/operations/deposit',
  OPERATIONS_TRANSFER: '/operations/transfer',
  OPERATIONS_WITHDRAWAL: '/operations/withdrawal',
  SETTINGS: '/settings',
  STATISTIC: '/statistic/futures-usd-m',
};
