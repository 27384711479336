import { twoFactorAuthApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

export const useToggleRequire2FAOnSignInMutation = () => {
  const { t } = useText();
  const { notify } = useNotification();
  const { refreshUser } = useAuth();

  return useMutation({
    mutationFn: (enabled: boolean) => twoFactorAuthApi.toggleRequire2FAOnSignIn(enabled),
    onSuccess: async (_, enabled) => {
      await refreshUser();
      const message = enabled
        ? t('settings.googleAuthOnSignInEnabledSuccess')
        : t('settings.googleAuthOnSignInDisableSuccess');

      notify(message, 'success');
    },
    onError: (_, enabled) => {
      const message = enabled
        ? t('settings.googleAuthOnSignInEnabledFailed')
        : t('settings.googleAuthOnSignInDisableFailed');

      notify(message, 'error');
    },
  });
};
