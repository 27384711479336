import { setTextPath } from '@hooks/useText';
import { INetwork } from '@interfaces/network';
import * as yup from 'yup';

import { i18n } from '../localization/i18n';
import { validateAmount } from '../utils/validate-amount';

export const withdrawalSchema: yup.ObjectSchema<any> = yup
  .object({
    address: yup.string().required(setTextPath('errors.required')),
    network: yup.object().required(setTextPath('errors.required')),
    asset: yup.object().required(setTextPath('errors.required')),
    amount: yup
      .string()
      .required(setTextPath('errors.required'))
      .test({
        name: 'isBalanceValid',
        message: setTextPath('wallet.insufficientError'),
        test(value) {
          const max = this.parent.asset?.amount as string | undefined;

          return validateAmount(value, max);
        },
      })
      .test({
        name: 'isNetworkLimitsValid',
        test(value) {
          const network = this.parent.network as INetwork | undefined;

          if (!network) {
            return true;
          }

          const amount = parseFloat(value);
          const min = parseFloat(network.withdrawMin);
          const max = parseFloat(network.withdrawMax);
          const isMinInvalid = amount < min;

          if (isMinInvalid) {
            return this.createError({
              message: i18n.t(setTextPath('errors.minWithdrawal'), { min }),
            });
          }

          const isMaxInvalid = amount > max;

          if (isMaxInvalid) {
            return this.createError({
              message: i18n.t(setTextPath('errors.maxWithdrawal'), { max }),
            });
          }

          return true;
        },
      }),
  })
  .required();
