import React, { useMemo } from 'react';

import { Section, Table } from '@components/Common';
import { CoinIcon } from '@components/Icons';
import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IWalletAsset } from '@interfaces/wallet-asset';
import { IconButton, Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

type PropsType = {
  assets?: IWalletAsset[];
  emptyText?: string;
  onBack?: () => void;
};

const columnHelper = createColumnHelper<IWalletAsset>();

const CoinsSection: React.FC<PropsType> = ({ assets = [], onBack, emptyText }) => {
  const { t } = useText();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('coin', {
        header: () => t('wallet.coin'),
        cell: (info) => {
          const coin = info.getValue();

          return (
            <div className="flex items-center gap-3">
              <CoinIcon url={info.row.original.coinLogo} name={coin} size="medium" />
              <Typography variant="small" className="font-semibold">
                {coin}
              </Typography>
            </div>
          );
        },
        meta: {
          className: 'md:w-[75%]',
        },
      }),
      columnHelper.accessor('amount', {
        header: () => t('wallet.amount'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {info.getValue()}
          </Typography>
        ),
      }),
    ];
  }, [assets]);

  return (
    <Section>
      {onBack && (
        <IconButton variant="text" color="blue" onClick={onBack}>
          <ChevronLeftIcon className="w-8" />
        </IconButton>
      )}
      <Table columns={columns} data={assets} emptyText={emptyText || t('wallet.emptyCoins')} />
    </Section>
  );
};

export default React.memo(CoinsSection);
