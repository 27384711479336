import React from 'react';

import AuthLayout from '@components/Layout/AuthLayout';
import { ResetPasswordForm } from '@components/ResetPassword';
import { IResetPasswordFormData } from '@components/ResetPassword/ResetPasswordForm';
import { useResetPasswordMutation } from '@hooks/mutations/useResetPasswordMutation';
import { useText } from '@hooks/useText';
import { useSearchParams } from 'react-router-dom';

const ResetPasswordPage = () => {
  const { t } = useText();
  const { mutateAsync } = useResetPasswordMutation();

  let [searchParams] = useSearchParams();

  const params = searchParams.get('token');

  const handleSubmit = async (data: IResetPasswordFormData) => {
    if (data.password.password) {
      await mutateAsync({ password: data.password.password, token: params });
    }
  };

  return (
    <AuthLayout title={t('resetPassword.newPassword')}>
      <ResetPasswordForm onSubmit={handleSubmit} />
    </AuthLayout>
  );
};

export default React.memo(ResetPasswordPage);
