import React from 'react';

import { AlertsList } from '@components/Common';
import { setTextPath } from '@hooks/useText';

const texts = [
  setTextPath('apiAlert.one'),
  setTextPath('apiAlert.two'),
  setTextPath('apiAlert.three'),
  setTextPath('apiAlert.four'),
];

const ApiKeysAlert = () => {
  return <AlertsList texts={texts} />;
};

export default React.memo(ApiKeysAlert);
