import SelectField, { SelectFieldPropsType } from '@components/Form/Fields/SelectField/SelectField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

type PropsType = SelectFieldPropsType;

const SelectFieldController: ControllerComponent<PropsType> = ({ name, control, ...props }) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <SelectField {...props} {...field} />}
  />
);

SelectFieldController.isController = true;

export default SelectFieldController;
