import { statisticApi } from '@api/index';
import { IPeriod } from '@interfaces/period';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

interface IUseFuturesPnlsQueryParams extends IPeriod {
  isPnlWithFee: boolean;
}

export const useFuturesPnlsQuery = (query: IUseFuturesPnlsQueryParams, enabled?: boolean) => {
  return useQuery(
    [QUERIES.FUTURES_PNLS, query.toDate, query.fromDate, query.isPnlWithFee],
    () =>
      statisticApi.getPnls({
        fromDate: query.fromDate.toISOString(),
        toDate: query.toDate.toISOString(),
        isPnlWithFee: query.isPnlWithFee,
      }),
    {
      enabled,
    },
  );
};
