import React from 'react';

import { useText } from '@hooks/useText';
import cn from 'classnames';

export type FormFieldProps = {
  error?: string;
  className?: string;
  helperText?: string;
  hideError?: boolean;
};

const FormField: React.FC<React.PropsWithChildren<FormFieldProps>> = ({
  children,
  error,
  className,
  helperText,
  hideError,
}) => {
  const { t } = useText();

  return (
    <div className={cn('relative mb-7 last:mb-0 w-full', className)}>
      {helperText && (
        <p className="absolute mb-[1px] text-xs font-semibold bottom-full right-0">
          {t(helperText as never)}
        </p>
      )}
      {children}
      {error && !hideError && (
        <p className="absolute mt-[1px] text-xs text-amber-500">{t(error as never)}</p>
      )}
    </div>
  );
};

export default React.memo(FormField);
