import { exchangeApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export const useToggleCoinBurnMutation = () => {
  const { t } = useText();
  const { notify } = useNotification();

  return useMutation({
    mutationFn: (enabled: boolean) => exchangeApi.toggleCoinBurn(enabled),
    onSuccess: async (_, enabled) => {
      await queryClient.invalidateQueries({ queryKey: QUERIES.BURN_STATUS });
      const message = enabled
        ? t('settings.burnEnabledSuccess')
        : t('settings.burnDisabledSuccess');

      notify(message, 'success');
    },
    onError: (_, enabled) => {
      const message = enabled ? t('settings.burnEnabledFailed') : t('settings.burnDisabledFailed');

      notify(message, 'error');
    },
  });
};
