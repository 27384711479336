import React from 'react';

import {
  IGetReferralOverviewResponse,
  IGetReferralUrlResponse,
} from '@api/referral/referral-api.interfaces';
import { PrimaryInfoBlock, Section } from '@components/Common';
import { ReferralsForm, ReferralsLastIncome } from '@components/Referral/index';
import ReferralPrimaryLink from '@components/Referral/ReferralPrimaryLink';
import { IReferralForm } from '@components/Referral/ReferralsForm';
import { useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';

import { renderAmount } from '../../utils/renderers';

type PropsType = {
  enableHistoryMode: () => void;
  referralsTotal: IGetReferralOverviewResponse;
  getReferralUrl: IGetReferralUrlResponse;
  handleSubmit: (data: IReferralForm) => void;
  showHistoryModeButton: boolean;
};

const ReferralsInfoBlock: React.FC<PropsType> = ({
  enableHistoryMode,
  referralsTotal,
  getReferralUrl,
  handleSubmit,
  showHistoryModeButton,
}) => {
  const { t } = useText();

  return (
    <div className="flex flex-col gap-6 lg:w-[37%]">
      <Section>
        <PrimaryInfoBlock
          title={
            <div className="flex w-full justify-between items-center mb-2 h-[32px]">
              {t('referrals.total')}
              {showHistoryModeButton && (
                <Button size="sm" onClick={enableHistoryMode}>
                  {t('referrals.history')}
                </Button>
              )}
            </div>
          }
          value={renderAmount(referralsTotal.total.amount, referralsTotal.total.asset)}
        />
      </Section>
      <ReferralsLastIncome lastIncome={referralsTotal.lastIncome} />
      <ReferralPrimaryLink url={getReferralUrl.url} />
      <Section>
        <ReferralsForm onSubmit={handleSubmit} />
      </Section>
    </div>
  );
};

export default React.memo(ReferralsInfoBlock);
