import routes from '@config/routes';
import withAuthGuard from '@hocs/withAuthGuard';

import ResetPasswordRequestPage from './ResetPasswordRequestPage';

export default withAuthGuard({
  condition: {
    isAuth: false,
  },
  redirectTo: routes.HOME,
})(ResetPasswordRequestPage);
