import { positionsApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IPosition } from '@interfaces/position';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export const useClosePositionsMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (symbols: string[]) => positionsApi.closeMany(symbols),
    onSuccess: async (_, variables) => {
      const message =
        variables.length === 1 ? t('position.deleteSuccess') : t('position.deleteSuccessMultiple');

      queryClient.setQueriesData<IPosition[]>(QUERIES.POSITIONS, (input) => {
        return input?.filter((position: IPosition) => !variables.includes(position.symbol)) || [];
      });
      await queryClient.invalidateQueries([QUERIES.FUTURES_BALANCE]);
      notify(message, 'success');
    },
    onError: (_, variables) => {
      const message =
        variables.length === 1 ? t('position.deleteFailed') : t('position.deleteFailedMultiple');

      notify(message, 'error');
    },
  });
};
