import { authApi } from '@api/index';
import routes from '@config/routes';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export interface IResetPasswordPayload {
  password: string;
  token: string | null;
}

export const useResetPasswordMutation = () => {
  const navigate = useNavigate();
  const { t } = useText();
  const { notify } = useNotification();

  return useMutation({
    mutationFn: (data: IResetPasswordPayload) => authApi.resetPassword(data),
    onSuccess: () => {
      notify(t('resetPassword.success'));
      navigate(routes.LOGIN);
    },
  });
};
