import { useState } from 'react';

import { IApiPaginationQuery, IApiPaginationResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import { useQuery } from '@hooks/useQuery';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';

export interface IPaginationQueryParams<D = any>
  extends UseQueryOptions<IApiPaginationResponse<D>, ApiError, IApiPaginationResponse<D>, any[]> {}

export interface IPaginationData {
  page: number;
  itemsPerPage: number;
  totalCount: number | null;
  setPage: (page: number) => void;
}

export interface IPaginationQueryUtils {
  resetQuery: () => void;
}

export interface IPaginationQueryResult<D = any> {
  query: UseQueryResult<D[] | undefined, ApiError>;
  pagination: IPaginationData;
  utils: IPaginationQueryUtils;
}

type QueryFn<D> = (params: IApiPaginationQuery) => Promise<IApiPaginationResponse<D>>;

export const ITEMS_PER_PAGE = 10;

export const usePaginationQuery = <D = any>(
  queryKey: any[],
  queryFn: QueryFn<D>,
  params: IPaginationQueryParams<D> = {},
): IPaginationQueryResult<D> => {
  const { ...queryParams } = params;
  const [page, setPage] = useState(0);
  const [isReseted, setIsReseted] = useState(false);

  const query = useQuery(
    [...queryKey, page],
    () => {
      return queryFn({
        take: ITEMS_PER_PAGE,
        skip: page * ITEMS_PER_PAGE,
      });
    },
    {
      ...queryParams,
      keepPreviousData: true,
      onSettled: (...args) => {
        setIsReseted(false);

        if (queryParams.onSettled) {
          queryParams.onSettled(...args);
        }
      },
    },
  );

  const setPageNumber = (page: number) => {
    setPage(page - 1);
  };

  const resetQuery = () => {
    if (query.data) {
      setPage(0);
      setIsReseted(true);
    }
  };

  return {
    query: {
      ...query,
      data: isReseted ? [] : query.data?.items,
      isLoading: query.isLoading || (query.isFetching && isReseted),
    } as IPaginationQueryResult['query'],
    pagination: {
      page: page + 1,
      itemsPerPage: ITEMS_PER_PAGE,
      totalCount: query.data?.count || null,
      setPage: setPageNumber,
    },
    utils: {
      resetQuery,
    },
  };
};
