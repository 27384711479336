import React, { useMemo } from 'react';

import Tabs, { ITab } from '@components/Common/Tabs';
import { PageLayout } from '@components/Layout';
import { DepositTab, TransferTab, WithdrawalTab } from '@components/Operations';
import routes from '@config/routes';
import {
  ArrowDownLeftIcon,
  ArrowUpRightIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';

const OperationsPage = () => {
  const { t } = useText();

  const tabs: ITab[] = useMemo(
    () => [
      {
        label: t('operations.deposit'),
        value: routes.OPERATIONS_DEPOSIT,
        icon: ArrowDownLeftIcon,
        content: <DepositTab />,
      },
      {
        label: t('operations.transfer'),
        value: routes.OPERATIONS_TRANSFER,
        icon: ArrowsRightLeftIcon,
        content: <TransferTab />,
      },
      {
        label: t('operations.withdrawal'),
        value: routes.OPERATIONS_WITHDRAWAL,
        icon: ArrowUpRightIcon,
        content: <WithdrawalTab />,
      },
    ],
    [],
  );

  return (
    <PageLayout contentClassName="items-center md:pt-20">
      <Tabs
        tabs={tabs}
        defaultTab={routes.OPERATIONS_DEPOSIT}
        className="max-w-2xl"
        useLocationAsState
      />
    </PageLayout>
  );
};

export default React.memo(OperationsPage);
