import React, { useMemo } from 'react';

import { IInfoITem, InfoList, Section } from '@components/Common';
import { useText } from '@hooks/useText';

import { CASHBACK_TYPE, CASHBACK_TYPE_LABELS } from '../../constants';
import { roundAmount } from '../../utils/amount';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  total: Record<CASHBACK_TYPE, string>;
};

const TotalCashbackSaved: React.FC<PropsType> = ({ total }) => {
  const { t } = useText();

  const items = useMemo<IInfoITem[]>(() => {
    return Object.keys(CASHBACK_TYPE).map((key) => {
      const type = key as CASHBACK_TYPE;
      const amount = roundAmount(total[type], 5);
      const value = renderAmount(amount, 'USDT');

      return {
        key: t(CASHBACK_TYPE_LABELS[type] as never),
        value: <>≈ {value}</>,
      };
    });
  }, [total]);

  return (
    <Section>
      <InfoList title={t('cashback.total')} items={items} />
    </Section>
  );
};

export default React.memo(TotalCashbackSaved);
