import React from 'react';

import { useText } from '@hooks/useText';
import { ICashbackHistory } from '@interfaces/cashback';
import { Typography } from '@material-tailwind/react';
import { format } from 'date-fns';

import { CASHBACK_TYPE_LABELS } from '../../constants';

type PropsType = {
  cashback: ICashbackHistory;
};

const CashbackDetails: React.FC<PropsType> = ({ cashback }) => {
  const { t } = useText();

  return (
    <div>
      <Typography variant="small" className="font-semibold whitespace-nowrap">
        {t(CASHBACK_TYPE_LABELS[cashback.type] as never)}
      </Typography>
      <Typography variant="small" className="font-semibold text-blue-300 text-xs">
        {t('cashback.period')} {format(new Date(cashback.createdAt), 'MM/dd/yyyy')}
      </Typography>
    </div>
  );
};

export default React.memo(CashbackDetails);
