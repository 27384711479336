import { ApiError } from '@api/core/api.error';
import { twoFactorAuthApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

export const useVerify2FAMutation = (onSuccess: () => void) => {
  const { t } = useText();
  const { notify } = useNotification();
  const { refreshUser } = useAuth();

  return useMutation({
    mutationFn: (code: string) => twoFactorAuthApi.verify(code),
    onSuccess: async () => {
      await refreshUser();
      notify(t('settings.googleAuthEnableSuccess'), 'success');
      onSuccess();
    },
    onError: (error: ApiError) => {
      const message =
        error.statusCode === 401 ? t('otp.invalidCode') : t('settings.googleAuthEnableFailed');

      notify(message, 'error');
    },
  });
};
