import React, { useEffect, useMemo, useState } from 'react';

import { DateRangePicker, InfoBlock, Pnl } from '@components/Common';
import { SelectField } from '@components/Form';
import { useText } from '@hooks/useText';
import { IOption } from '@interfaces/option';
import { IPeriod } from '@interfaces/period';
import { IPnls } from '@interfaces/pnl';
import { Switch } from '@material-tailwind/react';
import { differenceInDays, isSameDay } from 'date-fns';
import { DateRange } from 'react-day-picker';

import { customPeriod, periods } from '../../data/periods';
import { getPeriodForDays, mapPeriodForRange, mapRangeToPeriod } from '../../utils/date';

type PropsType = {
  pnls: IPnls;
  period: IPeriod;
  onPeriodChange: (period: IPeriod) => void;
  setIsPnlWithFee: (checked: boolean) => void;
  isPnlWithFee: boolean;
};

const StatisticHeading: React.FC<PropsType> = ({
  pnls,
  period,
  onPeriodChange,
  setIsPnlWithFee,
  isPnlWithFee,
}) => {
  const { t } = useText();
  const [range, setRange] = useState<DateRange | undefined | null>(() => mapPeriodForRange(period));

  const periodDays = useMemo(() => {
    const days = differenceInDays(period.toDate, period.fromDate);

    return days + 1;
  }, [period]);

  const fixedPeriod = useMemo<IOption<number>>(() => {
    return periods.find((p) => p.value === periodDays) || customPeriod;
  }, [periodDays]);

  const periodsOptions = useMemo(() => {
    if (fixedPeriod.value === 0) {
      return periods;
    }

    return periods.filter((p) => p.value !== 0);
  }, [fixedPeriod]);

  useEffect(() => {
    const newPeriod = mapRangeToPeriod(range);

    if (
      newPeriod &&
      (!isSameDay(newPeriod.fromDate, period.fromDate) ||
        !isSameDay(newPeriod.toDate, period.toDate))
    ) {
      onPeriodChange(newPeriod);
    }
  }, [range]);

  const handleFixedPeriodChange = (option: IOption<number>) => {
    if (option.value !== 0 && option.value !== periodDays) {
      const period: IPeriod = getPeriodForDays(option.value);

      onPeriodChange(period);
      setRange(mapPeriodForRange(period));
    }
  };

  return (
    <div className="flex justify-between gap-3 flex-col lg:flex-row mb:1 md:mb-5">
      <div className="w-full lg:max-w-[45%] order-2 lg:order-1 flex flex-col md:flex-row md:justify-between lg:justify-start lg:items-center gap-3 lg:gap-10">
        <InfoBlock
          label={t('statistic.pnlForPeriod')}
          text={<Pnl pnl={pnls.total} asset={pnls.totalAsset} className="text-lg" />}
          size="large"
          labelClassName="whitespace-nowrap"
        />
        <Switch
          label={t('statistic.p&lFee')}
          color="blue"
          onChange={(e) => setIsPnlWithFee(e.target.checked)}
          checked={isPnlWithFee}
          crossOrigin
        />
      </div>
      <div className="flex flex-col sm:flex-row sm:gap-3 w-full lg:max-w-[55%] justify-end order-1 lg:order-2">
        <SelectField
          label={t('common.period')}
          className="lg:max-w-[250px] sm:!mb-0"
          options={periodsOptions}
          value={fixedPeriod}
          onChange={handleFixedPeriodChange}
        />
        <DateRangePicker label={t('common.dates')} value={range} onChange={setRange} />
      </div>
    </div>
  );
};

export default React.memo(StatisticHeading);
