import React, { useState } from 'react';

import { ClipboardDocumentCheckIcon, ClipboardDocumentIcon } from '@heroicons/react/24/solid';
import { useClipboard } from '@hooks/useClipboard';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { animated, useSpring } from '@react-spring/web';

type PropsType = {
  text: string;
  copyTitle?: string;
};

const CopyButton: React.FC<PropsType> = ({ text, copyTitle }) => {
  const [isMouseLeave, setIsMouseLeave] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const copy = useClipboard(text);

  const [copyAnimationStyles, copyAnimationApi] = useSpring(() => ({
    from: { opacity: 1 },
    reset: true,
    config: {
      duration: 200,
    },
  }));
  const [copiedAnimationStyles, copiedAnimationApi] = useSpring(() => ({
    from: { opacity: 1 },
    reset: true,
    config: {
      duration: 200,
    },
  }));

  const handleCopy = async (text: string) => {
    await copy(text);
    copyAnimationApi.start({
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0,
      },
      onRest() {
        setIsCopied(true);
        copiedAnimationApi.start({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        });
      },
    });
  };

  const handleMouseLeave = () => {
    setIsMouseLeave(true);
  };

  const handleMouseOver = () => {
    if (isMouseLeave) {
      copiedAnimationApi.start({
        from: {
          opacity: 1,
        },
        to: {
          opacity: 0,
        },
        onRest() {
          setIsCopied(false);
          setIsMouseLeave(false);
          copyAnimationApi.start({
            from: {
              opacity: 0,
            },
            to: {
              opacity: 1,
            },
          });
        },
      });
    }
  };

  const copyButton = (
    <IconButton
      variant="text"
      color="blue"
      onClick={() => handleCopy(text)}
      onMouseLeave={handleMouseLeave}
    >
      <ClipboardDocumentIcon className="text-white h-[20px]" />
    </IconButton>
  );

  return (
    <div>
      <div className="flex">
        {!isCopied && (
          <animated.div style={copyAnimationStyles}>
            {copyTitle && <Tooltip content={copyTitle}>{copyButton}</Tooltip>}
            {!copyTitle && copyButton}
          </animated.div>
        )}
        {isCopied && (
          <animated.div style={copiedAnimationStyles}>
            <IconButton
              variant="text"
              onMouseOver={handleMouseOver}
              onMouseLeave={handleMouseLeave}
            >
              <ClipboardDocumentCheckIcon className="text-white h-[20px]" />
            </IconButton>
          </animated.div>
        )}
      </div>
    </div>
  );
};

export default React.memo(CopyButton);
