import React from 'react';

import { PrimaryInfoBlock, Section } from '@components/Common';
import { useText } from '@hooks/useText';

import { renderAmount } from '../../utils/renderers';

type PropsType = {
  amount: string;
};

const TodayCashback: React.FC<PropsType> = ({ amount }) => {
  const { t } = useText();

  const renderValue = () => {
    const asset = 'USDT';

    if (parseFloat(amount) === 0) {
      return renderAmount(amount, asset);
    }

    return <>≈ {renderAmount(amount, asset)}</>;
  };

  return (
    <Section>
      <PrimaryInfoBlock title={<>{t('cashback.today')}:</>} value={renderValue()} />
    </Section>
  );
};

export default TodayCashback;
