import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

import CheckboxField, { CheckboxFieldPropsType } from './CheckboxField';

type PropsType = CheckboxFieldPropsType;

const InputFieldController: ControllerComponent<PropsType> = ({ name, control, ...props }) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <CheckboxField {...props} {...field} />}
  />
);

InputFieldController.isController = true;

export default InputFieldController;
