import React, { useMemo } from 'react';

import { IPeriod } from '@interfaces/period';
import { isSameDay } from 'date-fns';
import ChartComponent, { Props } from 'react-apexcharts';

import { roundAmount } from '../../utils/amount';

type PropsType = {
  xLabels: any[];
  data: number[];
  label: string;
  period: IPeriod;
};

const Chart: React.FC<PropsType> = ({ xLabels, data, label, period }) => {
  const chartConfig: Props = useMemo(() => {
    return {
      type: 'line',
      height: 400,
      series: [
        {
          name: label,
          data,
        },
      ],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: ['#ffca28'],
        stroke: {
          lineCap: 'round',
          curve: 'smooth',
        },
        markers: {
          size: 0,
        },
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: '#d8d8e8',
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 500,
            },
            formatter: function (value) {
              const date = new Date(value);

              if (isSameDay(date, period.fromDate) || isSameDay(date, period.toDate)) {
                return value;
              }

              return '';
            },
            rotate: 0,
          },
          categories: xLabels,
        },
        yaxis: {
          labels: {
            style: {
              colors: '#d8d8e8',
              fontSize: '12px',
              fontFamily: 'inherit',
              fontWeight: 500,
            },
            formatter(val: number) {
              return roundAmount(val, 5);
            },
          },
        },
        grid: {
          show: true,
          borderColor: '#f8fafcA6',
          strokeDashArray: 5,
          padding: {
            top: 5,
            right: 20,
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        fill: {
          opacity: 0.8,
        },
        tooltip: {
          theme: 'dark',
          x: {
            formatter(val: number): string {
              return xLabels[val - 1];
            },
          },
        },
      },
    };
  }, [xLabels, data, period]);

  return (
    <div className="py-5">
      <ChartComponent {...chartConfig} />
    </div>
  );
};

export default React.memo(Chart);
