import { historyApi } from '@api/index';
import { usePaginationQuery } from '@hooks/usePaginationQuery';
import { QUERIES } from '@query/queries';

import { OPERATIONS_TYPES } from '../../constants';

export const useHistoryQuery = (historyType: OPERATIONS_TYPES | null) => {
  return usePaginationQuery([QUERIES.HISTORY, historyType], (query) =>
    historyApi.get({ ...query, type: historyType || undefined }),
  );
};
