import React from 'react';

import {getRandomStringFromArray} from "../../utils/get-random-string-from-array";

const allRulesArray = [
 "Соблюдайте дисциплину. Придерживайтесь своего торгового плана, независимо от эмоций."
 ,"Управляйте рисками. Никогда не рискуйте более 1-2% вашего торгового капитала на одной сделке."
 ,"Изучайте рынок. Тщательно анализируйте рынки и оставайтесь в курсе новостей."
 ,"Разрабатывайте стратегию. Создайте четкую торговую стратегию и придерживайтесь её."
 ,"Оценивайте свои эмоции. Понимайте, как эмоции влияют на ваши решения."
 ,"Не торгуйте на удачу. Торговля — это не азартная игра. Действуйте на основе фактов и анализа."
 ,"Используйте стоп-ордера. Они помогут ограничить ваши потери."
 ,"Будьте готовы к неудачам. Потери — часть трейдинга, учитесь на них."
 ,"Диверсифицируйте портфель. Не вкладывайте все средства в один актив."
 ,"Ставьте реалистичные цели и не торопитесь. Не стремитесь быстро разбогатеть, будьте терпеливы."
 ,"Следите за тенденциями. Тренд — ваш друг, используйте его в своих интересах."
 ,"Обучайтесь постоянно. Трейдинг — это бесконечный процесс обучения."
 ,"Не пытайтесь отыграться. Избегайте импульсивных решений после убытков."
 ,"Ведите дневник трейдера. Записывайте свои сделки на видео, в тетрадь и анализируйте их."
 ,"Используйте технический анализ. Графики, индикаторы, уровни поддержки и сопротивления могут быть полезными. Используйте их для принятия решений."
 ,"Не игнорируйте фундаментальный анализ. Новости и экономические события могут сильно повлиять на рынок. Иногда «молнии» делают важные изменения на рынке."
 ,"Контролируйте размер позиции. Не перегружайте свои сделки."
 ,"Досконально освойте Риск-менеджмент. Тщательно изучите и разберитесь, что такое плечи и как с ними работать, торгуя от риска."
 ,"Соблюдайте тайминг. Входите и выходите в правильное время."
 ,"Избегайте шумов рынка. Сосредоточьтесь на своих стратегиях."
 ,"Следите за ликвидностью и волатильностью. Оценивайте, насколько активно торгуется актив."
 ,"Планируйте выход. Заранее определите, когда и по какой цене будете выходить из сделки."
 ,"Доверьтесь своему анализу. Не позволяйте другим трейдерам сбить вас с толку."
 ,"Соблюдайте баланс. Не забывайте отдыхать и избегайте выгорания."
 ,"Работайте над психологией. Развивайте устойчивость к стрессу и давлению рынка."
 ,"Выявляйте свои слабые стороны. Находите и устраняйте свои ментальные и эмоциональные ошибки."
 ,"Общайтесь с другими трейдерами. Делитесь опытом и учитесь друг у друга."
 ,"Используйте для практики счета с небольшим депозитом. Тренируйтесь на маленьких суммах, которые не жалко потерять, прежде чем рисковать большими деньгами."
 ,"Не торгуйте чужими и заемными деньгами. Пока не будете уверены в своей торговле с положительной статистикой, не берите деньги под управление и не занимайте для торговли."
 ,"Торгуйте в хорошем состоянии духа, в хорошем настроении."
 ,"Относитесь к трейдингу, как к бизнесу, а не как к хобби. Трейдинг - это полноценная работа, ее нужно воспринимать как бизнес."
 ,"Будьте гибкими. Трейдер может попытаться диктовать условия рынку, но в этом нет смысла – рынок не заметит этих попыток. Умейте подстраиваться под обстоятельства на рынке."
 ,"Избегайте непонятных ситуаций. Точки входа, цели и риски должны быть четко прогнозируемыми."
 ,"Наберитесь терпения и умейте ждать. Рынки всегда дают возможности для торговли, нужно лишь уметь ждать. Лучше не потерять деньги, чем торговать по сомнительным идеям с высоким риском."
 ,"Контролируйте эмоции. По сути, все сводится к тому, чтобы всегда соблюдать установленные правила."
 ,"Не копите убыток. Для контроля убытков используются стоп-лоссы. Если SL установлен по правилам, его не нужно постоянно сдвигать, это лишь увеличивает убыток."
 ,"Дайте прибыли расти. Вместо фиксированного тейк-профита можно использовать более гибкие варианты сопровождения позиции, например, трейлинг-стоп."
 ,"Не садитесь в \"уходящий поезд\". Лучше пропустить сигнал, чем получить убыток на ровном месте."
 ,"Не распыляйтесь. Не нужно распылять внимание на десятки торговых инструментов. На начальном этапе можно ограничиться 2-5 инструментами, позже увеличить их количество."
 ,"Добивайтесь стабильной прибыли. Для профессионала важен не сиюминутный успех, а показатели на дистанции. Нужно научиться брать небольшой профит, но делать это стабильно."
];

// Компонент для отображения строки по центру экрана
const TradingRules = () => {
    const randomRule = getRandomStringFromArray(allRulesArray);

    return (
        <div className="flex justify-center items-start md:-ml-40 md:-mr-40 lg:-ml-72 lg:-mr-72 2xl:-ml-96 2xl:-mr-96 mt-16">
         <svg xmlns="http://www.w3.org/2000/svg" className="ionicon align-top text-[#4CAF50] min-w-[32px] min-h-[32px] pt-1" viewBox="0 0 512 512" width="32" height="32">
            <path
              d="M304 384v-24c0-29 31.54-56.43 52-76 28.84-27.57 44-64.61 44-108 0-80-63.73-144-144-144a143.6 143.6 0 00-144 144c0 41.84 15.81 81.39 44 108 20.35 19.21 52 46.7 52 76v24M224 480h64M208 432h96M256 384V256"
              fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32"/>
            <path d="M294 240s-21.51 16-38 16-38-16-38-16" fill="none" stroke="currentColor" strokeLinecap="round"
                strokeLinejoin="round" strokeWidth="32"/>
         </svg>
         <p className="text-[#4CAF50] text-xl font-bold text-left">
          {randomRule}
         </p>
        </div>
    );
};

export default TradingRules;