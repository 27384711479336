import React, {useMemo, useState} from 'react';

import LeverageInput from '@components/Leverage/LeverageInput';
import {Button, Typography} from '@material-tailwind/react';
import {t} from "i18next";
import Slider from 'rc-slider';

type PropsType = {
    value?: number,
    onChange?: (value: number) => void,
    max: number,
    popoverHandler: (value: boolean) => void
};

const SLIDER_BACKGROUND_COLOR = '#1D88EB';

const getReminderByMax = (max: number) => {
    if (max < 40) {
        return 5;
    }

    if (max < 80) {
        return 10;
    }

    return 20;
};

const generateMarksNumber = (max: number) => {
    const values: Record<string, number> = {};
    const reminder = getReminderByMax(max);

    for (let i = 1; i <= max; i++) {
        const isFirst = i === 1;
        const isEnoughSpaceBeforeLast = max - i >= reminder;
        const isRemainderValid = i % reminder === 0 && isEnoughSpaceBeforeLast;
        const isLast = i === max;
        const isValid = isFirst || isRemainderValid || isLast;

        if (isValid) {
            values[i] = i;
        }
    }

    return values;
};

const LeverageSlider: React.FC<PropsType> = ({max, value = Math.min(max, 10), onChange, popoverHandler}) => {
    const [leverage, setLeverage] = useState(value);

    const marks = useMemo(() => {
        const number = generateMarksNumber(max);

        return Object.keys(number).reduce<Record<string, React.ReactNode>>((result, key) => {
            result[key] = (
                <Typography className="text-xs" variant="small">
                    {number[key]}
                </Typography>
            );

            return result;
        }, {});
    }, [max]);

  function applyButtonHandler() {
    if (onChange) {
      onChange(leverage);
    }
    popoverHandler(false);
  }

  function cancelButtonHandler() {
    popoverHandler(false);
  }

  return (
        <div className="py-4 px-2">
            <LeverageInput className="mb-6" value={leverage} max={max} onChange={setLeverage}/>
            <Slider
                min={1}
                step={1}
                max={max}
                value={leverage}
                onChange={(value) => setLeverage(value as number)}
                marks={marks}
                styles={{
                    handle: {
                        background: SLIDER_BACKGROUND_COLOR,
                        borderColor: SLIDER_BACKGROUND_COLOR,
                        boxShadow: 'none',
                        opacity: 1,
                    },
                    track: {
                        background: SLIDER_BACKGROUND_COLOR,
                    },
                }}
                dotStyle={{
                    display: 'none',
                }}
            />
            <Button className="mt-10" onClick={applyButtonHandler}>{t('common.apply')}</Button>
            <Button className="mt-10 ml-2" onClick={cancelButtonHandler}>{t('common.cancellation')}</Button>
        </div>
    );
};

export default React.memo(LeverageSlider);
