import React from 'react';

import logoPng from '@assets/logo.png';
import routes from '@config/routes';
import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <NavLink to={routes.HOME} className="h-[40px]">
      <img src={logoPng} alt="logo" className="h-full" />
    </NavLink>
  );
};

export default React.memo(Logo);
