import { pairsApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

const invalidateFuturesPairQuery = (symbols: string[]) => {
  queryClient.setQueriesData<IFuturesPair[]>(QUERIES.FUTURES_PAIRS, (input) => {
    return (
      input?.map((pair) => {
        if (symbols.includes(pair.symbol)) {
          return {
            ...pair,
            leverage: pair.maxLeverage,
          };
        }

        return pair;
      }) || []
    );
  });
};

export const useSetMaxLeverageMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (symbols: string[]) => pairsApi.setMaxLeverage(symbols),
    onSuccess: async (_, variables) => {
      invalidateFuturesPairQuery(variables);
      notify(t('leverage.setMaxLeverageSuccess'), 'success');
    },
    onError: () => {
      notify(t('leverage.setMaxLeverageFailed'), 'error');
    },
  });
};
