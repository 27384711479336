import React from 'react';

import cn from 'classnames';

type PropsType = {
  className?: string;
  cols?: number;
};

const FormRow: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  className,
  cols = 2,
}) => {
  return (
    <div
      className={cn(
        `grid auto-cols-auto grid-rows-1 md:gap-4 grid-cols-1`,
        cols === 2 && 'md:grid-cols-2',
        cols === 3 && 'md:grid-cols-3',
        className,
      )}
    >
      {children}
    </div>
  );
};

export default React.memo(FormRow);
