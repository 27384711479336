import React from 'react';

import verificationSuccessSvg from '@assets/verifications-success.svg';
import { Section } from '@components/Common';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';

const VerificationsSuccess = () => {
  const { t } = useText();
  const isMd = useMd();

  return (
    <div className="flex justify-center">
      <Section className="max-w-2xl" contentClassName="flex items-center flex-col">
        <div className="py-10">
          <img src={verificationSuccessSvg} alt="Succes icon" />
        </div>
        <Typography variant={isMd ? 'h3' : 'h4'} className="mb-3 text-center">
          {t('verification.success')}
        </Typography>
        <Typography variant="paragraph" className="font-medium text-center">
          {t('verification.successDescription')}
        </Typography>
      </Section>
    </div>
  );
};

export default React.memo(VerificationsSuccess);
