import React, { useEffect } from 'react';

import { Section, TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import DealsTable from '@components/Statistic/DealsTable';
import StatisticHeading from '@components/Statistic/StatisticHeading';
import { useFuturesDealsQuery } from '@hooks/queries/useFuturesDealsQuery';
import { IPeriod } from '@interfaces/period';
import { IPnls } from '@interfaces/pnl';

type PropsType = TabPropsType & {
  period: IPeriod;
  pnls?: IPnls;
  isLoading: boolean;
  isSynced: boolean;
  onPeriodChange: (period: IPeriod) => void;
  setIsPnlWithFee: (checked: boolean) => void;
  isPnlWithFee: boolean;
};

const ClosedOrdersTab: React.FC<PropsType> = ({
  period,
  isActive,
  onPeriodChange,
  pnls,
  isLoading,
  isSynced,
  isPnlWithFee,
  setIsPnlWithFee,
}) => {
  const {
    query: { data: deals, isLoading: isDealsLoading, isFetching: isDealsFetching },
    pagination: dealsPagination,
    utils: { resetQuery: resetDeaslsQuery },
  } = useFuturesDealsQuery(period, isActive && isSynced);

  useEffect(() => {
    resetDeaslsQuery();
  }, [isActive, period.fromDate, period.toDate]);

  if (isLoading || isDealsLoading) {
    return <Preloader />;
  }

  if (!pnls || !deals) {
    return null;
  }

  return (
    <Section>
      <StatisticHeading
        pnls={pnls}
        period={period}
        setIsPnlWithFee={setIsPnlWithFee}
        onPeriodChange={onPeriodChange}
        isPnlWithFee={isPnlWithFee}
      />
      <DealsTable
        data={deals}
        isPnlWithFee={isPnlWithFee}
        pagination={dealsPagination}
        isLoading={isDealsFetching}
      />
    </Section>
  );
};

export default React.memo(ClosedOrdersTab);
