import AmountField, { AmountFieldPropsType } from '@components/Form/Fields/AmountField/AmountField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

type PropsType = AmountFieldPropsType;

const AmountFieldController: ControllerComponent<PropsType> = ({ name, control, ...props }) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <AmountField {...props} {...field} />}
  />
);

AmountFieldController.isController = true;

export default AmountFieldController;
