import { referralApi } from '@api/index';
import { usePaginationQuery } from '@hooks/usePaginationQuery';
import { QUERIES } from '@query/queries';

export const useReferralsQuery = (term: string, enabled?: boolean) => {
  return usePaginationQuery(
    [QUERIES.REFERRALS, term],
    (query) => referralApi.getReferrals({ ...query, term: term || undefined }),
    { enabled },
  );
};
