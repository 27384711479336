import InputField, { InputFieldPropsType } from '@components/Form/Fields/InputField/InputField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

type PropsType = InputFieldPropsType;

const InputFieldController: ControllerComponent<PropsType> = ({ name, control, ...props }) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <InputField {...props} {...field} />}
  />
);

InputFieldController.isController = true;

export default InputFieldController;
