import { walletApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useQuery } from 'react-query';

export const useFuturesAssets = (enabled?: boolean) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery(['futures-assets'], () => walletApi.getFuturesAssets(), {
    enabled,
    onError: () => notify(t('errors.common')),
  });
};
