import React from 'react';

import {
  IGetReferralOverviewResponse,
  IGetReferralUrlResponse,
} from '@api/referral/referral-api.interfaces';
import { SearchInput } from '@components/Common';
import Section from '@components/Common/Section';
import { ReferralsTable } from '@components/Referral/index';
import { IReferralForm } from '@components/Referral/ReferralsForm';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { IReferral } from '@interfaces/referrals';

type PropsType = {
  historyMode: boolean;
  referralsTotal: IGetReferralOverviewResponse;
  referrals: IReferral[];
  getReferralUrl: IGetReferralUrlResponse;
  handleSubmit: (data: IReferralForm) => void;
  isReferralsFetching: boolean;
  onSearch: (term: string) => void;
  pagination: IPaginationData;
};

const ReferralsBlock: React.FC<PropsType> = ({
  referrals,
  historyMode,
  isReferralsFetching,
  onSearch,
  pagination,
}) => {
  return (
    <div>
      {!historyMode && (
        <Section>
          <div className="flex justify-end">
            <SearchInput onChange={onSearch} className="!mb-0 max-w-full md:!max-w-[200px]" />
          </div>
          <ReferralsTable
            data={referrals}
            isLoading={isReferralsFetching}
            pagination={pagination}
          />
        </Section>
      )}
    </div>
  );
};

export default React.memo(ReferralsBlock);
