import React from 'react';

import telegramWhitePNG from '@assets/telegram-white.png';
import env from '@config/env';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import { NavLink } from 'react-router-dom';

const TelegramBot = () => {
    const { t } = useText();
    const isMd = useMd();
    return (
        <NavLink to={`${env.TELEGRAM_BOT_LINK}`}>
            <Typography
                as="span"
                color="white"
                variant="small"
                className="whitespace-nowrap transition-all hover:brightness-110 hover:text-[#fff] focus:text-[#fff] bg-gray-900 px-3 md:px-5 py-2 rounded-full inline-flex items-center"
            >
                {isMd && t('footer.help')}{' '}
                <img className="ml-2 mr-1" src={telegramWhitePNG} alt="telegram"/>
                {env.TELEGRAM_BOT_NAME}
            </Typography>
        </NavLink>
    );
};

export default TelegramBot;
