import { IApiPaginationQuery, IApiPaginationResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import {
  ICashbackEstimate,
  ICashbackHistory,
  ICashbackRates,
  ICashbackTotal,
} from '@interfaces/cashback';

export class CashbackApi extends BaseApi {
  public getHistory(query?: IApiPaginationQuery) {
    return this.client.get<IApiPaginationResponse<ICashbackHistory>>(
      endpoints.CASHBACK.INDEX,
      query,
    );
  }

  public getEstimate() {
    return this.client.get<ICashbackEstimate>(endpoints.CASHBACK.ESTIMATE);
  }

  public getTotal() {
    return this.client.get<ICashbackTotal>(endpoints.CASHBACK.TOTAL);
  }

  public getRates() {
    return this.client.get<ICashbackRates>(endpoints.CASHBACK.RATES);
  }
}
