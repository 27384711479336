import React, { useState } from 'react';

import { TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import CoinsSection from '@components/Wallet/CoinsSection';
import { OrdersSection, PositionsSection } from '@components/Wallet/index';
import WalletTab from '@components/Wallet/WalletTab';
import { useFuturesAssets } from '@hooks/queries/useFuturesAssets';
import { useOrders } from '@hooks/queries/useOrders';
import { usePositions } from '@hooks/queries/usePositions';
import { useText } from '@hooks/useText';
import { IFuturesBalance } from '@interfaces/balances';
import { Typography } from '@material-tailwind/react';

import { ORDERS_SOURCE } from '../../constants';

type PropsType = TabPropsType & {
  onCloseAll?: () => void;
  isAllClosing?: boolean;
  balance?: IFuturesBalance;
};

const FuturesTab: React.FC<PropsType> = ({ isActive, onCloseAll, isAllClosing, balance }) => {
  const [assetsMode, setAssetsMode] = useState(false);
  const { t } = useText();
  const { data: positions, isLoading: isPositionsLoading } = usePositions(isActive && !assetsMode);
  const { data: orders, isLoading: isOrdersLoading } = useOrders(
    ORDERS_SOURCE.FUTURES,
    isActive && !assetsMode,
  );
  const { data: futuresAssets, isLoading: isFuturesAssetsFetching } = useFuturesAssets(
    isActive && assetsMode,
  );

  const enableAssetsMode = () => setAssetsMode(true);

  const disableAssetsMode = () => setAssetsMode(false);

  if (isPositionsLoading || isOrdersLoading || isFuturesAssetsFetching) {
    return <Preloader />;
  }

  return (
    <WalletTab
      leftSide={
        <Typography variant="h3">
          {assetsMode ? t('wallet.assets') : t('wallet.positions')}
        </Typography>
      }
      isAllClosing={isAllClosing}
      onCloseAll={onCloseAll}
    >
      {!assetsMode && positions && balance && orders && (
        <>
          <PositionsSection
            positions={positions}
            balance={balance}
            onAssetsMode={enableAssetsMode}
          />
          <Typography variant="h3" className="mb-6 mt-6">
            {t('wallet.orders')}
          </Typography>
          <OrdersSection source={ORDERS_SOURCE.FUTURES} orders={orders} />
        </>
      )}
      {assetsMode && futuresAssets && (
        <CoinsSection
          assets={futuresAssets}
          onBack={disableAssetsMode}
          emptyText={t('wallet.emptyAssets')}
        />
      )}
    </WalletTab>
  );
};

export default React.memo(FuturesTab);
