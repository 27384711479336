import { IDeleteOrderPayload } from '@api/orders/orders-api.interfaces';
import { OrdersApi } from '@api/orders/orders.api';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IOrder } from '@interfaces/order';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

import { ORDERS_SOURCE } from '../../constants';
import { getOrdersApiBySource } from '../../utils/get-orders-api-by-source';

const mutationFn = (api: OrdersApi) => (orders: IOrder[]) => {
  const payload: IDeleteOrderPayload[] = orders.map((order) => ({
    symbol: order.symbol,
    orderId: order.id,
  }));

  return api.deleteOrders(payload);
};

export const useDeleteOrdersMutation = (source: ORDERS_SOURCE) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: mutationFn(getOrdersApiBySource(source)),
    onSuccess: (_, variables) => {
      const message =
        variables.length === 1 ? t('order.deleteSuccess') : t('order.deleteSuccessMultiple');

      notify(message, 'success');
      queryClient.setQueriesData<IOrder[]>(QUERIES.ORDERS, (input) => {
        return input?.filter((order: IOrder) => !variables.some((v) => v.id === order.id)) || [];
      });
    },
    onError: (_, variables) => {
      const message =
        variables.length === 1 ? t('order.deleteFailed') : t('order.deleteFailedMultiple');

      notify(message, 'error');
    },
  });
};
