import React from 'react';

import telegramSvg from '@assets/telegram.svg';
import youtubeSvg from '@assets/youtube.svg';
import { useMd } from '@hooks/responsive/useMd';
import { IconButton } from '@material-tailwind/react';

interface ILink {
  name: string;
  icon: string;
  url: string;
}

const links: ILink[] = [
  {
    name: 'Telegram',
    icon: telegramSvg,
    url: 'https://t.me/CryptoPrime24',
  },
  {
    name: 'Youtube',
    icon: youtubeSvg,
    url: 'https://www.youtube.com/@cryptoprime6822',
  },
];

const SocialLinks = () => {
  const isMd = useMd();

  return (
    <div className="flex gap-2 items-center">
      {links.map((link) => (
        <IconButton key={link.name} color="gray" size={isMd ? 'md' : 'sm'}>
          <a
            href={link.url}
            target="_blank"
            rel="noreferrer"
            className="opacity-75 transition-opacity hover:opacity-100"
          >
            <img src={link.icon} alt={link.name} className="w-7" />
          </a>
        </IconButton>
      ))}
    </div>
  );
};

export default React.memo(SocialLinks);
