import React, { useMemo } from 'react';

import ApiKeyAccess from '@components/ApiKeys/ApiKeyAccess';
import { LoadingIconButton, Section, Table } from '@components/Common';
import routes from '@config/routes';
import { TrashIcon } from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { IApiKey } from '@interfaces/api-key';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';

import { pathToUrl } from '../../utils/path-to-url';

type PropsType = {
  apiKeys: IApiKey[];
  className?: string;
  onDelete: (apiKey: IApiKey) => void;
  deletingId?: number;
};

const columnHelper = createColumnHelper<IApiKey>();

const ApiKeysTable: React.FC<PropsType> = ({ apiKeys, className, onDelete, deletingId }) => {
  const { t } = useText();
  const isMd = useMd();
  const navigate = useNavigate();

  const handleDelete = (apiKey: IApiKey) => (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(apiKey);
  };

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor('title', {
        header: () => t('api.name'),
        cell: (info) => (
          <Typography variant="paragraph" className="font-semibold text-sm">
            {info.getValue()}
          </Typography>
        ),
        meta: {
          className: 'md:w-[50%]',
        },
      }),
    ];

    if (isMd) {
      cols.push(
        columnHelper.display({
          id: 'access',
          header: () => t('api.access'),
          cell: (info) => <ApiKeyAccess apiKey={info.row.original} />,
          meta: {
            className: 'md:w-[40%]',
          },
        }),
      );
    }

    cols.push(
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const id = info.row.original.id;

          return (
            <LoadingIconButton
              variant="text"
              color="blue"
              onClick={handleDelete(info.row.original)}
              isLoading={deletingId === id}
            >
              <TrashIcon className="text-white h-[18px]" />
            </LoadingIconButton>
          );
        },
        minSize: 40,
        maxSize: 50,
        size: 50,
      }),
    );

    return cols;
  }, [isMd, onDelete, deletingId]);

  const handleRowClick = (apiKey: IApiKey) => {
    navigate(pathToUrl(routes.API_KEYS_UPDATE, { id: apiKey.id }));
  };

  return (
    <Section className={className}>
      <Table
        data={apiKeys}
        columns={columns}
        rowClassName="hover:from-blue-500 hover:to-blue-400 hover:bg-gradient-to-tr cursor-pointer"
        onRowClick={handleRowClick}
        emptyText={t('api.empty')}
      />
    </Section>
  );
};

export default React.memo(ApiKeysTable);
