import React from 'react';

import { AuthLink, LoadingButton } from '@components/Common';
import { Form, FormInputField, FormNewPasswordField } from '@components/Form';
import { INewPasswordFieldValue } from '@components/Form/Fields/NewPasswordField/NewPasswordField';
import { passwordValidationSchema } from '@components/Form/Fields/NewPasswordField/validation';
import routes from '@config/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { setTextPath, useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

type PropsType = {
  onSubmit: (data: ISignUpFormData) => Promise<any>;
};

export interface ISignUpFormData {
  email: string;
  password: INewPasswordFieldValue;
}

const FIELD_NAMES: { [key in keyof ISignUpFormData]: key } = {
  email: 'email',
  password: 'password',
};

const schema: yup.ObjectSchema<ISignUpFormData> = yup
  .object({
    email: yup
      .string()
      .required(setTextPath('errors.required'))
      .email(setTextPath('signUp.emailError')),
    password: passwordValidationSchema,
  })
  .required();

const SignUpForm: React.FC<PropsType> = ({ onSubmit }) => {
  const { t } = useText();
  const formMethods = useForm<ISignUpFormData>({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} errorText={t('errors.accountCreation')}>
      <div className="mb-6">
        <FormInputField
          name={FIELD_NAMES.email}
          label={t('signUp.emailLabel')}
          autoComplete="username"
        />
        <FormNewPasswordField name={FIELD_NAMES.password} />
      </div>
      <LoadingButton
        fullWidth
        isLoading={formMethods.formState.isSubmitting}
        type="submit"
        className="w-full"
      >
        {t('signUp.continue')}
      </LoadingButton>
      <div className="mt-3 flex items-center w-full">
        <Typography className="text-sm font-semibold">{t('signIn.alreadyHave')}</Typography>
        <AuthLink to={routes.LOGIN} className="ml-1">
          {t('signIn.index')}
        </AuthLink>
      </div>
    </Form>
  );
};

export default React.memo(SignUpForm);
