export default class SearchEngine<Entity extends Record<string, any>> {
  private searchKeys: (keyof Entity)[] = [];
  private term: string = '';

  constructor(keys: (keyof Entity)[]) {
    this.searchKeys = keys;
  }

  public search(collection: Entity[], term: string): Entity[] {
    this.term = term;

    if (this.term) {
      return collection.filter(this.filterItem.bind(this));
    }

    return collection;
  }

  public getTerm() {
    return this.term;
  }

  private filterItem(item: Entity) {
    return this.getSearchString(item).includes(this.term.toLowerCase());
  }

  private getSearchString(item: Entity) {
    return this.searchKeys
      .map((key) => item[key])
      .filter(Boolean)
      .join(' ')
      .toLowerCase()
      .trim();
  }
}
