import React, { useMemo } from 'react';

import { Pnl, Table } from '@components/Common';
import Deal from '@components/Statistic/Deal';
import { useLg } from '@hooks/responsive/useLg';
import { useXl } from '@hooks/responsive/useXl';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { IDeal } from '@interfaces/deal';
import { Typography } from '@material-tailwind/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import { roundAmount } from '../../utils/amount';
import { calcDealRealizedPnl } from '../../utils/deals';
import { renderAmount, renderPositionSide } from '../../utils/renderers';

type PropsType = {
  data: IDeal[];
  pagination: IPaginationData;
  isLoading?: boolean;
  isPnlWithFee: boolean;
};

const columnHelper = createColumnHelper<IDeal>();

const DealsTable: React.FC<PropsType> = ({ data, pagination, isLoading, isPnlWithFee }) => {
  const { t } = useText();
  const isLg = useLg();
  const isXl = useXl();

  const columns = useMemo(() => {
    if (!isLg) {
      return [
        columnHelper.display({
          id: 'history',
          header: () => t('history.index'),
          cell: (info) => (
            <Deal
              deal={info.row.original}
              realizedPnl={roundAmount(calcDealRealizedPnl(info.row.original, isPnlWithFee))}
            />
          ),
        }),
      ];
    }

    return [
      columnHelper.accessor('symbol', {
        header: () => t('order.pair'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {info.getValue()}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('quantity', {
        header: () => t('order.quantity'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('avgEntryPrice', {
        header: () => t('order.entryPrice'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('avgExitPrice', {
        header: () => t('order.exitPrice'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {roundAmount(info.getValue(), 5)}
          </Typography>
        ),
        size: 100,
      }),
      columnHelper.accessor('side', {
        header: () => t('position.side'),
        cell: (info) => renderPositionSide(info.getValue()),
        size: 100,
      }),
      columnHelper.display({
        id: 'pnl',
        header: () => t('position.realizedPnl'),
        cell: (info) => {
          return (
            <Pnl
              pnl={roundAmount(calcDealRealizedPnl(info.row.original, isPnlWithFee))}
              asset={info.row.original.marginAsset}
            />
          );
        },
        size: 150,
      }),
      isXl
        ? columnHelper.display({
            id: 'fee',
            header: () => t('wallet.fee'),
            cell: (info) => (
              <Typography variant="small" className="font-semibold">
                {renderAmount(
                  roundAmount(info.row.original.totalFee, 5),
                  info.row.original.feeAsset,
                )}
              </Typography>
            ),
            size: 150,
          })
        : undefined,
      columnHelper.accessor('createdAt', {
        header: () => t('statistic.tradeTime'),
        cell: (info) => (
          <Typography className="font-semibold text-sm whitespace-nowrap">
            {format(new Date(info.getValue()), 'MM/dd/yyyy H:mm')}
          </Typography>
        ),
        size: 150,
      }),
    ].filter(Boolean) as ColumnDef<IDeal, IDeal>[];
  }, [data, isLg, isXl, isPnlWithFee]);

  return (
    <Table
      columns={columns}
      data={data}
      emptyText={t('statistic.empty')}
      onPageChange={pagination.setPage}
      page={pagination.page}
      itemsPerPage={pagination.itemsPerPage}
      itemsCount={pagination.totalCount || 0}
      isLoading={isLoading}
      witPagination
    />
  );
};

export default React.memo(DealsTable);
