import React from 'react';

import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { VerificationProvider, VerificationsSuccess } from '@components/Verification';
import { useVerificationRequestQuery } from '@hooks/queries/useVerificationRequestQuery';
import { useText } from '@hooks/useText';
import { VERIFICATION_STATUS } from '@interfaces/user';

const VerificationPage = () => {
  const { data: verification, isLoading } = useVerificationRequestQuery();
  const { t } = useText();

  if (isLoading) {
    return <Preloader />;
  }

  if (!verification) {
    return null;
  }

  return (
    <PageLayout title={t('verification.title')} contentClassName="justify-center">
      {verification.status === VERIFICATION_STATUS.VERIFIED && <VerificationsSuccess />}
      {verification.status !== VERIFICATION_STATUS.VERIFIED && (
        <VerificationProvider
          provider={verification.provider}
          data={verification.data}
          status={verification.status}
        />
      )}
    </PageLayout>
  );
};

export default React.memo(VerificationPage);
