import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { Alert } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  texts: string[];
  className?: string;
};

const AlertsList: React.FC<PropsType> = ({ texts, className }) => {
  const { t } = useText();
  const isMd = useMd();

  if (isMd) {
    return (
      <div className={cn('flex justify-between gap-4', className)}>
        {texts.map((text) => (
          <Alert
            key={text}
            variant="gradient"
            color="gray"
            icon={<InformationCircleIcon className="text-white w-[24px]" />}
          >
            {t(text as never)}
          </Alert>
        ))}
      </div>
    );
  }

  return (
    <div className={cn('flex justify-between gap-4', className)}>
      <Alert
        variant="gradient"
        color="gray"
        icon={<InformationCircleIcon className="text-white w-[24px]" />}
      >
        <ul className="ml-3 list-disc">
          {texts.map((text) => (
            <li key={text} className="mb-1 last:mb-0">
              {t(text as never)}
            </li>
          ))}
        </ul>
      </Alert>
    </div>
  );
};

export default React.memo(AlertsList);
