import React from 'react';

import { API_ERROR_CODES } from '@api/api.constants';
import { ApiKeyForm, ApiKeySecretInfo } from '@components/ApiKeys';
import { IApiKeyFormData } from '@components/ApiKeys/ApiKeyForm';
import { Section } from '@components/Common';
import TwoFactorAuth from '@components/Common/TwoFactorAuth';
import { PageLayout } from '@components/Layout';
import { useCreateApiKeyMutation } from '@hooks/mutations/useCreateApiKeyMutation';
import { use2FA } from '@hooks/use2FA';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';

import { TWO_FACTOR_AUTH_ACTIONS } from '../../constants';

const APIKeysCreatePage = () => {
  const { t } = useText();
  const { mutateAsync, isSuccess, data, error, isLoading: isCreating } = useCreateApiKeyMutation();

  const { activate: createApiKey, ...twoFactorAuthState } = use2FA<IApiKeyFormData>({
    callback: mutateAsync,
    mapDataTo2FAPayload: (data) => {
      return {
        action: TWO_FACTOR_AUTH_ACTIONS.API_KEY_CREATE,
        payload: {
          name: data.title,
        },
      };
    },
  });

  const getErrorText = () => {
    if (!error) {
      return undefined;
    }

    if (error.response?.code === API_ERROR_CODES.API_KEYS_LIMIT_EXCEEDED) {
      return t('api.limit');
    }

    return t('api.failed');
  };

  return (
    <PageLayout contentClassName="items-center md:pt-20">
      <div className="w-full max-w-xl">
        <Typography variant="h4" className="font-bold mb-5">
          {isSuccess && data && data.title}
          {!isSuccess && t('api.creation')}
        </Typography>
        <Section>
          {isSuccess && data && <ApiKeySecretInfo apiKey={data} />}
          {!isSuccess && <ApiKeyForm onSubmit={createApiKey} error={getErrorText()} />}
        </Section>
      </div>
      <TwoFactorAuth {...twoFactorAuthState} isVerifying={isCreating} />
    </PageLayout>
  );
};

export default React.memo(APIKeysCreatePage);
