import React, { useEffect, useMemo, useState } from 'react';

import { LoadingButton } from '@components/Common';
import { SelectField } from '@components/Form';
import LeverageSelect from '@components/Leverage/LeverageSelect';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { IOption } from '@interfaces/option';

import { MARGIN_TYPE } from '../../constants';
import { marginTypes } from '../../data/margin-types';
import { getMaxPairsLeverage } from '../../utils/futures-pairs';

type PropsType = {
  selectedPairs: IFuturesPair[];
  isMarginTypeUpdating?: boolean;
  isLeverageUpdating?: boolean;
  isMaxLeverageSetting?: boolean;
  onMarginTypeChange: (type: MARGIN_TYPE) => void;
  onLeverageChange: (leverage: number) => void;
  onSetMaxLeverage: () => void;
};

const LeverageMultipleActions: React.FC<PropsType> = ({
  selectedPairs,
  isMarginTypeUpdating,
  isLeverageUpdating,
  isMaxLeverageSetting,
  onMarginTypeChange,
  onLeverageChange,
  onSetMaxLeverage,
}) => {
  const { t } = useText();
  const [selectedMarginType, setSelectedMarginType] = useState<IOption<MARGIN_TYPE> | null>(null);
  const [selectedLeverage, setSelectedLeverage] = useState<number | undefined>();

  useEffect(() => {
    setSelectedMarginType(null);
    setSelectedLeverage(undefined);
  }, [selectedPairs]);

  const handleMarginTypeChange = (option: IOption<MARGIN_TYPE> | null) => {
    if (option) {
      onMarginTypeChange(option.value);
    }

    setSelectedMarginType(option);
  };

  const handleLeverageChange = (leverage: number) => {
    onLeverageChange(leverage);
    setSelectedLeverage(leverage);
  };

  const maxLeverage = useMemo(() => {
    return getMaxPairsLeverage(selectedPairs);
  }, [selectedPairs]);

  return (
    <div className="flex flex-wrap gap-3 md:gap-6 lg:flex-nowrap items-center mt-8 md:mt-3 xl:mt-0 justify-end w-full xl:w-auto">
      <SelectField
        label={t('leverage.margin')}
        options={marginTypes}
        value={selectedMarginType}
        onChange={handleMarginTypeChange}
        disabled={isMarginTypeUpdating}
        className="md:w-[170px] !mb-0"
      />
      <div className="flex gap-3 md:gap-6 flex-wrap lg:flex-nowrap w-full sm:w-auto">
        <LeverageSelect
          size="md"
          value={selectedLeverage}
          max={maxLeverage}
          disabled={isLeverageUpdating}
          onChange={handleLeverageChange}
        />
        <LoadingButton
          variant="outlined"
          color="gray"
          className="whitespace-nowrap w-full sm:w-auto normal-case text-base font-semibold py-[9px]"
          onClick={onSetMaxLeverage}
          isLoading={isMaxLeverageSetting}
        >
          {t('leverage.setMaxLeverage')}
        </LoadingButton>
      </div>
    </div>
  );
};

export default React.memo(LeverageMultipleActions);
