import React, { useEffect, useState } from 'react';

import {
  Tabs as MaterialTabs,
  Tab,
  TabPanel,
  TabsBody,
  TabsHeader,
} from '@material-tailwind/react';
import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';

export interface ITab {
  label: string;
  value: string;
  icon?: any;
  content: React.ReactNode;
}

type PropsType = {
  defaultTab?: string;
  tabs: ITab[];
  className?: string;
  headerClassName?: string;
  useLocationAsState?: boolean;
  orientation?: 'horizontal' | 'vertical';
  rightHeaderElement?: React.ReactNode;
  isTabsActive?: boolean;
};

export type TabPropsType = {
  isActive?: boolean;
};

const Tabs: React.FC<PropsType> = ({
  tabs,
  defaultTab,
  className,
  headerClassName,
  useLocationAsState,
  orientation,
  rightHeaderElement,
  isTabsActive = true,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>('');

  const getInitialTab = () => {
    if (useLocationAsState) {
      const isValid = tabs.some((t) => t.value === location.pathname);

      if (isValid) {
        return location.pathname;
      }
    }

    return defaultTab || tabs[0]?.value || '';
  };

  useEffect(() => {
    const initialTab = getInitialTab();

    setActiveTab(initialTab);

    if (useLocationAsState && location.pathname !== initialTab) {
      navigate(initialTab);
    }
  }, []);

  const handleChange = (value: string) => {
    setActiveTab(value);

    if (useLocationAsState) {
      navigate(value);
    }
  };

  if (!activeTab) {
    return null;
  }

  return (
    <MaterialTabs
      value={activeTab}
      className={cn('w-full overflow-visible', className)}
      orientation={orientation}
    >
      <div className={cn(rightHeaderElement && 'flex justify-between')}>
        <TabsHeader
          className={cn(
            'bg-transparent w-full',
            orientation === 'vertical' && 'flex flex-col',
            headerClassName,
          )}
          indicatorProps={{
            className: 'bg-gradient-to-tr from-gray-900 to-gray-800 shadow-gray-900/20 shadow-md',
          }}
        >
          {tabs.map(({ label, value, icon: Icon }) => (
            <Tab
              key={value}
              value={value}
              className={cn(
                'text-white font-semibold text-base md:text-lg md:mr-6 last:mr-0 md:px-6',
                activeTab === value && 'text-[#fff]',
              )}
              onClick={() => handleChange(value)}
            >
              <div className="flex items-center">
                {label}
                {Icon && <Icon className="h-5 w-5 ml-4" />}
              </div>
            </Tab>
          ))}
        </TabsHeader>
        {rightHeaderElement && <div>{rightHeaderElement}</div>}
      </div>
      <TabsBody className="w-full overflow-visible">
        {tabs.map(({ value, content }) => {
          const isActive = value === activeTab && isTabsActive;

          return (
            <TabPanel key={value} value={value} className="px-0 md:px-4">
              {React.cloneElement<TabPropsType>(
                content as React.DetailedReactHTMLElement<any, HTMLElement>,
                {
                  isActive,
                },
              )}
            </TabPanel>
          );
        })}
      </TabsBody>
    </MaterialTabs>
  );
};

export default React.memo(Tabs);
