import { LANGUAGES } from '../constants';

export interface IUser {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  email: string;
  isEmailVerified: boolean;
  verification: IUserVerification | null;
  is2FAEnabled: boolean;
  require2FAOnSignIn: boolean;
  lng: LANGUAGES;
}

export interface IUserVerification {
  level: VERIFICATION_LEVELS;
  result: VERIFICATION_RESULTS;
}

export enum VERIFICATION_LEVELS {
  BASIC_KYC = 'basic-kyc-level',
}

export enum VERIFICATION_STATUS {
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  PENDING = 'PENDING',
}

export enum VERIFICATION_RESULTS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}
