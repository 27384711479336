import React from 'react';

import { SelectField } from '@components/Form';
import LeverageInput from '@components/Leverage/LeverageSelect';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { Spinner, Typography } from '@material-tailwind/react';

import { MARGIN_TYPE } from '../../constants';
import { marginTypes } from '../../data/margin-types';

type PropsType = {
  pair: IFuturesPair;
  isLoading?: boolean;
  onMarginTypeChange: (pairs: IFuturesPair[], type: MARGIN_TYPE) => void;
  onLeverageChange: (symbols: string[], leverage: number) => void;
};

const PairLeverage: React.FC<PropsType> = ({
  pair,
  isLoading,
  onLeverageChange,
  onMarginTypeChange,
}) => {
  const { t } = useText();
  const marginTypeOption = marginTypes.find((t) => t.value === pair.marginType);

  if (isLoading) {
    return (
      <div className="w-full">
        <Spinner color="blue" />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <Typography variant="paragraph" className="font-semibold mb-6">
        {pair.symbol}
      </Typography>
      <div className="sm:flex gap-3">
        <SelectField
          label={t('leverage.margin')}
          className="md:max-w-[180px] !mb-3"
          value={marginTypeOption}
          options={marginTypes}
          onChange={(value) => onMarginTypeChange([pair], value.value)}
        />
        <LeverageInput
          value={pair.leverage}
          max={pair.maxLeverage}
          onChange={(value) => onLeverageChange([pair.symbol], value)}
          size="md"
        />
      </div>
    </div>
  );
};

export default React.memo(PairLeverage);
