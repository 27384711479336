import React from 'react';

import { useText } from '@hooks/useText';
import { IReferral } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';

import { renderAmount } from '../../utils/renderers';

type PropsType = {
  referral: IReferral;
};

const Referrals: React.FC<PropsType> = ({ referral }) => {
  const { t } = useText();

  return (
    <div>
      <Typography className="font-semibold mb-3">{referral.email}</Typography>
      <Typography variant="small" className="font-semibold">
        {t('referrals.income')}: {renderAmount(referral.progress, referral.progressAsset)}
      </Typography>
      <Typography variant="small" className="font-semibold">
        {t('cashback.volume')}: {renderAmount(referral.volume, referral.progressAsset)}
      </Typography>
    </div>
  );
};

export default React.memo(Referrals);
