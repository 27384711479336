import { useCallback } from 'react';
import * as React from 'react';

import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { Alert } from '@material-tailwind/react';
import { toast } from 'react-toastify';
import { ToastContentProps, TypeOptions } from 'react-toastify/dist/types';

const icons: Record<TypeOptions, React.ForwardRefExoticComponent<any>> = {
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationCircleIcon,
  error: ExclamationTriangleIcon,
  default: InformationCircleIcon,
};

const renderAlert =
  (message: string) =>
  ({ toastProps }: ToastContentProps<string>) => {
    const Icon = icons[toastProps.type];

    return (
      <Alert icon={<Icon className="text-white w-[24px]" />} variant="gradient">
        {message}
      </Alert>
    );
  };

export const useNotification = () => {
  const notify = useCallback((message: string, type: TypeOptions = 'error') => {
    toast(renderAlert(message), {
      toastId: message,
      type,
    });
  }, []);

  return { notify };
};
