import React, { useMemo } from 'react';

import { ITab } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { ConfirmModal } from '@components/Modals';
import { FuturesTab, SpotTab, WalletLayout } from '@components/Wallet';
import { useCloseAllOrdersAndPositionsMutation } from '@hooks/mutations/useCloseAllOrdersAndPositionsMutation';
import { useFuturesBalance } from '@hooks/queries/useFuturesBalance';
import { useFuturesPnlForTodayQuery } from '@hooks/queries/useFuturesPnlForTodayQuery';
import { useSpotBalance } from '@hooks/queries/useSpotBalance';
import { useConfirm } from '@hooks/useConfirm';
import { useText } from '@hooks/useText';
import { IWalletsBalance } from '@interfaces/balances';
import { Typography } from '@material-tailwind/react';

import { WALLET_TYPE } from '../../constants';

const WalletPage = () => {
  const { t } = useText();
  const { data: spotBalance, isLoading: isSpotBalanceLoading } = useSpotBalance();
  const { data: futuresBalance, isLoading: isFuturesBalanceLoading } = useFuturesBalance();
  const { data: futuresPnl, isLoading: isFuturesPnlLoading } = useFuturesPnlForTodayQuery();
  const { mutate: closeAllOrdersAndPositions, isLoading: isAllOrdersAndPositionsClosing } =
    useCloseAllOrdersAndPositionsMutation();

  const { confirm: confirmCloseAllOrdersAndPositions, ...confirmProps } = useConfirm(
    closeAllOrdersAndPositions,
  );

  const tabs: ITab[] = [
    {
      label: t('wallet.futuresUSDM'),
      value: WALLET_TYPE.USDT_FUTURE,
      content: (
        <FuturesTab
          onCloseAll={confirmCloseAllOrdersAndPositions}
          isAllClosing={isAllOrdersAndPositionsClosing}
          balance={futuresBalance}
        />
      ),
    },
    {
      label: t('wallet.spot'),
      value: WALLET_TYPE.SPOT,
      content: (
        <SpotTab
          onCloseAll={confirmCloseAllOrdersAndPositions}
          isAllClosing={isAllOrdersAndPositionsClosing}
        />
      ),
    },
  ];

  const balance = useMemo<IWalletsBalance>(() => {
    const spot = spotBalance?.balance || '0';
    const futuresUSDM = futuresBalance?.totalMarginBalance || '0';
    const total = parseFloat(spot) + parseFloat(futuresUSDM);

    return {
      spot,
      futuresUSDM,
      total: total.toString(),
    };
  }, [spotBalance, futuresBalance]);

  if (isSpotBalanceLoading || isFuturesBalanceLoading || isFuturesPnlLoading) {
    return <Preloader />;
  }

  if (!spotBalance || !futuresBalance || !futuresPnl) {
    return null;
  }

  return (
    <PageLayout title={t('wallet.overview')}>
      <WalletLayout
        tabs={tabs}
        balance={balance}
        futuresPnl={futuresPnl}
        onCloseAll={confirmCloseAllOrdersAndPositions}
        isAllClosing={isAllOrdersAndPositionsClosing}
      />
      <ConfirmModal {...confirmProps} title={t('common.confirmAction')}>
        <Typography variant="small" className="font-semibold">
          {t('wallet.closeAllConfirm')}
        </Typography>
      </ConfirmModal>
    </PageLayout>
  );
};

export default React.memo(WalletPage);
