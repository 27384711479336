import React from 'react';

import { LoadingButton } from '@components/Common';
import { Form, FormInputField } from '@components/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setTextPath, useText } from '@hooks/useText';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

type PropsType = {
  onSubmit: (data: IReferralForm) => void;
};

export interface IReferralForm {
  email: string;
}

const FIELD_NAMES: { [key in keyof IReferralForm]: key } = {
  email: 'email',
};

const schema: yup.ObjectSchema<IReferralForm> = yup.object({
  email: yup
    .string()
    .required(setTextPath('errors.required'))
    .email(setTextPath('signUp.emailError')),
});

const ReferralsForm: React.FC<PropsType> = ({ onSubmit }) => {
  const { t } = useText();
  const formMethods = useForm<IReferralForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = async (data: IReferralForm) => {
    await onSubmit(data);
    formMethods.reset({ email: '' });
  };

  return (
    <Form onSubmit={handleSubmit} formMethods={formMethods} errorText={t('referrals.inviteError')}>
      <FormInputField name={FIELD_NAMES.email} label={t('referrals.email')} />
      <LoadingButton
        type="submit"
        className="w-full"
        isLoading={formMethods.formState.isSubmitting}
      >
        {t('referrals.invite')}
      </LoadingButton>
    </Form>
  );
};

export default React.memo(ReferralsForm);
