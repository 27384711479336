import React from 'react';

import { IPView, TextView } from '@components/Common';
import { CheckboxField } from '@components/Form';
import routes from '@config/routes';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IApiKeyWithSecret } from '@interfaces/api-key';
import { Alert, Button } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  apiKey: IApiKeyWithSecret;
};

const ApiKeySecretInfo: React.FC<PropsType> = ({ apiKey }) => {
  const { t } = useText();
  const navigate = useNavigate();

  const handleReady = () => navigate(routes.API_KEYS);

  return (
    <div>
      <div className="flex gap-2 md:gap-6 flex-wrap mb-7">
        <CheckboxField
          label={t('api.readRights')}
          className="whitespace-nowrap !w-auto !mb-0"
          checked
          disabled
        />
        <CheckboxField
          label={t('api.spot')}
          className="!w-auto !mb-0"
          checked={apiKey.isSpot}
          disabled
        />
        <CheckboxField
          label={t('api.futures')}
          className="!w-auto !mb-0"
          checked={apiKey.isFutures}
          disabled
        />
      </div>
      {!!apiKey.ips?.length && <IPView ips={apiKey.ips} className="mb-7" />}
      <TextView text={apiKey.apiKey} label={t('api.apiKey')} copyTitle={t('api.copy')} />
      <TextView text={apiKey.secretKey} label={t('api.secretKey')} copyTitle={t('api.copy')} />
      <Alert
        variant="outlined"
        color="gray"
        icon={<InformationCircleIcon className="text-amber-500 w-[24px]" />}
        className="mb-7"
      >
        {t('api.secretAlert')}
      </Alert>
      <Button onClick={handleReady} fullWidth>
        {t('common.ready')}
      </Button>
    </div>
  );
};

export default React.memo(ApiKeySecretInfo);
