import React from 'react';

import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

import { roundAmount } from '../../utils/amount';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  pnl: string;
  pnlPercentage?: string;
  asset?: string;
  className?: string;
};

const Pnl: React.FC<PropsType> = ({ pnl, pnlPercentage, asset = 'USDT', className }) => {
  const isNegative = pnl.startsWith('-');
  const color = isNegative ? 'red' : 'green';
  const prefix = isNegative ? '' : '+';

  return (
    <Typography
      variant="small"
      className={cn('font-semibold whitespace-nowrap', className)}
      color={color}
    >
      {prefix}
      {renderAmount(roundAmount(pnl, 5), asset)}
      {pnlPercentage ? ` (${pnlPercentage}%)` : ''}
    </Typography>
  );
};

export default React.memo(Pnl);
