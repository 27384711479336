import React from 'react';

import { IHistory } from '@interfaces/history';
import { Typography } from '@material-tailwind/react';
import { format } from 'date-fns';

import { renderAmount, renderHistoryStatus, renderHistoryTitle } from '../../utils/renderers';

type PropsType = {
  history: IHistory;
};

const HistoryRecord: React.FC<PropsType> = ({ history }) => {
  if (!history.amount || !history.asset) {
    return null;
  }

  return (
    <div className="flex justify-between">
      <div>
        <Typography className="font-semibold whitespace-nowrap">
          {renderHistoryTitle(history.type)}
        </Typography>
        {renderHistoryStatus(history.status)}
        <Typography className="font-semibold text-sm whitespace-nowrap">
          {format(new Date(history.createdAt), 'MM/dd/yyyy H:mm')}
        </Typography>
      </div>
      <div className="flex items-center">
        <Typography className="font-semibold text-sm whitespace-nowrap">
          {renderAmount(history.amount, history.asset)}
        </Typography>
      </div>
    </div>
  );
};

export default React.memo(HistoryRecord);
