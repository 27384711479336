import React from 'react';

import pdfPng from '@assets/pdf.png';

type PropsType = {
  url: string;
  title: string;
};

const FileBox: React.FC<PropsType> = ({ url, title }) => {
  return (
    <a href={url} target="_blank" rel="noreferrer" className="text-center">
      <img src={pdfPng} alt="pdf" className="w-[76px]" />
      <span className="text-sm font-medium text-white">{title}</span>
    </a>
  );
};

export default React.memo(FileBox);
