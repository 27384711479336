import React from 'react';

import routes from '@config/routes';
import { Button, Typography } from '@material-tailwind/react';
import { NavLink } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <div className="grow flex flex-col items-center justify-center max-w-[100vw] min-h-[100vh] px-4 md:px-0">
      <Typography className="text-white text-center" variant="h2" color="white">
        Sorry, page not found!
      </Typography>
      <Typography
        className="text-white max-w-2xl text-center mt-4"
        variant="paragraph"
        color="gray"
      >
        Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
        sure to check your spelling.
      </Typography>
      <NavLink to={routes.HOME}>
        <Button className="mt-6">Go To Home</Button>
      </NavLink>
    </div>
  );
};

export default React.memo(NotFoundPage);
