import React from 'react';

import { InfoBlock, OperationIcon } from '@components/Common';
import HiddenString from '@components/Common/HiddenString';
import Modal from '@components/Modals/Modal';
import { useText } from '@hooks/useText';
import { IHistory, IHistoryDustTransferMetaData } from '@interfaces/history';
import { Button, Chip, Typography } from '@material-tailwind/react';
import { format } from 'date-fns';

import {
  HISTORY_TYPE_LABELS,
  OPERATIONS_TYPES,
  WALLET_TYPE,
  WALLET_TYPE_LABELS,
} from '../../constants';
import { roundAmount } from '../../utils/amount';
import { renderAmount, renderHistoryStatus } from '../../utils/renderers';

type PropsType = {
  record: IHistory | null;
  onClose: () => void;
};

const HistoryDetailsModal: React.FC<PropsType> = ({ record, onClose }) => {
  const { t } = useText();

  const renderTitle = () => {
    if (!record) {
      return null;
    }

    return (
      <span className="inline-flex items-center gap-4 w-full">
        {t(HISTORY_TYPE_LABELS[record.type as never])}
        <OperationIcon type={record.type} className="w-5" />
      </span>
    );
  };

  const renderOperationDetails = (record: IHistory) => {
    switch (record.type) {
      case OPERATIONS_TYPES.TRANSFER:
        return (
          <div>
            <div className="flex gap-10 mb-2 md:mb-4">
              <InfoBlock
                label={t('wallet.from')}
                text={t(WALLET_TYPE_LABELS[record.from as WALLET_TYPE] as never)}
              />
              <InfoBlock
                label={t('wallet.to')}
                text={t(WALLET_TYPE_LABELS[record.to as WALLET_TYPE] as never)}
              />
            </div>
            {record.txnId && <HiddenString label={t('history.txId')} text={record.txnId} />}
          </div>
        );
      case OPERATIONS_TYPES.WITHDRAW:
        return (
          <div>
            {record.to && (
              <div className="mb-2 md:mb-4">
                <HiddenString label={t('wallet.address')} text={record.to} />
              </div>
            )}
            {record.network && (
              <InfoBlock
                label={t('wallet.network')}
                text={record.network}
                className="mb-2 md:mb-4"
              />
            )}
            {record.txnId && <HiddenString label={t('history.txId')} text={record.txnId} />}
          </div>
        );
      case OPERATIONS_TYPES.DEPOSIT:
        return (
          <div>
            {record.from && (
              <div className="mb-2 md:mb-4">
                <HiddenString label={t('history.from')} text={record.from} />
              </div>
            )}
            {record.to && (
              <div className="mb-2 md:mb-4">
                <HiddenString label={t('history.to')} text={record.to} />
              </div>
            )}
            {record.network && (
              <InfoBlock
                label={t('wallet.network')}
                text={record.network}
                className="mb-2 md:mb-4"
              />
            )}
            {record.txnId && <HiddenString label={t('history.txId')} text={record.txnId} />}
          </div>
        );
      case OPERATIONS_TYPES.DUST_TRANSFER:
        // eslint-disable-next-line no-case-declarations
        const metadata = record.metadata as IHistoryDustTransferMetaData;

        return (
          <div>
            {metadata.transferredAmount && record.to && (
              <InfoBlock
                label={t('history.amount')}
                text={renderAmount(roundAmount(metadata.transferredAmount, 8), record.to)}
                className="mb-2 md:mb-4"
              />
            )}
            {metadata.fee && record.to && (
              <InfoBlock
                label={t('wallet.fee')}
                text={renderAmount(roundAmount(metadata.fee, 8), record.to)}
                className="mb-2 md:mb-4"
              />
            )}
            {record.txnId && <HiddenString label={t('history.txId')} text={record.txnId} />}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      title={renderTitle()}
      isOpen={!!record}
      onClose={onClose}
      footer={
        <Button fullWidth onClick={onClose}>
          {t('common.close')}
        </Button>
      }
    >
      {record && (
        <>
          {record.amount && (
            <Typography variant="h3" className="mb-3">
              {record.asset && renderAmount(roundAmount(record.amount, 5), record.asset)}
              {!record.asset && roundAmount(record.amount)}
            </Typography>
          )}
          <div className="flex gap-3 mb-2 md:mb-4">
            <Chip
              value={renderHistoryStatus(record.status)}
              className="normal-case inline leading-5"
            />
            <Chip
              value={format(new Date(record.createdAt), 'MM/dd/yyyy H:mm')}
              className="normal-case inline leading-5"
            />
          </div>
          {renderOperationDetails(record)}
        </>
      )}
    </Modal>
  );
};

export default React.memo(HistoryDetailsModal);
