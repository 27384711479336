import { usersApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

export const useSendEmailConfirmationLink = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: () => usersApi.sendEmailVerificationLink(),
    onError: () => notify(t('errors.common')),
    onSuccess: () => notify(t('emailConfirmation.resendSuccess'), 'success'),
  });
};
