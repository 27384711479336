import React from 'react';

import { useText } from '@hooks/useText';
import { IReferralsHistory } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';
import { format } from 'date-fns';

import { CASHBACK_TYPE_LABELS } from '../../constants';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  referralsHistory: IReferralsHistory;
};

const ReferralsHistoryRecord: React.FC<PropsType> = ({ referralsHistory }) => {
  const { t } = useText();

  return (
    <div>
      <Typography variant="small" className="font-semibold whitespace-nowrap">
        {referralsHistory.email}
      </Typography>
      <Typography variant="small" className="font-semibold whitespace-nowrap mt-3">
        {t(CASHBACK_TYPE_LABELS[referralsHistory.type] as never)}
      </Typography>
      <Typography variant="small" className="font-semibold text-blue-300 text-xs">
        {t('cashback.period')} {format(new Date(referralsHistory.createdAt), 'MM/dd/yyyy')}
      </Typography>
      <Typography variant="small" className="font-semibold whitespace-nowrap mb-3">
        {t('cashback.volume')}:{' '}
        {renderAmount(referralsHistory.totalVolume, referralsHistory.marginAsset)}
      </Typography>
      <Typography variant="small" className="font-semibold whitespace-nowrap" color="green">
        +{renderAmount(referralsHistory.amount, referralsHistory.asset)}
      </Typography>
    </div>
  );
};

export default React.memo(ReferralsHistoryRecord);
