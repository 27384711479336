import React from 'react';

type PropsType = {
  hidden?: boolean;
  size?: 'middle' | 'long';
};

const HiddenContent: React.FC<React.PropsWithChildren<PropsType>> = ({
  hidden,
  children,
  size = 'middle',
}) => {
  if (hidden) {
    return <span>******{size === 'long' && '************'}</span>;
  }

  return <>{children}</>;
};

export default React.memo(HiddenContent);
