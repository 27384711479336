import { IApiEmptyResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IPosition } from '@interfaces/position';

export class PositionsApi extends BaseApi {
  public get() {
    return this.client.get<IPosition[]>(endpoints.FUTURES.POSITIONS);
  }

  public closeMany(symbols: string[]) {
    return this.client.delete<{ symbols: string[] }, IApiEmptyResponse>(
      endpoints.FUTURES.POSITIONS,
      {
        symbols,
      },
    );
  }
}
