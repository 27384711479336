import { IApiPaginationQuery, IApiPaginationResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import {
  IGetReferralOverviewResponse,
  IGetReferralUrlResponse,
  IReferralCodePayload,
} from '@api/referral/referral-api.interfaces';
import { IReferralForm } from '@components/Referral/ReferralsForm';
import endpoints from '@config/endpoints';
import { IReferral, IReferralCode, IReferralsHistory } from '@interfaces/referrals';

interface IGetReferralsQuery extends IApiPaginationQuery {
  term?: string;
}

export class ReferralApi extends BaseApi {
  public getUrl() {
    return this.client.get<IGetReferralUrlResponse>(endpoints.REFERRALS.URL);
  }

  public getReferrals(query?: IGetReferralsQuery) {
    return this.client.get<IApiPaginationResponse<IReferral>>(endpoints.REFERRALS.REFERRALS, query);
  }

  public getReferralsHistory(query?: IApiPaginationQuery) {
    return this.client.get<IApiPaginationResponse<IReferralsHistory>>(
      endpoints.REFERRALS.HISTORY,
      query,
    );
  }

  public getTotal(): Promise<IGetReferralOverviewResponse> {
    return this.client.get<IGetReferralOverviewResponse>(endpoints.REFERRALS.OVERVIEW);
  }

  public inviteReferral(data: IReferralForm) {
    return this.client.post<IReferralForm, null>(endpoints.REFERRALS.INVITE_REFERRAL, data);
  }

  public createCode(data: IReferralCodePayload) {
    return this.client.post(endpoints.REFERRALS.CODES, data);
  }

  public getCodes(query?: IApiPaginationQuery) {
    return this.client.get<IApiPaginationResponse<IReferralCode>>(endpoints.REFERRALS.CODES, query);
  }

  public markPrimary(id: number) {
    return this.client.patch(`${endpoints.REFERRALS.CODES}/${id}/primary`);
  }

  public deleteLink(id: number) {
    return this.client.delete(`${endpoints.REFERRALS.CODES}/${id}`);
  }
}
