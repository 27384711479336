import { verificationApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { useQuery } from '@hooks/useQuery';
import { VERIFICATION_STATUS } from '@interfaces/user';

export const useVerificationRequestQuery = () => {
  const { refreshUser } = useAuth();

  return useQuery(['verification-request'], () => verificationApi.request(), {
    onSuccess: async (data) => {
      if (data.status === VERIFICATION_STATUS.VERIFIED) {
        await refreshUser();
      }
    },
  });
};
