import React, { useEffect, useState } from 'react';

import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IconButton, Input } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  className?: string;
  value?: number;
  max?: number;
  onChange?: (value: number) => void;
};

const LeverageInput: React.FC<PropsType> = ({ className, value = 1, onChange, max = 100 }) => {
  const { t } = useText();
  const [text, setText] = useState<string>(value.toString());

  useEffect(() => {
    if (value.toString() !== text) {
      setText(value.toString());
    }
  }, [value]);

  useEffect(() => {
    if (onChange) {
      const number = parseFloat(text);

      if (number) {
        onChange(number);
      }
    }
  }, [text]);

  const handleMinus = () => {
    const newValue = Math.max(1, value - 1).toString();

    setText(newValue);
  };

  const handlePlus = () => {
    const newValue = Math.min(max, value + 1).toString();

    setText(newValue);
  };

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const number = parseFloat(e.target.value);

    if (number) {
      const newValue = Math.max(1, Math.min(max, number)).toString();

      setText(newValue);
    } else {
      setText(e.target.value);
    }
  };

  return (
    <div className={cn('flex gap-4', className)}>
      <IconButton
        color="blue"
        variant="gradient"
        className="min-w-[35px] w-[35px] sm:w-[60px] sm:min-w-[60px]"
        onClick={handleMinus}
      >
        <MinusIcon className="h-[20px]" />
      </IconButton>
      <div>
        <Input
          label={t('leverage.leverage')}
          crossOrigin
          containerProps={{
            className: '!min-w-[100px]',
          }}
          value={text}
          onChange={handleChange}
        />
      </div>
      <IconButton
        color="blue"
        variant="gradient"
        className="min-w-[35px] w-[35px] sm:w-[60px] sm:min-w-[60px]"
        onClick={handlePlus}
      >
        <PlusIcon className="h-[20px]" />
      </IconButton>
    </div>
  );
};

export default React.memo(LeverageInput);
