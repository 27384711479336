import { exchangeApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export const useToggleDustTransferMutation = () => {
  const { t } = useText();
  const { notify } = useNotification();

  return useMutation({
    mutationFn: (enabled: boolean) => exchangeApi.toggleDustTransfer(enabled),
    onSuccess: async (_, enabled) => {
      await queryClient.invalidateQueries({ queryKey: QUERIES.EXCHANGE_ACCOUNT });
      const message = enabled
        ? t('settings.convertEnableSuccess')
        : t('settings.convertDisableSuccess');

      notify(message, 'success');
    },
    onError: (_, enabled) => {
      const message = enabled
        ? t('settings.convertEnableFailed')
        : t('settings.convertDisableFailed');

      notify(message, 'error');
    },
  });
};
