import React from 'react';

import cn from 'classnames';

type PropsType = {
  url: string;
  name: string;
  className?: string;
  size?: 'small' | 'medium';
};

const CoinIcon: React.FC<PropsType> = ({ url, name, className, size = 'small' }) => {
  return (
    <img
      src={url}
      alt={name}
      className={cn(size === 'small' && 'w-[18px]', size === 'medium' && 'w-[25px]', className)}
    />
  );
};

export default React.memo(CoinIcon);
