import React, { useMemo } from 'react';

import { ClassNameType } from '@common/types/class-name.type';
import { HiddenContent } from '@components/Common';
import routes from '@config/routes';
import {
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  ChevronDownIcon,
  CheckCircleIcon,
  UserCircleIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { useHideMode } from '@hooks/useHideMode';
import { setTextPath, useText } from '@hooks/useText';
import { IUser } from '@interfaces/user';
import {
  Button,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { renderUserVerification } from '../../utils/renderers';

type PropsType = ClassNameType & {
  user: IUser;
  onLogout: () => void;
};

interface IMenuItem {
  label: string;
  icon: React.ForwardRefExoticComponent<any>;
  onClick?: () => void;
}

const UserMenu: React.FC<PropsType> = ({ className, user, onLogout }) => {
  const { t } = useText();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { compileText } = useText();
  const isMd = useMd();
  const navigate = useNavigate();
  const { enabled: isHideModeEnabled } = useHideMode();

  const profileMenuItems: IMenuItem[] = useMemo(() => {
    return [
      {
        label: setTextPath('navbar.settings'),
        icon: Cog6ToothIcon,
        onClick: () => navigate(routes.SETTINGS),
      },
      {
        label: setTextPath('verification.index'),
        icon: CheckCircleIcon,
        onClick: () => navigate(routes.VERIFICATION),
      },
      {
        label: setTextPath('navbar.referrals'),
        icon: UserGroupIcon,
        onClick: () => navigate(routes.REFERRALS),
      },
      {
        label: setTextPath('navbar.signout'),
        icon: ArrowLeftOnRectangleIcon,
        onClick: onLogout,
      },
    ];
  }, [onLogout]);

  const closeMenu = () => setIsMenuOpen(false);

  const handleClick = (handler?: () => void) => () => {
    closeMenu();

    if (handler) {
      handler();
    }
  };

  return (
    <div className={cn('flex', className)}>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        placement="bottom-end"
        dismiss={{ itemPress: false }}
      >
        <MenuHandler>
          {isMd ? (
            <Button
              variant="text"
              color="gray"
              size={isMd ? 'md' : 'sm'}
              className="flex items-center gap-1 py-0.5 px-1 xl:px-4 lg:ml-auto"
            >
              <div className="xl:mr-4 py-2 normal-case text-left">
                <Typography variant="paragraph" className="!text-[#fff] font-medium">
                  {t('verification.status')}
                </Typography>
                <Typography variant="small" className="font-medium">
                  {compileText(renderUserVerification(user.verification))}
                </Typography>
              </div>
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3 w-3 text-gray-400 transition-transform ${
                  isMenuOpen ? 'rotate-180' : ''
                }`}
              />
            </Button>
          ) : (
            <IconButton variant="text">
              <UserCircleIcon className="text-white w-7 h-7" />
            </IconButton>
          )}
        </MenuHandler>
        <MenuList className="p-1 bg-gray-900 border-gray-900">
          <div className="border-b border-gray-600 px-4 py-[9px] flex gap-3 items-center outline-none">
            <div>
              <UserCircleIcon className="h-8 w-8 text-white" />
            </div>
            <div>
              <Typography variant="small" className="font-semibold">
                <HiddenContent hidden={isHideModeEnabled} size="long">
                  {user.email}
                </HiddenContent>
              </Typography>
            </div>
          </div>
          {!isMd && (
            <div className="border-b border-gray-600 px-4 py-[9px] outline-none">
              <Typography variant="paragraph" className="!text-[#fff] font-medium">
                {t('verification.status')}
              </Typography>
              <Typography variant="small" className="font-medium">
                {compileText(renderUserVerification(user.verification))}
              </Typography>
            </div>
          )}
          {profileMenuItems.map(({ label, icon, onClick }, key) => {
            const isLastItem = key === profileMenuItems.length - 1;

            return (
              <MenuItem
                key={label}
                onClick={handleClick(onClick)}
                className={`flex items-center gap-2 !bg-transparent rounded hover:from-blue-500 hover:to-blue-400 hover:bg-gradient-to-tr`}
              >
                {React.createElement(icon, {
                  className: `h-5 text-white w-5 ${isLastItem ? '!text-amber-500' : ''}`,
                  strokeWidth: 2,
                })}
                <Typography
                  as="span"
                  variant="paragraph"
                  className="font-semibold"
                  color={isLastItem ? 'amber' : 'white'}
                >
                  {compileText(label)}
                </Typography>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </div>
  );
};

export default React.memo(UserMenu);
