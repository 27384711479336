import React from 'react';

import routes from '@config/routes';
import { useAuth } from '@hooks/useAuth';
import { VERIFICATION_RESULTS } from '@interfaces/user';
import { Navigate } from 'react-router-dom';

export default function withVerificationGuard<P extends {}>(Component: React.ComponentType) {
  return (props: P) => {
    const { user } = useAuth();

    if (user?.verification?.result !== VERIFICATION_RESULTS.APPROVED) {
      return <Navigate to={routes.VERIFICATION} />;
    }

    return <Component {...props} />;
  };
}
