import EmailConfirmationGuard from '@components/Guards/EmailConfirmationGuard';
import { MainLayout } from '@components/Layout';
import routes from '@config/routes';
import APIKeysCreatePage from '@pages/APIKeysCreatePage';
import APIKeysPage from '@pages/APIKeysPage';
import APIKeysUpdatePage from '@pages/APIKeysUpdatePage';
import EmailVerificationPage from '@pages/EmailVerificationPage';
import HistoryPage from '@pages/HistoryPage';
import LeveragePage from '@pages/LeveragePage';
import NotFoundPage from '@pages/NotFoundPage';
import OperationsPage from '@pages/OperationsPage';
import ReferralLinksPage from '@pages/ReferralLinksPage';
import ResetPasswordPage from '@pages/ResetPasswordPage';
import ResetPasswordRequestPage from '@pages/ResetPasswordRequestPage';
import SavedCommissionsPage from '@pages/SavedCommissionsPage';
import SettingsPage from '@pages/SettingsPage';
import SignInPage from '@pages/SignInPage';
import SignUpPage from '@pages/SignUpPage';
import StatisticFuturesUSDM from '@pages/StatisticPage';
import TermsPage from '@pages/TermsPage';
import VerificationPage from '@pages/VerificationPage';
import WalletPage from '@pages/WalletPage';
import ReferralsPage from 'pages/ReferralsPage';
import { createBrowserRouter, Navigate } from 'react-router-dom';

export const router = createBrowserRouter([
  {
    path: routes.SIGN_UP,
    element: <SignUpPage />,
  },
  {
    path: routes.LOGIN,
    element: <SignInPage />,
  },
  {
    path: routes.VERIFY_EMAIL,
    element: <EmailVerificationPage />,
  },
  {
    path: routes.RESET_PASSWORD_REQUEST,
    element: <ResetPasswordRequestPage />,
  },
  {
    path: routes.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },
  {
    path: routes.HOME,
    element: (
      <EmailConfirmationGuard>
        <MainLayout />
      </EmailConfirmationGuard>
    ),
    children: [
      {
        path: routes.HOME,
        element: <Navigate to={routes.WALLET} />,
      },
      {
        path: routes.WALLET,
        element: <WalletPage />,
      },
      {
        path: routes.SAVED_COMMISSIONS,
        element: <SavedCommissionsPage />,
      },
      {
        path: routes.LEVERAGE,
        element: <LeveragePage />,
      },
      {
        path: routes.API_KEYS,
        element: <APIKeysPage />,
      },
      {
        path: routes.API_KEYS_CREATE,
        element: <APIKeysCreatePage />,
      },
      {
        path: routes.API_KEYS_UPDATE,
        element: <APIKeysUpdatePage />,
      },
      {
        path: routes.HISTORY,
        element: <HistoryPage />,
      },
      {
        path: routes.TERMS,
        element: <TermsPage />,
      },
      {
        path: routes.VERIFICATION,
        element: <VerificationPage />,
      },
      {
        path: routes.REFERRALS,
        element: <ReferralsPage />,
      },
      {
        path: routes.REFERRALS_LINKS,
        element: <ReferralLinksPage />,
      },
      {
        path: routes.STATISTIC,
        element: <StatisticFuturesUSDM />,
      },
      {
        path: routes.OPERATIONS + '/*',
        element: <OperationsPage />,
      },
      {
        path: routes.SETTINGS,
        element: <SettingsPage />,
      },
    ],
    errorElement: <NotFoundPage />,
  },
]);
