import React, {useCallback, useEffect} from 'react';

import { LanguagePicker } from '@components/Common';
import { BurgerIcon, CloseIcon } from '@components/Icons';
import { LAYOUT_WIDTH_CLASS_NAMES } from '@components/Layout/classes';
import Logo from '@components/Layout/Logo';
import Navigation from '@components/Layout/Navigation';
import UserMenu from '@components/Layout/UserMenu';
import { useChangeLanguageMutation } from '@hooks/mutations/useChangeLanguageMutation';
import { useAuth } from '@hooks/useAuth';
import {useQuoteAdvice} from "@hooks/useQuoteAdvice";
import { IconButton, MobileNav, Navbar } from '@material-tailwind/react';
import cn from 'classnames';

const Header: React.FC = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const { user, logout } = useAuth();

  const toggleNav = useCallback(() => setOpenNav((prev) => !prev), []);

  const { mutate: changeLanguage, isLoading: isLanguageChanging } = useChangeLanguageMutation();

  const { showQuoteAdvice } = useQuoteAdvice();

  // При первом рендере, показываем цитату-наставление
  useEffect(() => {
    if (user)
      showQuoteAdvice();
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <header>
      <Navbar
        variant="filled"
        color="transparent"
        className="sticky top-0 z-10 h-max max-w-full rounded-none p-0 border-b border-border-nav"
      >
        <div
          className={cn(
            'flex items-center justify-between text-blue-gray-900 py-2 lg:py-2',
            LAYOUT_WIDTH_CLASS_NAMES,
          )}
        >
          <div className="flex items-center gap-5 xl:gap-10">
            <Logo />
            <div className="mr-4 hidden lg:flex justify-between">
              <Navigation />
            </div>
          </div>

          <div className="flex gap-2 lg:gap-0 items-center">
            <LanguagePicker
              className="ml-2 lg:mr-3 lg:!ml-0"
              value={user.lng}
              onChange={changeLanguage}
              isLoading={isLanguageChanging}
            />
            <UserMenu user={user} onLogout={logout} />
            <div className="flex items-center gap-10">
              <IconButton
                variant="text"
                className="ml-auto h-6 w-6 text-white text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                ripple={false}
                onClick={toggleNav}
              >
                {openNav ? <CloseIcon /> : <BurgerIcon />}
              </IconButton>
            </div>
          </div>
        </div>
        <MobileNav open={openNav}>
          <div className="py-2 px-4">
            <Navigation />
          </div>
        </MobileNav>
      </Navbar>
    </header>
  );
};

export default React.memo(Header);
