import { useState } from 'react';

export const useConfirm = <A>(callback: (args: A) => void) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<A>();

  const confirm = (args: A) => {
    setIsOpen(true);
    setData(args);
  };

  const onConfirm = () => {
    callback(data as A);
    setIsOpen(false);
    setData(undefined);
  };

  const onCancel = () => {
    setIsOpen(false);
    setData(undefined);
  };

  return {
    isOpen,
    data,
    confirm,
    onConfirm,
    onCancel,
  };
};
