import { setTextPath } from '@hooks/useText';
import { IOption } from '@interfaces/option';

export const customPeriod: IOption<number> = {
  label: setTextPath('common.periodCustom'),
  value: 0,
};

export const periods: IOption<number>[] = [
  {
    label: setTextPath('common.period7'),
    value: 7,
  },
  {
    label: setTextPath('common.period30'),
    value: 30,
  },
  {
    label: setTextPath('common.period90'),
    value: 90,
  },
  customPeriod,
];
