import { apiKeysApi } from '@api/index';
import { I2FAOtp } from '@api/two-factor-auth/two-factor-auth.interfaces';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IApiKey } from '@interfaces/api-key';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export interface IUseDeleteApiKeyPayload {
  otps: I2FAOtp[];
  apiKey: IApiKey;
}

export const useDeleteApiKeyMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (payload: IUseDeleteApiKeyPayload) =>
      apiKeysApi.delete(payload.apiKey.id, payload.otps),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERIES.API_KEYS });
      notify(t('api.deleteSuccess'), 'success');
    },
    onError: () => {
      notify(t('api.deleteFailed'), 'error');
    },
  });
};
