import React from 'react';

import { IconButton, IconButtonProps, Spinner, Tooltip } from '@material-tailwind/react';

type PropsType = IconButtonProps & {
  isLoading?: boolean;
  tooltip?: string;
};

const LoadingIconButton: React.FC<PropsType> = ({
  isLoading,
  children,
  disabled,
  tooltip,
  color,
  ...props
}) => {
  const content = (
    <IconButton {...(props as any)} color={color} disabled={isLoading || disabled}>
      {!isLoading && children}
      {isLoading && <Spinner color={color as any} />}
    </IconButton>
  );

  if (tooltip) {
    return <Tooltip content={tooltip}>{content}</Tooltip>;
  }

  return content;
};

export default React.memo(LoadingIconButton);
