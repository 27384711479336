import React, { useState } from 'react';

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import { IconButton, Input, InputProps } from '@material-tailwind/react';

const PasswordInput: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  props,
  ref,
) => {
  const [isPasswordShow, setIsPasswordShow] = useState(false);

  const togglePasswordVisibility = () => setIsPasswordShow((prev) => !prev);

  return (
    <Input
      crossOrigin
      {...(props as any)}
      inputRef={ref}
      type={isPasswordShow ? 'text' : 'password'}
      icon={
        <IconButton
          onClick={togglePasswordVisibility}
          variant="text"
          size="sm"
          className="h-[25px]"
        >
          {isPasswordShow && <EyeIcon className="text-white h-[15px]" />}
          {!isPasswordShow && <EyeSlashIcon className="text-white h-[15px]" />}
        </IconButton>
      }
    />
  );
};

export default React.memo(React.forwardRef(PasswordInput));
