import { pairsApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

import { MARGIN_TYPE } from '../../constants';

interface IParams {
  pairs: IFuturesPair[];
  marginType: MARGIN_TYPE;
}

const invalidateFuturesPairQuery = (variables: IParams) => {
  queryClient.setQueriesData<IFuturesPair[]>(QUERIES.FUTURES_PAIRS, (input) => {
    return (
      input?.map((pair) => {
        const isUpdated = variables.pairs.some((p) => p.symbol === pair.symbol);

        if (isUpdated) {
          return {
            ...pair,
            marginType: variables.marginType,
          };
        }

        return pair;
      }) || []
    );
  });
};

const mutationFn = ({ pairs, marginType }: IParams) => {
  const symbols = pairs.filter((p) => p.marginType !== marginType).map((p) => p.symbol);

  return pairsApi.updateMarginType(symbols, marginType);
};

export const useUpdateMarginTypeMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn,
    onMutate: (variables) => {
      invalidateFuturesPairQuery(variables);
    },
    onSuccess: () => {
      notify(t('leverage.marginTypeUpdateSuccess'), 'success');
    },
    onError: () => {
      notify(t('leverage.marginTypeUpdateFailed'), 'error');
    },
  });
};
