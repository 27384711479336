import { setTextPath } from '@hooks/useText';
import { IOption } from '@interfaces/option';

import { MARGIN_TYPE } from '../constants';

export const marginTypes: IOption<MARGIN_TYPE>[] = [
  {
    label: setTextPath('leverage.crossed'),
    value: MARGIN_TYPE.CROSSED,
  },
  {
    label: setTextPath('leverage.isolated'),
    value: MARGIN_TYPE.ISOLATED,
  },
];
