import React, { useMemo } from 'react';

import { CopyButton, LoadingIconButton } from '@components/Common';
import Section from '@components/Common/Section';
import { ReferralsRecord } from '@components/Referral/index';
import { Table } from '@components/Table';
import { BookmarkSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useLg } from '@hooks/responsive/useLg';
import { useMd } from '@hooks/responsive/useMd';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { IReferralCode } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

type PropsType = {
  data: IReferralCode[];
  isLoading?: boolean;
  pagination: IPaginationData;
  markPrimary: (id: number) => void;
  deleteLink: (id: number) => void;
  deletingId?: number;
  markPrimaryId?: number;
};

const columnHelper = createColumnHelper<IReferralCode>();

const ReferralsCodes: React.FC<PropsType> = ({
  data,
  markPrimary,
  deleteLink,
  deletingId,
  markPrimaryId,
  pagination,
  isLoading,
}) => {
  const { t } = useText();
  const isMd = useMd();
  const isLg = useLg();

  const columns = useMemo(() => {
    if (!isMd) {
      return [
        columnHelper.display({
          id: 'referrals',
          header: () => t('referrals.code'),
          cell: (info) => (
            <ReferralsRecord
              data={info.row.original}
              markPrimaryId={markPrimaryId}
              markPrimary={markPrimary}
              deleteLink={deleteLink}
              deletingId={deletingId}
            />
          ),
        }),
      ];
    }

    return [
      columnHelper.accessor('code', {
        header: () => <div className="pl-7">{t('referrals.code')}</div>,
        cell: (info) => {
          const primary = info.row.original.isPrimary;
          const isDeleted = info.row.original.isDeleted;

          return (
            <div className="flex gap-2 items-center">
              {primary && <BookmarkSquareIcon className="h-[20px] text-blue-500" />}
              {isDeleted && <TrashIcon className="h-[20px] text-amber-500" />}
              <Typography
                variant="paragraph"
                className={`font-semibold break-all text-sm ${!primary && !isDeleted && 'pl-7'}`}
              >
                {info.getValue()}
              </Typography>
            </div>
          );
        },
        size: isLg ? undefined : 120,
      }),
      columnHelper.accessor('referralsCount', {
        header: () => t('referrals.invitesCount'),
        cell: (info) => (
          <Typography variant="paragraph" className="font-semibold text-sm">
            {info.getValue()}
          </Typography>
        ),
        size: isLg ? undefined : 100,
      }),
      columnHelper.accessor('activeReferralsCount', {
        header: () => t('referrals.usersCount'),
        cell: (info) => (
          <Typography variant="paragraph" className="font-semibold text-sm">
            {info.getValue()}
          </Typography>
        ),
        size: isLg ? undefined : 80,
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const id = info.row.original.id;
          const isDefault = info.row.original.isDefault;
          const isDeleted = info.row.original.isDeleted;
          const primary = info.row.original.isPrimary;

          if (isDeleted) {
            return null;
          }

          return (
            <div className="flex gap-3 items-center">
              <CopyButton text={info.row.original.url} copyTitle={t('referrals.copyLink')} />
              {!primary && (
                <div>
                  <LoadingIconButton
                    variant="text"
                    color="blue"
                    tooltip={t('referrals.markPrimary')}
                    onClick={() => markPrimary(id)}
                    isLoading={markPrimaryId === id}
                  >
                    <BookmarkSquareIcon className="h-[20px] text-white " />
                  </LoadingIconButton>
                </div>
              )}
              {!isDefault && (
                <div>
                  <LoadingIconButton
                    variant="text"
                    color="blue"
                    tooltip={t('referrals.delete')}
                    onClick={() => deleteLink(id)}
                    isLoading={deletingId === id}
                  >
                    <TrashIcon className="text-white h-[20px]" />
                  </LoadingIconButton>
                </div>
              )}
            </div>
          );
        },
        size: isLg ? 90 : 100,
      }),
    ];
  }, [deletingId, markPrimaryId, isMd, isLg]);

  return (
    <Section>
      <Table
        data={data}
        columns={columns}
        onPageChange={pagination.setPage}
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        itemsCount={pagination.totalCount || 0}
        isLoading={isLoading}
        witPagination
        rowClassName="md:h-[73px]"
      />
    </Section>
  );
};

export default React.memo(ReferralsCodes);
