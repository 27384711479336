import React from 'react';

import InputField, { InputFieldPropsType } from '@components/Form/Fields/InputField/InputField';
import { useControlledField } from '@hooks/useControlledField';

export type OtpFieldPropsType = Omit<InputFieldPropsType, 'onChange' | 'value'> & {
  onChange?: (value: string) => void;
  value?: string;
  maxDigits?: number;
};

const validateValue = (value: string | undefined, maxDigits: number, prevValue = '') => {
  if (!value) {
    return '';
  }

  const pattern = /^\d+$/;
  const isValid = pattern.test(value) && value.length <= maxDigits;

  if (isValid) {
    return value;
  }

  return prevValue;
};

const NumberField: React.ForwardRefRenderFunction<HTMLInputElement, OtpFieldPropsType> = (
  { maxDigits = 100, value, onChange, ...props },
  ref,
) => {
  const [number, setNumber] = useControlledField(validateValue(value, maxDigits), value, onChange);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const value = validateValue(e.target.value, maxDigits, number);

    setNumber(value);
  };

  return <InputField {...props} ref={ref} value={number} onChange={handleChange} />;
};

export default React.memo(React.forwardRef(NumberField));
