import { ISignIn2FAError } from '@api/auth/auth-api.interfaces';
import { ApiError } from '@api/core/api.error';
import { authApi } from '@api/index';
import { ISignInFormData } from '@components/SignInForm/SignInForm';
import { useAuth } from '@hooks/useAuth';
import { IUser } from '@interfaces/user';
import { useMutation } from 'react-query';

export const useSignInMutation = () => {
  const { setUser } = useAuth();

  return useMutation<IUser, ApiError<ISignIn2FAError>, ISignInFormData>({
    mutationFn: (data: ISignInFormData) => authApi.signIn(data),
    onSuccess: setUser,
  });
};
