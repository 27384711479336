import React, { useCallback, useEffect, useState } from 'react';

import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import {
  ReferralsAlert,
  ReferralsBlock,
  ReferralsHistoryBlock,
  ReferralsInfoBlock,
} from '@components/Referral';
import { IReferralForm } from '@components/Referral/ReferralsForm';
import { useReferralInviteMutation } from '@hooks/mutations/useReferralInviteMutation';
import { useReferralsHistoryQuery } from '@hooks/queries/useReferralsHistory';
import { useReferralsQuery } from '@hooks/queries/useReferralsQuery';
import { useReferralsTotalQuery } from '@hooks/queries/useReferralsTotalQuery';
import { useReferralUrlQuery } from '@hooks/queries/useReferralUrlQuery';
import { useText } from '@hooks/useText';
import { debounce } from 'lodash';

const ReferralsPage = () => {
  const [term, setTerm] = useState('');
  const [historyMode, setHistoryMode] = useState(false);
  const {
    query: { data: referrals, isLoading: isReferralsLoading, isFetching: isReferralsFetching },
    pagination: referralsPagination,
  } = useReferralsQuery(term, !historyMode);
  const { data: getReferralUrl, isLoading: isReferralUrlLoading } = useReferralUrlQuery();
  const { data: referralsTotal, isLoading: isReferralsTotalLoading } = useReferralsTotalQuery();
  const {
    query: {
      data: referralsHistory,
      isLoading: isReferralsHistoryLoading,
      isFetching: isReferralsHistoryFetching,
    },
    pagination: referralsHistoryPagination,
  } = useReferralsHistoryQuery(historyMode);
  const { mutateAsync } = useReferralInviteMutation();
  const { t } = useText();

  useEffect(() => {
    referralsPagination.setPage(1);
    referralsHistoryPagination.setPage(1);
  }, [historyMode, term]);

  const enableHistoryMode = () => setHistoryMode(true);

  const disableHistoryMode = () => setHistoryMode(false);

  const handleSubmit = (data: IReferralForm) => {
    return mutateAsync(data);
  };

  const searchDebounced = useCallback(
    debounce((term: string) => {
      setTerm(term);
    }, 500),
    [],
  );

  if (
    isReferralsTotalLoading ||
    isReferralUrlLoading ||
    isReferralsLoading ||
    isReferralsHistoryLoading
  ) {
    return <Preloader />;
  }

  if (
    !getReferralUrl ||
    (!historyMode && !referrals) ||
    !referralsTotal ||
    (historyMode && !referralsHistory)
  ) {
    return null;
  }

  return (
    <PageLayout title={t('referrals.title')}>
      <ReferralsAlert />
      <div className="flex flex-col lg:flex-row gap-6 mt-7">
        <ReferralsInfoBlock
          enableHistoryMode={enableHistoryMode}
          referralsTotal={referralsTotal}
          getReferralUrl={getReferralUrl}
          handleSubmit={handleSubmit}
          showHistoryModeButton={!historyMode && !!referrals?.length}
        />
        <div className="w-full">
          <ReferralsBlock
            referrals={referrals || []}
            getReferralUrl={getReferralUrl}
            referralsTotal={referralsTotal}
            isReferralsFetching={isReferralsFetching}
            handleSubmit={handleSubmit}
            historyMode={historyMode}
            onSearch={searchDebounced}
            pagination={referralsPagination}
          />
          <ReferralsHistoryBlock
            referralsHistory={referralsHistory || []}
            historyMode={historyMode}
            disableHistoryMode={disableHistoryMode}
            isLoading={isReferralsHistoryFetching}
            pagination={referralsHistoryPagination}
          />
        </div>
      </div>
    </PageLayout>
  );
};

export default React.memo(ReferralsPage);
