import React, { useEffect, useState } from 'react';

import InputField from '@components/Form/Fields/InputField/InputField';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useText } from '@hooks/useText';
import { IconButton } from '@material-tailwind/react';
import cn from 'classnames';
import { flowRight, property, toUpper } from 'lodash';

type PropsType = {
  onChange?: (term: string) => void;
  className?: string;
  upperCase?: boolean;
};

const SearchInput: React.FC<PropsType> = ({ onChange, className, upperCase }) => {
  const { t } = useText();
  const [term, setTerm] = useState('');

  useEffect(() => {
    if (onChange) {
      onChange(term);
    }
  }, [term]);

  const handleClear = () => {
    setTerm('');
  };

  const toUpperCase = (text: string) => {
    if (upperCase) {
      return toUpper(text);
    }

    return text;
  };

  const renderIcon = () => {
    if (!term) {
      return <MagnifyingGlassIcon className="text-white h-[18px]" />;
    }

    return (
      <IconButton onClick={handleClear} variant="text" size="sm" className="h-[25px]" color="blue">
        <XMarkIcon className="text-white h-[18px]" />
      </IconButton>
    );
  };

  return (
    <InputField
      value={term}
      onChange={flowRight(setTerm, toUpperCase, property('target.value'))}
      label={t('common.search')}
      className={cn('lg:max-w-[200px] xl:max-w-[300px] lg:mb-0', className)}
      icon={renderIcon()}
    />
  );
};

export default React.memo(SearchInput);
