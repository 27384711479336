import { useCallback } from 'react';

export const useClipboard = (textToCopy?: string) => {
  return useCallback(
    async (text?: string) => {
      try {
        const content = text || textToCopy;

        if (!content) {
          return null;
        }

        await window.navigator.clipboard.writeText(content);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    },
    [textToCopy],
  );
};
