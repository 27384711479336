import React, { useEffect, useMemo, useState } from 'react';

import { CheckboxField, SelectField } from '@components/Form';
import LeverageInput from '@components/Leverage/LeverageSelect';
import PairLeverage from '@components/Leverage/PairLeverage';
import { Table, TableLoadingCell } from '@components/Table';
import { useLg } from '@hooks/responsive/useLg';
import { useMd } from '@hooks/responsive/useMd';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

import { MARGIN_TYPE } from '../../constants';
import { marginTypes } from '../../data/margin-types';
import { getMaxPairVolume } from '../../utils/futures-pairs';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  pairs: IFuturesPair[];
  selectedSymbols?: string[];
  symbolsMarginTypeChanging?: string[];
  symbolsLeverageChanging?: string[];
  onMarginTypeChange: (pairs: IFuturesPair[], type: MARGIN_TYPE) => void;
  onLeverageChange: (symbols: string[], leverage: number) => void;
  onSelect?: (symbol: string) => void;
};

const columnHelper = createColumnHelper<IFuturesPair>();

const ITEMS_PER_PAGE = 10;

const PairsTable: React.FC<PropsType> = ({
  pairs,
  selectedSymbols,
  symbolsMarginTypeChanging = [],
  symbolsLeverageChanging = [],
  onMarginTypeChange,
  onLeverageChange,
  onSelect,
}) => {
  const { t } = useText();
  const isLg = useLg();
  const isMd = useMd();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pairs]);

  const pairsToRender = useMemo(() => {
    const skip = (page - 1) * ITEMS_PER_PAGE;

    return pairs.slice(skip, skip + ITEMS_PER_PAGE);
  }, [page, pairs]);

  const handleSelect = (symbol: string) => () => {
    if (onSelect) {
      onSelect(symbol);
    }
  };

  const columns = useMemo(() => {
    const selectColumn = columnHelper.display({
      id: 'select',
      cell: (info) => {
        const { symbol } = info.row.original;
        const isSelected = info.table.options.meta?.selectedSymbols?.includes(symbol);

        return <CheckboxField value={isSelected} onChange={handleSelect(symbol)} />;
      },
      size: isMd ? 44 : 20,
      meta: {
        className: '!px-0',
      },
    });

    if (!isMd) {
      return [
        selectColumn,
        columnHelper.display({
          id: 'pair',
          header: t('leverage.pair'),
          cell: (info) => (
            <PairLeverage
              pair={info.row.original}
              onLeverageChange={onLeverageChange}
              onMarginTypeChange={onMarginTypeChange}
            />
          ),
        }),
      ];
    }

    const columns: any[] = [
      selectColumn,
      columnHelper.accessor('symbol', {
        header: t('leverage.pair'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {info.getValue()}
          </Typography>
        ),
        size: 120,
      }),
      columnHelper.accessor('leverage', {
        header: t('leverage.leverage'),
        cell: (info) => {
          const { maxLeverage, symbol, leverage } = info.row.original;
          const isLoading = info.table.options.meta?.symbolsLeverageChanging?.includes(symbol);

          return (
            <TableLoadingCell isLoading={isLoading}>
              <LeverageInput
                value={leverage}
                max={maxLeverage}
                onChange={(value) => onLeverageChange([symbol], value)}
              />
            </TableLoadingCell>
          );
        },
        size: 100,
        meta: {
          className: 'max-w-[100px]',
        },
      }),
    ];

    const marginTypeColumn = columnHelper.accessor('marginType', {
      header: t('leverage.margin'),
      cell: (info) => {
        const { symbol } = info.row.original;
        const isLoading = info.table.options.meta?.symbolsMarginTypeChanging?.includes(symbol);
        const value = marginTypes.find((t) => t.value === info.getValue());

        return (
          <TableLoadingCell isLoading={isLoading}>
            <SelectField
              label={t('leverage.margin')}
              className="max-w-[180px]"
              value={value}
              options={marginTypes}
              onChange={(value) => onMarginTypeChange([info.row.original], value.value)}
            />
          </TableLoadingCell>
        );
      },
      meta: {
        className: 'max-w-[150px]',
      },
    });

    if (!isLg) {
      return [...columns, marginTypeColumn];
    }

    return [
      ...columns,
      columnHelper.accessor('maxLeverage', {
        header: t('leverage.maxLeverage'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {info.getValue()}
          </Typography>
        ),
        size: 90,
      }),
      columnHelper.display({
        id: 'maxPosition',
        header: t('leverage.maxPosition'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold">
            {renderAmount(getMaxPairVolume(info.row.original), 'USDT')}
          </Typography>
        ),
        size: 140,
      }),
      marginTypeColumn,
    ];
  }, [isLg, isMd, onSelect]);

  return (
    <Table
      columns={columns}
      data={pairsToRender}
      meta={{
        symbolsMarginTypeChanging,
        symbolsLeverageChanging,
        selectedSymbols,
      }}
      emptyText={t('leverage.empty')}
      itemsPerPage={ITEMS_PER_PAGE}
      page={page}
      itemsCount={pairs.length}
      onPageChange={setPage}
      witPagination
    />
  );
};

export default React.memo(PairsTable);
