import React from 'react';

import { useMd } from '@hooks/responsive/useMd';
import { Button, Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  title?: string;
  className?: string;
  contentClassName?: string;
  showButton?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
};

const PageLayout: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  title,
  className,
  contentClassName,
  showButton,
  buttonText = 'Add',
  onButtonClick,
}) => {
  const isMd = useMd();

  return (
    <div className={cn('grow flex flex-col', className)}>
      {(title || showButton) && (
        <div className="flex flex-col mb-6 md:mb-0 md:flex-row md:justify-between md:items-center">
          {title && (
            <Typography variant={isMd ? 'h1' : 'h3'} className="md:mb-6">
              {title}
            </Typography>
          )}
          {showButton && (
            <Button className="mt-3 md:mt-0" onClick={onButtonClick}>
              {buttonText}
            </Button>
          )}
        </div>
      )}
      <div className={cn('w-full grow flex flex-col', contentClassName)}>{children}</div>
    </div>
  );
};

export default React.memo(PageLayout);
