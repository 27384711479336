import React, { useCallback } from 'react';

import { ApiKeyForm } from '@components/ApiKeys';
import { IApiKeyFormData } from '@components/ApiKeys/ApiKeyForm';
import { Section } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import TwoFactorAuth from '@components/Common/TwoFactorAuth';
import { PageLayout } from '@components/Layout';
import { useUpdateApiKeyMutation } from '@hooks/mutations/useUpdateApiKeyMutation';
import { useApiKey } from '@hooks/queries/useApiKey';
import { OtpCallbackType, use2FA } from '@hooks/use2FA';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';

import { FORM_MODES, TWO_FACTOR_AUTH_ACTIONS } from '../../constants';

const APIKeysUpdatePage = () => {
  const { t } = useText();
  let { mutateAsync, error, isLoading: isUpdating } = useUpdateApiKeyMutation();
  const { id } = useParams();
  const { data: apiKey, isLoading: isApiKeyLoading } = useApiKey(id);

  const handleSubmit = useCallback<OtpCallbackType<IApiKeyFormData>>(
    async (data) => {
      if (!id) {
        return null;
      }

      return mutateAsync({
        id: parseInt(id),
        payload: data,
      });
    },
    [id],
  );

  const { activate: updateApiKey, ...twoFactorAuthState } = use2FA<IApiKeyFormData>({
    callback: handleSubmit,
    mapDataTo2FAPayload: (data) => {
      return {
        action: TWO_FACTOR_AUTH_ACTIONS.API_KEY_UPDATE,
        payload: {
          name: data.title,
        },
      };
    },
  });

  if (isApiKeyLoading) {
    return <Preloader />;
  }

  if (!apiKey) {
    return null;
  }

  return (
    <PageLayout contentClassName="items-center md:pt-20">
      <div className="w-full max-w-xl">
        <Typography variant="h4" className="font-bold mb-5">
          {t('api.updating')}
        </Typography>
        <Section>
          <ApiKeyForm
            apiKey={apiKey}
            mode={FORM_MODES.UPDATE}
            onSubmit={updateApiKey}
            error={error ? t('api.updateFailed') : undefined}
          />
        </Section>
      </div>
      <TwoFactorAuth {...twoFactorAuthState} isVerifying={isUpdating} />
    </PageLayout>
  );
};

export default React.memo(APIKeysUpdatePage);
