import React from 'react';

import { OperationIcon } from '@components/Common';
import routes from '@config/routes';
import { setTextPath, useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { OPERATIONS_TYPES } from '../../constants';

type PropsType = {
  className?: string;
};

interface IButton {
  label: string;
  operation: OPERATIONS_TYPES;
  route: string;
  color: 'blue' | 'gray';
}

const buttons: IButton[] = [
  {
    label: setTextPath('operations.deposit'),
    operation: OPERATIONS_TYPES.DEPOSIT,
    route: routes.OPERATIONS_DEPOSIT,
    color: 'blue',
  },
  {
    label: setTextPath('operations.transfer'),
    operation: OPERATIONS_TYPES.TRANSFER,
    route: routes.OPERATIONS_TRANSFER,
    color: 'gray',
  },
  {
    label: setTextPath('operations.withdrawal'),
    operation: OPERATIONS_TYPES.WITHDRAW,
    route: routes.OPERATIONS_WITHDRAWAL,
    color: 'gray',
  },
];

const OperationsButtons: React.FC<PropsType> = ({ className }) => {
  const { t } = useText();
  const navigate = useNavigate();

  const handleClick = (route: string) => () => {
    navigate(route);
  };

  return (
    <div className={cn('flex gap-2 flex-col w-full md:w-auto md:flex-row', className)}>
      {buttons.map(({ label, operation, route, color }) => (
        <Button
          key={route}
          className="flex items-center justify-center md:justify-start gap-2"
          color={color}
          onClick={handleClick(route)}
        >
          {t(label as never)} <OperationIcon type={operation} className="w-5" />
        </Button>
      ))}
    </div>
  );
};

export default React.memo(OperationsButtons);
