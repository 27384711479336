import { authApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';

export const useLogoutMutation = (onSuccess?: () => void) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: () => authApi.logout(),
    onError: () => notify(t('errors.common')),
    onSuccess,
  });
};
