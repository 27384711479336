import React, { useEffect, useMemo, useState } from 'react';

import { Section, TabPropsType, TextView } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import QrCode from '@components/Common/QRCode';
import { FormRow, SelectField } from '@components/Form';
import { useCoinsQuery } from '@hooks/queries/useCoins';
import { useDepositAddressQuery } from '@hooks/queries/useDepositAddressQuery';
import { useQRCode } from '@hooks/useQRCode';
import { useText } from '@hooks/useText';
import { ICoin } from '@interfaces/coin';
import { INetwork } from '@interfaces/network';
import { property } from 'lodash';

import { COIN_TYPES, HOT_ASSETS } from '../../constants';
import { renderCoin, renderNetwork } from '../../utils/renderers';

import WithdrawalAlert from './WithdrawalAlert';

const DepositTab: React.FC<TabPropsType> = ({ isActive }) => {
  const { t } = useText();
  const [selectedCoin, setSelectedCoin] = useState<ICoin | null>(null);
  const [selectedNetwork, setSelectedNetwork] = useState<INetwork | null>(null);
  const { data: coins, isFetching: isCoinsLoading } = useCoinsQuery(COIN_TYPES.DEPOSIT, isActive);
  const { data: depositAddress, isFetching: isAddressFetching } = useDepositAddressQuery(
    selectedCoin?.coin,
    selectedNetwork?.network,
  );
  const { qrCode, isGenerating } = useQRCode(depositAddress?.address);

  useEffect(() => {
    setSelectedNetwork(null);
  }, [selectedCoin]);

  const renderAddress = () => {
    if (isAddressFetching || isGenerating) {
      return <Preloader className="!py-6" />;
    }

    if (!depositAddress || !selectedCoin || !selectedNetwork || !coins) {
      return null;
    }

    return (
      <div>
        {qrCode && <QrCode src={qrCode} className="mb-7" />}
        <TextView
          text={depositAddress.address}
          label={t('wallet.address')}
          copyTitle={t('deposit.copy')}
        />
        {depositAddress.tag && (
          <TextView
            text={depositAddress.tag}
            label={t('wallet.memo')}
            copyTitle={t('deposit.copyMemo')}
          />
        )}
      </div>
    );
  };

  const hotCoins = useMemo(() => {
    return coins?.filter((c) => Object.values(HOT_ASSETS).includes(c.coin as any)) || [];
  }, [coins]);

  if (isCoinsLoading) {
    return <Preloader />;
  }

  return (
    <Section>
      <FormRow className="mb-7 md:mb-0">
        <SelectField
          hotOptions={hotCoins}
          options={coins}
          label={t('wallet.coin')}
          renderOption={renderCoin}
          getOptionValue={property('coin')}
          value={selectedCoin}
          onChange={setSelectedCoin}
          searchFields={['coin']}
          searchable
        />
        <SelectField
          options={selectedCoin?.networks}
          label={t('wallet.network')}
          getOptionValue={property('name')}
          renderOption={renderNetwork()}
          value={selectedNetwork}
          onChange={setSelectedNetwork}
          disabled={!selectedCoin}
        />
      </FormRow>
      <WithdrawalAlert
        coin={selectedCoin?.name}
        network={selectedNetwork?.name}
        tag={depositAddress?.tag}
      />
      {renderAddress()}
    </Section>
  );
};

export default React.memo(DepositTab);
