import { IApiEmptyResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import {
  IGetDepositAddressQuery,
  ITransferAssetsPayload,
  IWithdrawAssetPayload,
} from '@api/wallet/wallet-api.interfaces';
import endpoints from '@config/endpoints';
import { IFuturesBalance, ISpotBalance } from '@interfaces/balances';
import { ICoin } from '@interfaces/coin';
import { IDepositAddress } from '@interfaces/deposit-address';
import { IWalletAsset } from '@interfaces/wallet-asset';

import { COIN_TYPES } from '../../constants';

export class WalletApi extends BaseApi {
  public getCoins(type: COIN_TYPES = COIN_TYPES.DEPOSIT) {
    return this.client.get<ICoin[]>(endpoints.WALLET.COINS, { type });
  }

  public getDepositAddressCoins(query: IGetDepositAddressQuery) {
    return this.client.get<IDepositAddress>(endpoints.WALLET.DEPOSIT_ADDRESS, query);
  }

  public getSpotAssets() {
    return this.client.get<IWalletAsset[]>(endpoints.WALLET.SPOT_ASSETS);
  }

  public getFuturesAssets() {
    return this.client.get<IWalletAsset[]>(endpoints.WALLET.FUTURES_ASSETS);
  }

  public getSpotBalance() {
    return this.client.get<ISpotBalance>(endpoints.SPOT.BALANCE);
  }

  public getFuturesBalance() {
    return this.client.get<IFuturesBalance>(endpoints.FUTURES.BALANCE);
  }

  public transferAssets(data: ITransferAssetsPayload) {
    return this.client.post<ITransferAssetsPayload, IApiEmptyResponse>(
      endpoints.WALLET.TRANSFER_ASSETS,
      data,
    );
  }

  public withdrawAsset(data: IWithdrawAssetPayload) {
    return this.client.post<IWithdrawAssetPayload, IApiEmptyResponse>(
      endpoints.WALLET.WITHDRAW_ASSET,
      data,
    );
  }

  public closeAllFuturesPositionsAndOrders() {
    return this.client.delete(endpoints.FUTURES.ORDERS_POSITIONS);
  }

  public closeAllSpotOrders() {
    return this.client.delete(endpoints.SPOT.ORDERS_ALL);
  }
}
