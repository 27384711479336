import { futuresOrdersApi, spotOrdersApi } from '@api/index';
import { OrdersApi } from '@api/orders/orders.api';

import { ORDERS_SOURCE } from '../constants';

export const getOrdersApiBySource = (source: ORDERS_SOURCE): OrdersApi => {
  if (source === ORDERS_SOURCE.SPOT) {
    return spotOrdersApi;
  }

  if (source === ORDERS_SOURCE.FUTURES) {
    return futuresOrdersApi;
  }

  throw new Error('Unsupported order source');
};
