import { ApiKeysApi } from '@api/api-keys/api-keys.api';
import { AuthApi } from '@api/auth/auth.api';
import { CashbackApi } from '@api/cashback/cashback.api';
import { AxiosClient } from '@api/core/axios.client';
import { DocumentsApi } from '@api/documents/documents.api';
import { ExchangeApi } from '@api/exchange/exchange.api';
import { HistoryApi } from '@api/history/history.api';
import { OrdersApi } from '@api/orders/orders.api';
import { PairsApi } from '@api/pairs/pairs.api';
import { PositionsApi } from '@api/positions/positions.api';
import { ReferralApi } from '@api/referral/referral.api';
import { StatisticApi } from '@api/statistic/statistic.api';
import { TwoFactorAuthApi } from '@api/two-factor-auth/two-factor-auth.api';
import { UsersApi } from '@api/users/users.api';
import { VerificationApi } from '@api/verification/verification.api';
import { WalletApi } from '@api/wallet/wallet.api';
import endpoints from '@config/endpoints';

const client = new AxiosClient();

export const usersApi = new UsersApi(client);
export const authApi = new AuthApi(client);
export const walletApi = new WalletApi(client);
export const apiKeysApi = new ApiKeysApi(client);
export const spotOrdersApi = new OrdersApi(client, endpoints.SPOT.ORDERS);
export const futuresOrdersApi = new OrdersApi(client, endpoints.FUTURES.ORDERS);
export const positionsApi = new PositionsApi(client);
export const cashbackApi = new CashbackApi(client);
export const historyApi = new HistoryApi(client);
export const pairsApi = new PairsApi(client);
export const exchangeApi = new ExchangeApi(client);
export const twoFactorAuthApi = new TwoFactorAuthApi(client);
export const verificationApi = new VerificationApi(client);
export const documentsApi = new DocumentsApi(client);
export const referralApi = new ReferralApi(client);
export const statisticApi = new StatisticApi(client);
