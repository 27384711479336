import { IFuturesPair } from '@interfaces/futures-pair';

export const getMaxPairsLeverage = (pairs: IFuturesPair[]) => {
  return pairs.reduce((result, pair) => {
    if (pair.maxLeverage < result) {
      return pair.maxLeverage;
    }

    return result;
  }, 125);
};

export const getAndSortLimitsLeverages = (leverageLimits: Record<number, number>): number[] => {
  return Object.keys(leverageLimits)
    .map((key) => parseInt(key))
    .sort((a, b) => b - a);
};

export const getMaxPairVolume = (pair: IFuturesPair) => {
  let limit = 0;
  const leverages = getAndSortLimitsLeverages(pair.leverageLimits);

  for (const leverage of leverages) {
    if (pair.leverage <= leverage) {
      limit = pair.leverageLimits[leverage];
    } else {
      break;
    }
  }

  return limit;
};
