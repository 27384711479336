import React from 'react';

import { Card, CardBody } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  contentClassName?: string;
  className?: string;
  onClick?: () => void;
};

const Section: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  contentClassName,
  className,
  onClick,
}) => {
  return (
    <Card className={className} onClick={onClick}>
      <CardBody className={cn('py-4 md:py-7 px-4 md:px-6', contentClassName)}>{children}</CardBody>
    </Card>
  );
};

export default React.memo(Section);
