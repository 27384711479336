import React, { useMemo } from 'react';

import ReferralHistoryRecord from '@components/Referral/ReferralsHistoryRecord';
import { Table } from '@components/Table';
import { useMd } from '@hooks/responsive/useMd';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { IReferralsHistory } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';

import { CASHBACK_TYPE_LABELS } from '../../constants';
import { renderAmount } from '../../utils/renderers';

type PropsType = {
  data: IReferralsHistory[];
  pagination: IPaginationData;
  isLoading?: boolean;
};

const columnHelper = createColumnHelper<IReferralsHistory>();

const ReferralsHistoryTable: React.FC<PropsType> = ({ data, pagination, isLoading }) => {
  const { t } = useText();
  const isMd = useMd();

  const columns = useMemo(() => {
    if (!isMd) {
      return [
        columnHelper.display({
          id: 'history',
          header: () => t('referrals.historyTitle'),
          cell: (info) => <ReferralHistoryRecord referralsHistory={info.row.original} />,
        }),
      ];
    }

    return [
      columnHelper.accessor('email', {
        header: () => t('referrals.email'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold whitespace-nowrap">
            {info.getValue()}
          </Typography>
        ),
      }),
      columnHelper.display({
        id: 'details',
        header: () => t('cashback.details'),
        cell: (info) => {
          const { createdAt, type } = info.row.original;

          return (
            <div>
              <Typography variant="small" className="font-semibold whitespace-nowrap">
                {t(CASHBACK_TYPE_LABELS[type] as never)}
              </Typography>
              <Typography variant="small" className="font-semibold text-blue-300 text-xs">
                {t('cashback.period')} {format(new Date(createdAt), 'MM/dd/yyyy')}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor('totalVolume', {
        header: () => t('cashback.volume'),
        cell: (info) => {
          const { totalVolume, marginAsset } = info.row.original;

          return (
            <Typography variant="small" className="font-semibold whitespace-nowrap">
              {renderAmount(totalVolume, marginAsset)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor('totalVolume', {
        header: () => t('referrals.income'),
        cell: (info) => {
          const { amount, asset } = info.row.original;

          return (
            <Typography variant="small" className="font-semibold whitespace-nowrap" color="green">
              +{renderAmount(amount, asset)}
            </Typography>
          );
        },
      }),
    ];
  }, [isMd]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        emptyText={t('referrals.emptyHistory')}
        onPageChange={pagination.setPage}
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        itemsCount={pagination.totalCount || 0}
        isLoading={isLoading}
        witPagination
      />
    </div>
  );
};

export default React.memo(ReferralsHistoryTable);
