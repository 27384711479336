import { useEffect, useState } from 'react';

export const useDeleteAll = (callback: () => Promise<void> | void, isDeleting?: boolean) => {
  const [isAllDeleting, setIsAllDeleting] = useState(false);

  useEffect(() => {
    if (!isDeleting && isAllDeleting) {
      setIsAllDeleting(false);
    }
  }, [isDeleting]);

  const deleteAll = () => {
    callback();
    setIsAllDeleting(true);
  };

  return {
    isAllDeleting,
    deleteAll,
  };
};
