import React, { useState } from 'react';

import authenticatorSVG from '@assets/authenticator.svg';
import firePNG from '@assets/fire.png';
import googleAuthSignInPng from '@assets/google-aut-sign-in.png';
import Preloader from '@components/Common/Preloader';
import { PageLayout } from '@components/Layout';
import { Enable2FaFlow, SettingsBlock } from '@components/Settings';
import { useToggleCoinBurnMutation } from '@hooks/mutations/useToggleCoinBurnMutation';
import { useToggleDustTransferMutation } from '@hooks/mutations/useToggleDustTransferMutation';
import { useToggleRequire2FAOnSignInMutation } from '@hooks/mutations/useToggleRequire2FAOnSignInMutation';
import { useBurnStatusQuery } from '@hooks/queries/useBurnStatusQuery';
import { useExchangeAccountQuery } from '@hooks/queries/useExchangeAccountQuery';
import { useAuth } from '@hooks/useAuth';
import { useText } from '@hooks/useText';

import { getAssetIcon } from '../../utils/get-asset-icon';

const SettingsPage = () => {
  const { t } = useText();
  const { data: coinBurnStatus, isLoading: isCoinBurnStatusLoading } = useBurnStatusQuery();
  const { data: exchangeAccount, isLoading: isExchangeAccountLoading } = useExchangeAccountQuery();

  const { mutate: toggleCoinBurn, isLoading: isCoinBurnChanging } = useToggleCoinBurnMutation();
  const { mutate: toggleDustTransfer, isLoading: isDustTransferChanging } =
    useToggleDustTransferMutation();
  const { mutate: toggle2FAOnSignIn, isLoading: is2FAOnSignChanging } =
    useToggleRequire2FAOnSignInMutation();

  const { user } = useAuth();
  const [is2FAEnabling, setIs2FAEnabling] = useState(false);

  const handle2FAEnablingFinish = () => {
    setIs2FAEnabling(false);
  };

  if (isCoinBurnStatusLoading || isExchangeAccountLoading) {
    return <Preloader />;
  }

  if (!coinBurnStatus || !exchangeAccount) {
    return null;
  }

  return (
    <PageLayout title={t('settings.title')} contentClassName="items-center">
      <div className="flex flex-col gap-6 max-w-4xl w-full">
        <SettingsBlock
          title={t('settings.authentication')}
          description={t('settings.authenticationDescription')}
          icon={
            <img
              src={authenticatorSVG}
              alt="Google Authenticator"
              className="h-[45px] w-[45px] min-w-[45px]"
            />
          }
          enabled={user?.is2FAEnabled}
          onChange={setIs2FAEnabling}
          oneSide
        />
        <SettingsBlock
          title={t('settings.googleAuthOnSignIn')}
          description={t('settings.googleAuthOnSignInDescription')}
          icon={
            <img
              src={googleAuthSignInPng}
              alt="Google Authenticator on Sign In"
              className="h-[45px] w-[45px] min-w-[45px]"
            />
          }
          enabled={user?.require2FAOnSignIn}
          isLoading={is2FAOnSignChanging}
          onChange={toggle2FAOnSignIn}
          disabled={!user?.is2FAEnabled}
        />
        <SettingsBlock
          title={t('settings.burn')}
          description={t('settings.burnDescription')}
          icon={<img src={firePNG} alt="Fire" className="h-[45px] w-[45px] min-w-[45px]" />}
          enabled={coinBurnStatus.isSpotBurn}
          isLoading={isCoinBurnChanging}
          onChange={toggleCoinBurn}
        />
        <SettingsBlock
          title={t('settings.convert')}
          description={t('settings.convertDescription')}
          icon={
            <img src={getAssetIcon('BNB')} alt="Fire" className="h-[45px] w-[45px] min-w-[45px]" />
          }
          enabled={exchangeAccount.dustTransferEnabled}
          isLoading={isDustTransferChanging}
          onChange={toggleDustTransfer}
        />
      </div>
      <Enable2FaFlow
        isActive={is2FAEnabling}
        onCancel={handle2FAEnablingFinish}
        onFinish={handle2FAEnablingFinish}
      />
    </PageLayout>
  );
};

export default React.memo(SettingsPage);
