import React from 'react';

import { Spinner } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  fullScreen?: boolean;
  className?: string;
};

const Preloader: React.FC<PropsType> = ({ fullScreen, className }) => {
  return (
    <div
      className={cn(
        'grow flex justify-center items-center',
        fullScreen && 'w-screen h-screen',
        !fullScreen && 'py-[100px] md:py-[180px]',
        className,
      )}
    >
      <Spinner className="h-14 w-14" color="blue" />
    </div>
  );
};

export default React.memo(Preloader);
