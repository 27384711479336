import React from 'react';

import { LoadingButton } from '@components/Common';
import { Form, FormNewPasswordField } from '@components/Form';
import { INewPasswordFieldValue } from '@components/Form/Fields/NewPasswordField/NewPasswordField';
import { passwordValidationSchema } from '@components/Form/Fields/NewPasswordField/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useText } from '@hooks/useText';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export interface IResetPasswordFormData {
  password: INewPasswordFieldValue;
}

const FIELD_NAMES: { [key in keyof IResetPasswordFormData]: key } = {
  password: 'password',
};

type PropsType = {
  onSubmit: (data: IResetPasswordFormData) => void;
};

const schema: yup.ObjectSchema<IResetPasswordFormData> = yup
  .object({
    password: passwordValidationSchema,
  })
  .required();

const ResetPasswordForm: React.FC<PropsType> = ({ onSubmit }) => {
  const { t } = useText();

  const formMethods = useForm<IResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} errorText={t('resetPassword.error')}>
      <FormNewPasswordField name={FIELD_NAMES.password} />
      <LoadingButton
        fullWidth
        isLoading={formMethods.formState.isSubmitting}
        type="submit"
        className="w-full"
      >
        {t('resetPassword.changePassword')}
      </LoadingButton>
    </Form>
  );
};

export default React.memo(ResetPasswordForm);
