import React, { useMemo } from 'react';

import { LoadingButton, TextView } from '@components/Common';
import {
  CheckboxField,
  FormCheckboxField,
  FormInputField,
  FormIPAddressesField,
} from '@components/Form';
import Form from '@components/Form/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setTextPath, useText } from '@hooks/useText';
import { IApiKey } from '@interfaces/api-key';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FORM_MODES } from '../../constants';
import { i18n } from '../../localization/i18n';

type PropsType = {
  onSubmit: SubmitHandler<IApiKeyFormData>;
  apiKey?: IApiKey;
  mode?: FORM_MODES;
  error?: string;
};

export interface IApiKeyFormData {
  title: string;
  isSpot: boolean;
  isFutures: boolean;
  ips: string[];
}

const FIELD_NAMES: { [key in keyof IApiKeyFormData]: key } = {
  title: 'title',
  isSpot: 'isSpot',
  isFutures: 'isFutures',
  ips: 'ips',
};

const schema: yup.ObjectSchema<IApiKeyFormData> = yup
  .object({
    title: yup
      .string()
      .required(setTextPath('errors.required'))
      .max(200, i18n.t('errors.maxLength', { max: 200 })),
    isSpot: yup.boolean().required(setTextPath('errors.required')),
    isFutures: yup.boolean().required(setTextPath('errors.required')),
    ips: yup.array().required(setTextPath('errors.required')),
  })
  .required();

const ApiKeyForm: React.FC<PropsType> = ({ mode = FORM_MODES.CREATE, apiKey, onSubmit, error }) => {
  const { t } = useText();

  const defaultValues = useMemo<IApiKeyFormData | undefined>(() => {
    if (apiKey && mode === FORM_MODES.UPDATE) {
      return {
        title: apiKey.title,
        isSpot: apiKey.isSpot,
        isFutures: apiKey.isFutures,
        ips: apiKey.ips || [],
      };
    }

    return {
      title: '',
      isSpot: false,
      isFutures: false,
      ips: [],
    };
  }, [apiKey, mode]);

  const formMethods = useForm<IApiKeyFormData>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  return (
    <Form
      formMethods={formMethods}
      errorText={t('api.failed')}
      error={error}
      errorVariant="outlined"
      onSubmit={onSubmit}
    >
      <FormInputField name={FIELD_NAMES.title} label={t('api.name')} />
      <div className="flex gap-2 md:gap-6 flex-wrap mb-7">
        <CheckboxField
          label={t('api.readRights')}
          className="whitespace-nowrap !w-auto !mb-0"
          checked
          disabled
        />
        <FormCheckboxField
          name={FIELD_NAMES.isSpot}
          label={t('api.spot')}
          className="!w-auto !mb-0"
        />
        <FormCheckboxField
          name={FIELD_NAMES.isFutures}
          label={t('api.futures')}
          className="!w-auto !mb-0"
        />
      </div>
      <FormIPAddressesField name={FIELD_NAMES.ips} label={t('api.ipsLabel')} />
      {mode === FORM_MODES.UPDATE && apiKey && (
        <>
          <TextView text={apiKey.apiKey} label={t('api.apiKey')} copyTitle={t('api.copy')} />
          <TextView
            text="&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;&#10033;"
            label={t('api.secretKey')}
            copyTitle={t('api.copy')}
            disableCopy
          />
        </>
      )}
      <LoadingButton
        isLoading={formMethods.formState.isSubmitting}
        type="submit"
        disabled={!formMethods.formState.isValid || !formMethods.formState.isDirty}
        fullWidth
      >
        {mode === FORM_MODES.CREATE && t('api.create')}
        {mode === FORM_MODES.UPDATE && t('api.update')}
      </LoadingButton>
    </Form>
  );
};

export default React.memo(ApiKeyForm);
