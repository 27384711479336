import React from 'react';

import { Typography } from '@material-tailwind/react';

export interface IInfoITem {
  key: string;
  value: string | React.ReactNode;
}

type PropsType = {
  title: string;
  items: IInfoITem[];
};

const InfoList: React.FC<PropsType> = ({ title, items }) => {
  return (
    <div>
      <Typography className="text-lg font-semibold mb-3">{title}:</Typography>
      <ul>
        {items.map(({ key, value }) => {
          return (
            <li key={key} className="flex justify-between whitespace-nowrap">
              <Typography variant="small" className="text-blue-300 font-semibold">
                {key}
              </Typography>
              <Typography variant="small" className="font-semibold">
                {value}
              </Typography>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default React.memo(InfoList);
