import React, { useEffect, useRef, useState } from 'react';

import LoadingButton, { LoadingButtonPropsType } from '@components/Common/LoadingButton';
import cn from 'classnames';

type PropsType = LoadingButtonPropsType & {
  timeoutInSec?: number;
  disableTimer?: boolean;
};

const TimerButton: React.FC<PropsType> = ({
  timeoutInSec = 60,
  children,
  onClick,
  disabled,
  disableTimer,
  ...props
}) => {
  const [seconds, setSeconds] = useState(timeoutInSec);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const startTimer = () => {
    intervalRef.current = setInterval(() => setSeconds((prev) => prev - 1), 1000);
  };

  const stopTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setSeconds(timeoutInSec);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }

    startTimer();
  };

  useEffect(() => {
    if (seconds <= 0) {
      stopTimer();
    }
  }, [seconds]);

  useEffect(() => {
    if (disableTimer) {
      stopTimer();
    }
  }, [disableTimer]);

  const isDisabled = seconds > 0 && seconds < timeoutInSec;

  return (
    <LoadingButton {...props} disabled={isDisabled || disabled} onClick={handleClick}>
      <div className={cn(isDisabled && 'opacity-0')}>{children}</div>
      {isDisabled && (
        <div className="absolute left-0 top-0 w-full h-full bg-inherit z-50 flex items-center justify-center">
          {seconds}
        </div>
      )}
    </LoadingButton>
  );
};

export default React.memo(TimerButton);
