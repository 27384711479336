import { IApiKeyPayload } from '@api/api-keys/api-keys-api.interfaces';
import { IApiAppErrorResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import { apiKeysApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { IApiKeyWithSecret } from '@interfaces/api-key';
import { useMutation } from 'react-query';

export const useCreateApiKeyMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation<IApiKeyWithSecret, ApiError<IApiAppErrorResponse>, IApiKeyPayload>({
    mutationFn: (payload: IApiKeyPayload) => apiKeysApi.create(payload),
    onSuccess: () => {
      notify(t('api.success'), 'success');
    },
  });
};
