import React from 'react';

import { TrashIcon } from '@heroicons/react/24/solid';
import { Chip, IconButton } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  ips: string[];
  onDelete?: (ip: string) => void;
  className?: string;
};

const IPView: React.FC<PropsType> = ({ ips, onDelete, className }) => {
  return (
    <div className={cn('flex gap-2 flex-wrap', className)}>
      {ips.map((value) => (
        <Chip
          className="flex"
          color="gray"
          variant="outlined"
          key={value}
          value={value}
          action={
            onDelete && (
              <IconButton
                onClick={() => onDelete(value)}
                variant="text"
                size="sm"
                className="h-[18px] w-[25px] ml-2"
                color="blue"
              >
                <TrashIcon className="text-white h-[13px]" />
              </IconButton>
            )
          }
        />
      ))}
    </div>
  );
};

export default React.memo(IPView);
