import React from 'react';

import { Spinner } from '@material-tailwind/react';

type PropsType = {
  isLoading?: boolean;
};

const TableLoadingCell: React.FC<React.PropsWithChildren<PropsType>> = ({
  isLoading,
  children,
}) => {
  if (isLoading) {
    return (
      <div className="w-full pl-5">
        <Spinner color="blue" />
      </div>
    );
  }

  return <div className="flex w-full">{children}</div>;
};

export default React.memo(TableLoadingCell);
