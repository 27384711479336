import React from 'react';

import { Section, TextView } from '@components/Common';
import routes from '@config/routes';
import { useText } from '@hooks/useText';
import { Button } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';

type PropsType = {
  url: string;
};

const ReferralPrimaryLink: React.FC<PropsType> = ({ url }) => {
  const { t } = useText();
  const navigate = useNavigate();

  const handleSeeAllLinks = () => {
    navigate(routes.REFERRALS_LINKS);
  };

  return (
    <Section>
      <TextView text={url} label={t('referrals.inviteLabel')} />
      <Button fullWidth onClick={handleSeeAllLinks}>
        {t('referrals.allUrls')}
      </Button>
    </Section>
  );
};

export default React.memo(ReferralPrimaryLink);
