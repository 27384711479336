import { twoFactorAuthApi } from '@api/index';
import { IRequest2FAPayload } from '@api/two-factor-auth/two-factor-auth.interfaces';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

interface IParams {
  payload?: IRequest2FAPayload;
  enabled: boolean;
}

const requestFn = async (payload?: IRequest2FAPayload) => {
  if (!payload) {
    throw new Error('Payload is not defined');
  }

  return twoFactorAuthApi.request(payload);
};

export const use2FARequest = ({ payload, enabled }: IParams) => {
  return useQuery([QUERIES.TWO_FACTOR_AUTH_TYPE, payload], () => requestFn(payload), {
    enabled,
  });
};
