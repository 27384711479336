import { BaseApi } from '@api/core/base.api';
import { IRequest2FAPayload, I2FAOtpType } from '@api/two-factor-auth/two-factor-auth.interfaces';
import endpoints from '@config/endpoints';
import { ITwoFactorAuthRegisterData } from '@interfaces/two-factor-auth';

export class TwoFactorAuthApi extends BaseApi {
  public register() {
    return this.client.post<unknown, ITwoFactorAuthRegisterData>(
      endpoints.TWO_FACTOR_AUTH.REGISTER,
    );
  }

  public verify(otp: string) {
    return this.client.post(endpoints.TWO_FACTOR_AUTH.VERIFY, {
      otp,
    });
  }

  public request(payload: IRequest2FAPayload) {
    return this.client.post<IRequest2FAPayload, I2FAOtpType[]>(
      endpoints.TWO_FACTOR_AUTH.REQUEST,
      payload,
    );
  }

  public toggleRequire2FAOnSignIn(enabled: boolean) {
    return this.client.patch(endpoints.TWO_FACTOR_AUTH.REQUIRE_ON_SIGN_IN, {
      enabled,
    });
  }
}
