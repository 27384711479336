import { IApiKeyPayload } from '@api/api-keys/api-keys-api.interfaces';
import { apiKeysApi } from '@api/index';
import routes from '@config/routes';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

interface IParams {
  id: number;
  payload: IApiKeyPayload;
}

export const useUpdateApiKeyMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: ({ id, payload }: IParams) => apiKeysApi.update(id, payload),
    onSuccess: () => {
      notify(t('api.updateSuccess'), 'success');
      navigate(routes.API_KEYS);
    },
  });
};
