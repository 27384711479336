import { referralApi } from '@api/index';
import { IDeleteReferralCodePayload } from '@hooks/mutations/useDeleteReferralLinkMutation';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export const useMarkIsPrimaryMutation = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation({
    mutationFn: (payload: IDeleteReferralCodePayload) => referralApi.markPrimary(payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: QUERIES.REFERRALS_CODES });
    },
    onError: () => {
      notify(t('errors.common'), 'error');
    },
  });
};
