import React from 'react';

import AuthLayout from '@components/Layout/AuthLayout';
import { ResetPasswordRequestForm, ResetPasswordRequestRepeat } from '@components/ResetPassword';
import { useRequestResetPasswordMutation } from '@hooks/mutations/useRequestResetPasswordMutation';
import { useText } from '@hooks/useText';
import cn from 'classnames';

const ResetPasswordRequestPage = () => {
  const { t } = useText();
  const { mutateAsync, reset, variables, isSuccess, isError } = useRequestResetPasswordMutation();
  const isSubmitted = !!variables?.email && isSuccess;

  return (
    <>
      <AuthLayout
        title={t('resetPasswordRequest.checkEmail')}
        message={t('resetPasswordRequest.sendEmail', { email: variables?.email })}
        className={cn(!isSubmitted && 'hidden')}
      >
        <ResetPasswordRequestRepeat onRepeat={reset} />
      </AuthLayout>
      <AuthLayout
        title={t('resetPasswordRequest.resetPassword')}
        message={t('resetPasswordRequest.provideEmail')}
        className={cn(isSubmitted && 'hidden')}
      >
        <ResetPasswordRequestForm isError={isError} onSubmit={mutateAsync} />
      </AuthLayout>
    </>
  );
};

export default React.memo(ResetPasswordRequestPage);
