import { IApiErrorResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import { authApi } from '@api/index';
import routes from '@config/routes';
import { useAuth } from '@hooks/useAuth';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

export const useVerifyEmailMutation = () => {
  const navigate = useNavigate();
  const { notify } = useNotification();
  const { t } = useText();
  const { refreshUser } = useAuth();

  return useMutation({
    mutationFn: (token: string) => authApi.verifyEmail(token),
    onSuccess: async () => {
      await refreshUser();
      navigate(routes.HOME);
    },
    onError: (error: ApiError<IApiErrorResponse>) => {
      notify(error.response?.message || t('errors.emailVerification'));
      navigate(routes.HOME);
    },
  });
};
