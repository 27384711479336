import React from 'react';

import { Typography } from '@material-tailwind/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

type PropsType = {
  to: string;
  className?: string;
};

const AuthLink: React.FC<React.PropsWithChildren<PropsType>> = ({ to, children, className }) => {
  return (
    <NavLink to={to} className={cn('block', className)}>
      <Typography as="span" className="text-sm text-blue-500 font-semibold">
        {children}
      </Typography>
    </NavLink>
  );
};

export default React.memo(AuthLink);
