import { walletApi } from '@api/index';
import { useQuery } from '@hooks/useQuery';
import { QUERIES } from '@query/queries';

export const useSpotBalance = () => {
  return useQuery([QUERIES.SPOT_BALANCE], () => walletApi.getSpotBalance(), {
    refetchInterval: 15000,
    refetchOnMount: false,
  });
};
