import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SearchInput, Section } from '@components/Common';
import LeverageMultipleActions from '@components/Leverage/LeverageMultipleActions';
import PairsTable from '@components/Leverage/PairsTable';
import { useText } from '@hooks/useText';
import { IFuturesPair } from '@interfaces/futures-pair';
import { Button } from '@material-tailwind/react';
import { debounce } from 'lodash';

import { MARGIN_TYPE } from '../../constants';
import SearchEngine from '../../utils/search';

type PropsType = {
  pairs: IFuturesPair[];
  symbolsMarginTypeChanging?: string[];
  symbolsLeverageChanging?: string[];
  isMaxLeverageSetting?: boolean;
  onMarginTypeChange: (pairs: IFuturesPair[], type: MARGIN_TYPE) => void;
  onLeverageChange: (symbols: string[], leverage: number) => void;
  onSetMaxLeverage: (symbols: string[]) => void;
};

const searchEngine = new SearchEngine<IFuturesPair>(['symbol']);

const PairsList: React.FC<PropsType> = ({
  pairs,
  symbolsMarginTypeChanging,
  symbolsLeverageChanging,
  isMaxLeverageSetting,
  onMarginTypeChange,
  onLeverageChange,
  onSetMaxLeverage,
}) => {
  const { t } = useText();
  const [pairsToRender, setPairsToRender] = useState(pairs);
  const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);

  useEffect(() => {
    setPairsToRender(searchEngine.search(pairs, searchEngine.getTerm()));
  }, [pairs]);

  const searchDebounced = useCallback(
    debounce((term: string) => {
      const searched = searchEngine.search(pairs, term);

      setPairsToRender(searched);
      setSelectedSymbols([]);
    }, 500),
    [pairs],
  );

  const selectedPairs = useMemo(() => {
    return selectedSymbols
      .map((symbol) => {
        return pairs.find((p) => p.symbol === symbol);
      })
      .filter(Boolean) as IFuturesPair[];
  }, [selectedSymbols, pairsToRender]);

  const isSelected = useMemo(() => !!selectedSymbols.length, [selectedSymbols]);

  const handleSelect = useCallback((symbol: string) => {
    setSelectedSymbols((prev) => {
      const isSelected = prev.some((s) => s === symbol);

      if (isSelected) {
        return prev.filter((s) => s !== symbol);
      }

      return [...prev, symbol];
    });
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedSymbols((prev) => {
      if (prev.length === pairsToRender.length) {
        return [];
      }

      return pairsToRender.map((p) => p.symbol);
    });
  }, [pairsToRender]);

  return (
    <Section>
      <div className="mb-6 flex justify-between items-center flex-col xl:flex-row">
        <div className="flex gap-3 md:gap-6 items-center w-full flex-col sm:flex-row">
          <SearchInput
            onChange={searchDebounced}
            className="!mb-0 max-w-full md:!max-w-[200px]"
            upperCase
          />
          <Button
            color="gray"
            variant="outlined"
            className="normal-case text-base font-semibold py-[9px] whitespace-nowrap w-full md:w-auto"
            onClick={handleSelectAll}
          >
            {pairsToRender.length === selectedSymbols.length
              ? t('common.unselectAll')
              : t('common.selectAll')}
          </Button>
        </div>
        {isSelected && (
          <LeverageMultipleActions
            selectedPairs={selectedPairs}
            isMarginTypeUpdating={!!symbolsMarginTypeChanging?.length}
            isLeverageUpdating={!!symbolsLeverageChanging?.length}
            isMaxLeverageSetting={isMaxLeverageSetting}
            onMarginTypeChange={(type) => onMarginTypeChange(selectedPairs, type)}
            onLeverageChange={(leverage) => onLeverageChange(selectedSymbols, leverage)}
            onSetMaxLeverage={() => onSetMaxLeverage(selectedSymbols)}
          />
        )}
      </div>
      <PairsTable
        pairs={pairsToRender}
        selectedSymbols={selectedSymbols}
        symbolsMarginTypeChanging={symbolsMarginTypeChanging}
        symbolsLeverageChanging={symbolsLeverageChanging}
        onMarginTypeChange={onMarginTypeChange}
        onLeverageChange={onLeverageChange}
        onSelect={handleSelect}
      />
    </Section>
  );
};

export default React.memo(PairsList);
