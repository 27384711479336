import { useCallback, useEffect, useMemo, useState } from 'react';

import { API_ERROR_CODES } from '@api/api.constants';
import { IApiAppErrorResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import {
  I2FAOtp,
  I2FAOtpType,
  IRequest2FAPayload,
  IValidate2FAErrorData,
} from '@api/two-factor-auth/two-factor-auth.interfaces';
import { use2FARequest } from '@hooks/queries/use2FARequest';
import { useText } from '@hooks/useText';

import { OTP_TYPES } from '../constants';

export type OtpCallbackType<A> = (args: A & { otps: I2FAOtp[] }) => Promise<any>;

export interface IUse2FAParams<A> {
  inputTypes?: I2FAOtpType[] | null;
  callback: OtpCallbackType<A>;
  mapDataTo2FAPayload?: (data: A) => IRequest2FAPayload | undefined;
  deps?: any[];
}

export interface IUse2FAReturn<A> {
  isOpen: boolean;
  isLoading: boolean;
  activate: (args: A) => void;
  onConfirm: (codes: Partial<Record<OTP_TYPES, string>>) => Promise<void>;
  onCancel: () => void;
  refresh: () => void;
  types: I2FAOtpType[];
  errors: Partial<Record<OTP_TYPES, string>>;
}

const mapCodesToOtps = (codes: Partial<Record<OTP_TYPES, string>>): I2FAOtp[] => {
  return Object.keys(codes).map((key) => {
    const type = key as OTP_TYPES;

    return {
      type,
      code: codes[type as OTP_TYPES] || '',
    };
  });
};

export const use2FA = <A extends Record<string, any>>({
  inputTypes,
  callback,
  deps = [],
  mapDataTo2FAPayload,
}: IUse2FAParams<A>): IUse2FAReturn<A> => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<Record<string, any>>({});
  const [errors, setErrors] = useState<Partial<Record<OTP_TYPES, string>>>({});
  const { t } = useText();

  useEffect(() => {
    if (inputTypes) {
      setIsOpen(true);
    }
  }, [inputTypes]);

  const payload = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (!mapDataTo2FAPayload) {
      return data as IRequest2FAPayload;
    }

    return mapDataTo2FAPayload(data as A);
  }, [data]);

  const {
    data: types = [],
    isLoading: isOtpTypeLoading,
    refetch: refresh,
  } = use2FARequest({
    payload,
    enabled: isOpen && !!payload && !inputTypes,
  });

  const activate = useCallback((args: A) => {
    setIsOpen(true);
    setData(args);
  }, []);

  const cleanup = useCallback(() => {
    setData({});
    setErrors({});
  }, []);

  const handleSuccess = useCallback(() => {
    setIsOpen(false);
    cleanup();
  }, [cleanup]);

  const handleError = (error: ApiError<IApiAppErrorResponse<IValidate2FAErrorData>>) => {
    if (error.response?.code === API_ERROR_CODES.INVALID_OTP) {
      const errors =
        error.response.data?.invalidTypes.reduce((result, type) => {
          return {
            ...result,
            [type]: t('otp.invalidCode'),
          };
        }, {}) || {};

      setErrors(errors);
    } else {
      handleSuccess();
    }
  };

  const onConfirm = useCallback(
    async (codes: Partial<Record<OTP_TYPES, string>>) => {
      try {
        const otps = mapCodesToOtps(codes);

        await callback({ ...(data as A), otps });
        handleSuccess();
      } catch (e) {
        handleError(e as ApiError<IApiAppErrorResponse<IValidate2FAErrorData>>);
      }
    },
    [data, deps],
  );

  const onCancel = useCallback(() => {
    setIsOpen(false);
    cleanup();
  }, []);

  return {
    isOpen,
    isLoading: isOtpTypeLoading,
    activate,
    onConfirm,
    onCancel,
    refresh,
    types: inputTypes || types,
    errors,
  };
};
