import React from 'react';

import { useText } from '@hooks/useText';
import { IApiKey } from '@interfaces/api-key';
import { Typography } from '@material-tailwind/react';

type PropsType = {
  apiKey: IApiKey;
  asP?: boolean;
};

const ApiKeyAccess: React.FC<PropsType> = ({ apiKey, asP = true }) => {
  const { t } = useText();

  const renderAccess = () => {
    const texts = [t('api.readRights')];

    if (apiKey.isSpot) {
      texts.push(t('api.spot'));
    }

    if (apiKey.isFutures) {
      texts.push(t('api.futures'));
    }

    return texts.join(', ');
  };

  if (!asP) {
    return <>{renderAccess()}</>;
  }

  return (
    <Typography variant="paragraph" className="font-semibold text-sm">
      {renderAccess()}
    </Typography>
  );
};

export default React.memo(ApiKeyAccess);
