import React, { useMemo } from 'react';

import CashbackDetails from '@components/Cashback/CashbackDetails';
import CashbackHistory from '@components/Cashback/CashbackHistory';
import { Section, Table } from '@components/Common';
import { useXl } from '@hooks/responsive/useXl';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { ICashbackHistory } from '@interfaces/cashback';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

import { renderAmount, renderSavedCommission } from '../../utils/renderers';

type PropsType = {
  data: ICashbackHistory[];
  pagination: IPaginationData;
  isLoading?: boolean;
};

const columnHelper = createColumnHelper<ICashbackHistory>();

const CashbackHistoryTable: React.FC<PropsType> = ({ data, pagination, isLoading }) => {
  const { t } = useText();
  const isXl = useXl();

  const columns = useMemo(() => {
    if (!isXl) {
      return [
        columnHelper.display({
          id: 'history',
          header: () => t('cashback.details'),
          cell: (info) => <CashbackHistory cashback={info.row.original} />,
        }),
      ];
    }

    return [
      columnHelper.display({
        id: 'details',
        header: () => t('cashback.details'),
        cell: (info) => <CashbackDetails cashback={info.row.original} />,
      }),
      columnHelper.accessor('totalVolume', {
        header: () => t('cashback.volume'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold whitespace-nowrap">
            {renderAmount(info.getValue(), info.row.original.marginAsset)}
          </Typography>
        ),
      }),
      columnHelper.accessor('totalFee', {
        header: () => t('cashback.commission'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold whitespace-nowrap">
            {renderAmount(info.getValue(), info.row.original.asset)}
          </Typography>
        ),
      }),
      columnHelper.display({
        id: 'amount',
        header: () => t('cashback.saved'),
        cell: (info) => renderSavedCommission(info.row.original),
      }),
    ];
  }, [isXl, data]);

  return (
    <Section>
      <Table
        columns={columns}
        data={data}
        emptyText={t('cashback.empty')}
        onPageChange={pagination.setPage}
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        itemsCount={pagination.totalCount || 0}
        isLoading={isLoading}
        witPagination
      />
    </Section>
  );
};

export default React.memo(CashbackHistoryTable);
