import React, { useEffect, useMemo } from 'react';

import { LoadingButton, Section, TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import { Form, FormAmountField, FormRow, FormSelectField } from '@components/Form';
import { ArrowsRightLeftIcon, InformationCircleIcon } from '@heroicons/react/24/solid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTransferMutation } from '@hooks/mutations/useTransferMutation';
import { useFuturesAssets } from '@hooks/queries/useFuturesAssets';
import { useSpotAssets } from '@hooks/queries/useSpotAssets';
import { setTextPath, useText } from '@hooks/useText';
import { IOption } from '@interfaces/option';
import { IWalletAsset } from '@interfaces/wallet-asset';
import { Alert, IconButton } from '@material-tailwind/react';
import { property } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';

import { WALLET_TYPE } from '../../constants';
import { walletTypes } from '../../data/wallet-types';
import { renderAsset, renderCoin } from '../../utils/renderers';
import { validateAmount } from '../../utils/validate-amount';

interface IFormData {
  from: IOption<WALLET_TYPE>;
  to: IOption<WALLET_TYPE>;
  coin: IWalletAsset | null;
  amount: string;
}

const FIELD_NAMES: { [key in keyof IFormData]: key } = {
  from: 'from',
  to: 'to',
  coin: 'coin',
  amount: 'amount',
};

const schema: yup.ObjectSchema<any> = yup
  .object({
    from: yup.object().required(setTextPath('errors.required')),
    to: yup.object().required(setTextPath('errors.required')),
    coin: yup.object().required(setTextPath('errors.required')),
    amount: yup
      .string()
      .required(setTextPath('errors.required'))
      .test({
        name: 'isAmountValid',
        message: setTextPath('wallet.insufficientError'),
        test(value) {
          const max = this.parent.coin?.amount;

          return validateAmount(value, max);
        },
      }),
  })
  .required();

const TransferTab: React.FC<TabPropsType> = ({ isActive }) => {
  const { t } = useText();
  const { data: spotAssets, isLoading: isSpotAssetsFetching } = useSpotAssets(isActive);
  const { data: futuresAssets, isLoading: isFuturesAssetsFetching } = useFuturesAssets(isActive);
  const { mutateAsync: transfer } = useTransferMutation();
  const formMethods = useForm<IFormData>({
    defaultValues: {
      [FIELD_NAMES.from]: walletTypes[0],
      [FIELD_NAMES.to]: walletTypes[1],
      [FIELD_NAMES.coin]: null,
      [FIELD_NAMES.amount]: '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const from = useWatch({ control: formMethods.control, name: FIELD_NAMES.from });
  const to = useWatch({ control: formMethods.control, name: FIELD_NAMES.to });
  const coin = useWatch({ control: formMethods.control, name: FIELD_NAMES.coin });
  const isLoading = isSpotAssetsFetching || isFuturesAssetsFetching;

  const toOptions = useMemo(() => {
    if (!from) {
      return walletTypes;
    }

    return walletTypes.filter((t) => t.value !== from.value);
  }, [from]);

  const syncValuesOnFromChange = () => {
    if (from && to && from.value === to.value) {
      formMethods.setValue(FIELD_NAMES.to, toOptions[0] || null);
    }

    formMethods.setValue(FIELD_NAMES.coin, null);
    formMethods.setValue(FIELD_NAMES.amount, '');
  };

  useEffect(() => {
    syncValuesOnFromChange();
  }, [from]);

  const handleReverse = () => {
    const from = formMethods.getValues(FIELD_NAMES.from);
    const to = formMethods.getValues(FIELD_NAMES.to);

    formMethods.setValue(FIELD_NAMES.from, to);
    formMethods.setValue(FIELD_NAMES.to, from);
  };

  const handleSubmit = (data: IFormData) => {
    if (!data.coin) {
      return null;
    }

    return transfer({
      from: data.from.value,
      to: data.to.value,
      asset: data.coin.coin,
      amount: data.amount,
    });
  };

  const getAssetsOptions = () => {
    if (from?.value === WALLET_TYPE.USDT_FUTURE) {
      return futuresAssets;
    }

    return spotAssets;
  };

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <Section>
      <Form
        formMethods={formMethods}
        onSubmit={handleSubmit}
        errorText={t('transfer.failed')}
        errorVariant="outlined"
      >
        <div className="mb-7 flex items-center flex-col md:flex-row">
          <FormSelectField
            name={FIELD_NAMES.from}
            options={walletTypes}
            label={t('wallet.from')}
            className="mr-2 !mb-0"
          />
          <IconButton
            size="md"
            variant="text"
            color="blue"
            className="mr-2 px-3"
            onClick={handleReverse}
          >
            <ArrowsRightLeftIcon className="h-6" />
          </IconButton>
          <FormSelectField name={FIELD_NAMES.to} options={toOptions} label={t('wallet.to')} />
        </div>
        <FormRow className="mb-7">
          <FormSelectField
            name={FIELD_NAMES.coin}
            label={t('wallet.coin')}
            className="md:!mb-0"
            options={getAssetsOptions()}
            getOptionValue={property('coin')}
            renderOption={renderAsset}
            renderSelected={renderCoin}
          />
          <FormAmountField
            name={FIELD_NAMES.amount}
            label={t('wallet.amount')}
            max={coin?.amount}
            coin={coin?.coin}
          />
        </FormRow>
        <Alert
          variant="outlined"
          color="gray"
          icon={<InformationCircleIcon className="text-white w-[24px]" />}
          className="mb-7"
        >
          {t('transfer.alert')}
        </Alert>
        <LoadingButton
          fullWidth
          size="lg"
          disabled={!formMethods.formState.isValid}
          isLoading={formMethods.formState.isSubmitting}
          type="submit"
        >
          {t('common.confirm')}
        </LoadingButton>
      </Form>
    </Section>
  );
};

export default React.memo(TransferTab);
