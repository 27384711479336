import { documentsApi } from '@api/index';
import { useAuth } from '@hooks/useAuth';
import { useQuery } from '@hooks/useQuery';
import { QUERIES } from '@query/queries';

export const useTermsOfUseDocumentsQuery = () => {
  const { user } = useAuth();

  return useQuery([QUERIES.DOCUMENTS, user?.lng], () => documentsApi.get(user?.lng));
};
