import { useMemo, useState } from 'react';

import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { toDataURL, QRCodeToDataURLOptions } from 'qrcode';

const options: QRCodeToDataURLOptions = {
  width: 200,
  margin: 2,
};

export const useQRCode = (data?: string) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { notify } = useNotification();
  const { t } = useText();

  return useMemo(() => {
    if (!data) {
      return {
        qrCode: undefined,
        isGenerating,
      };
    }

    let qrCode: string | undefined;

    setIsGenerating(true);
    toDataURL(data, options, (err, url) => {
      setIsGenerating(false);

      if (err) {
        notify(t('errors.common'));
        return;
      }

      qrCode = url;
    });

    return {
      qrCode,
      isGenerating,
    };
  }, [data]);
};
