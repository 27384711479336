import { positionsApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

export const usePositions = (enabled?: boolean) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery([QUERIES.POSITIONS], () => positionsApi.get(), {
    enabled,
    refetchInterval: 3000,
    onError: () => notify(t('errors.common')),
  });
};
