import { IApiPaginationQuery, IApiPaginationResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import { DealsSyncedResponse, IGetPnlsQuery } from '@api/statistic/statistic.interfaces';
import endpoints from '@config/endpoints';
import { IDeal } from '@interfaces/deal';
import { IPeriodStrings } from '@interfaces/period';
import { IPnl, IPnls } from '@interfaces/pnl';

export class StatisticApi extends BaseApi {
  public getPnl(period: IPeriodStrings) {
    return this.client.get<IPnl>(endpoints.FUTURES.PNL, period);
  }

  public getPnls(query: IGetPnlsQuery): Promise<IPnls> {
    return this.client.get(endpoints.FUTURES.PNLS, query);
  }

  public getDeals(query: IPeriodStrings & IApiPaginationQuery) {
    return this.client.get<IApiPaginationResponse<IDeal>>(endpoints.FUTURES.DEALS, query);
  }

  public syncDeals(): Promise<DealsSyncedResponse> {
    return this.client.post(endpoints.FUTURES.DEALS_SYNC);
  }
}
