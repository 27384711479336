import React, { useMemo } from 'react';

import { InfoBlock, LoadingButton } from '@components/Common';
import { Form, FormAmountField, FormInputField, FormRow, FormSelectField } from '@components/Form';
import WithdrawalAlert from '@components/Operations/WithdrawalAlert';
import { yupResolver } from '@hookform/resolvers/yup';
import { useText } from '@hooks/useText';
import { ICoin } from '@interfaces/coin';
import { INetwork } from '@interfaces/network';
import { IWalletAsset } from '@interfaces/wallet-asset';
import { property } from 'lodash';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';

import { calcAmountToReceive } from '../../utils/amount';
import { renderAmount, renderAsset, renderCoin, renderNetwork } from '../../utils/renderers';
import { withdrawalSchema } from '../../validation/withdrawal.validation';

export interface IWithdrawalFormData {
  address: string;
  network: INetwork | null;
  asset: IWalletAsset | null;
  amount: string;
}

type PropsType = {
  spotAssets: IWalletAsset[];
  coins: ICoin[];
  onSubmit: SubmitHandler<IWithdrawalFormData>;
  isSubmitting?: boolean;
  error?: string;
};

const FIELD_NAMES: { [key in keyof IWithdrawalFormData]: key } = {
  address: 'address',
  network: 'network',
  asset: 'asset',
  amount: 'amount',
};

const WithdrawalForm: React.FC<PropsType> = ({
  coins,
  spotAssets,
  onSubmit,
  isSubmitting,
  error,
}) => {
  const { t } = useText();
  const formMethods = useForm<IWithdrawalFormData>({
    resolver: yupResolver(withdrawalSchema),
    mode: 'onChange',
  });
  const asset = useWatch({ control: formMethods.control, name: FIELD_NAMES.asset });
  const amount = useWatch({ control: formMethods.control, name: FIELD_NAMES.amount });
  const address = useWatch({ control: formMethods.control, name: FIELD_NAMES.address });
  const network = useWatch({ control: formMethods.control, name: FIELD_NAMES.network });

  const coin = useMemo(() => {
    return coins?.find((c) => c.coin === asset?.coin);
  }, [asset]);

  const amountToReceive = useMemo(() => {
    if (!network || !amount) {
      return '0';
    }

    return calcAmountToReceive(amount, network.withdrawFee);
  }, [network, amount]);

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit} error={error} errorVariant="outlined">
      <FormRow className="mb-7 md:mb-0">
        <FormSelectField
          name={FIELD_NAMES.asset}
          label={t('wallet.coin')}
          options={spotAssets}
          renderOption={renderAsset}
          renderSelected={renderCoin}
          getOptionValue={property('coin')}
        />
        <FormSelectField
          name={FIELD_NAMES.network}
          label={t('wallet.network')}
          disabled={!asset}
          options={coin?.networks}
          getOptionValue={property('name')}
          renderOption={renderNetwork({ isWithdrawal: true, coin: coin?.coin })}
          renderSelected={renderNetwork()}
        />
      </FormRow>
      <FormInputField
        name={FIELD_NAMES.address}
        label={t('wallet.address')}
        disabled={!asset || !network}
      />
      <FormAmountField
        name={FIELD_NAMES.amount}
        label={t('wallet.amount')}
        max={asset?.amount}
        coin={asset?.coin}
        multiplier={network ? network.withdrawIntegerMultiple : undefined}
        disabled={!asset || !network || !address}
      />
      <WithdrawalAlert coin={coin?.name} network={network?.name} />
      {network && coin && (
        <div className="mb-7 flex justify-between flex-col md:flex-row gap-2 md:gap-0">
          <div>
            <InfoBlock
              className="mb-2"
              label={t('withdraw.min')}
              text={renderAmount(network.withdrawMin, coin.coin)}
            />
            <InfoBlock
              label={t('withdraw.max')}
              text={renderAmount(network.withdrawMax, coin.coin)}
            />
          </div>
          <div>
            <InfoBlock
              className="mb-2"
              label={t('wallet.fee')}
              text={renderAmount(network.withdrawFee, coin.coin)}
            />
            <InfoBlock
              label={t('withdraw.receive')}
              text={renderAmount(amountToReceive, coin.coin)}
            />
          </div>
        </div>
      )}
      <LoadingButton
        fullWidth
        size="lg"
        disabled={!formMethods.formState.isValid}
        isLoading={formMethods.formState.isSubmitting || isSubmitting}
        type="submit"
      >
        {t('common.confirm')}
      </LoadingButton>
    </Form>
  );
};

export default React.memo(WithdrawalForm);
