import React from 'react';

import infoSvg from '@assets/info.svg';
import pendingSvg from '@assets/pending.svg';
import warningPng from '@assets/warning.png';
import { Section } from '@components/Common';
import { setTextPath, useText } from '@hooks/useText';
import { VERIFICATION_STATUS } from '@interfaces/user';
import { Button, Typography } from '@material-tailwind/react';

type PropsType = {
  url?: string;
  status: VERIFICATION_STATUS;
};

const iconsByStatus: Partial<Record<VERIFICATION_STATUS, string>> = {
  [VERIFICATION_STATUS.NOT_VERIFIED]: infoSvg,
  [VERIFICATION_STATUS.REJECTED]: warningPng,
  [VERIFICATION_STATUS.PENDING]: pendingSvg,
};

const titleByStatus: Partial<Record<VERIFICATION_STATUS, string>> = {
  [VERIFICATION_STATUS.NOT_VERIFIED]: setTextPath('verificationAlert.title'),
  [VERIFICATION_STATUS.REJECTED]: setTextPath('verification.rejected'),
  [VERIFICATION_STATUS.PENDING]: setTextPath('verification.pending'),
};

const descriptionByStatus: Partial<Record<VERIFICATION_STATUS, string>> = {
  [VERIFICATION_STATUS.NOT_VERIFIED]: setTextPath('verificationAlert.description'),
  [VERIFICATION_STATUS.REJECTED]: setTextPath('verification.rejectedDescription'),
  [VERIFICATION_STATUS.PENDING]: setTextPath('verification.pendingDescription'),
};

const VerificationUrlProvider: React.FC<PropsType> = ({ url, status }) => {
  const { t } = useText();

  return (
    <div className="flex justify-center">
      <Section className="max-w-2xl" contentClassName="flex items-center flex-col">
        <div className="py-10">
          <img src={iconsByStatus[status]} alt="Verification status icon" />
        </div>
        <Typography variant="h3" className="mb-3">
          {t(titleByStatus[status] as never)}
        </Typography>
        <Typography variant="paragraph" className="font-medium text-center mb-6">
          {t(descriptionByStatus[status] as never)}
        </Typography>
        {status !== VERIFICATION_STATUS.PENDING && url && (
          <a href={url} target="_blank" rel="noreferrer">
            <Button>{t('verificationAlert.button')}</Button>
          </a>
        )}
      </Section>
    </div>
  );
};

export default React.memo(VerificationUrlProvider);
