import { IApiEmptyResponse } from '@api/api.interfaces';
import { IApiClient } from '@api/core/api.client';
import { BaseApi } from '@api/core/base.api';
import { IDeleteOrderPayload, IDeleteOrdersPayload } from '@api/orders/orders-api.interfaces';
import { IOrder } from '@interfaces/order';

export class OrdersApi extends BaseApi {
  constructor(
    client: IApiClient,
    private readonly endpoint: string,
  ) {
    super(client);
  }

  public getOrders() {
    return this.client.get<IOrder[]>(this.endpoint);
  }

  public deleteOrders(orders: IDeleteOrderPayload[]) {
    return this.client.delete<IDeleteOrdersPayload, IApiEmptyResponse>(this.endpoint, {
      orders,
    });
  }
}
