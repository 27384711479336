import React from 'react';

import { LAYOUT_WIDTH_CLASS_NAMES } from '@components/Layout/classes';
import HelpBox from '@components/Layout/HelpBox';
import SocialLinks from '@components/Layout/SocialLinks';
import TelegramBot from '@components/Layout/TelegramBot';
import routes from '@config/routes';
import { useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

const currentYear = new Date().getFullYear();

const Footer = () => {
  const { t } = useText();

  return (
    <footer className="border-t border-border-nav w-full">
      <div
        className={cn(
          'flex flex-col lg:flex-row flex-wrap items-center justify-center gap-y-3 lg:gap-y-6 md:justify-between text-center py-5',
          LAYOUT_WIDTH_CLASS_NAMES,
        )}
      >
        <div className="flex order-2 lg:order-1 gap-6 justify-center items-center w-full lg:w-auto">
          <Typography variant="small" color="white" className=" font-medium">
            &copy; {currentYear} Mabos Trading Limited
          </Typography>
          <SocialLinks />
        </div>
        <ul className="flex flex-wrap items-center justify-between gap-y-2 gap-x-8 order-1 lg:order-2 w-full lg:w-auto">
          <li className="w-full lg:w-auto">
            <NavLink to={routes.TERMS}>
              <Typography
                  as="span"
                  color="white"
                  variant="small"
                  className="transition-colors hover:text-[#fff] focus:text-[#fff]"
              >
                {t('footer.terms')}
              </Typography>
            </NavLink>
          </li>
          <li className="w-full lg:w-auto">
            <div className="flex justify-center gap-2 items-center">
              <TelegramBot />
            </div>
          </li>
          <li className="w-full lg:w-auto">
            <div className="flex justify-center gap-2 items-center">
              <HelpBox/>
            </div>
          </li>
        </ul>
      </div>
    </footer>
);
};

export default React.memo(Footer);
