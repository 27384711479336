import React from 'react';

import { Section } from '@components/Common';
import PositionsBalance from '@components/Wallet/PositionsBalance';
import PositionsTable from '@components/Wallet/PositionsTable';
import { useClosePositionsMutation } from '@hooks/mutations/useClosePositionsMutation';
import { IFuturesBalance } from '@interfaces/balances';
import { IPosition } from '@interfaces/position';

type PropsType = {
  positions: IPosition[];
  balance: IFuturesBalance;
  onAssetsMode?: () => void;
};

const PositionsSection: React.FC<PropsType> = ({ positions, balance, onAssetsMode }) => {
  const {
    mutate: closePositions,
    isLoading: isClosing,
    variables: symbolsToClose,
  } = useClosePositionsMutation();

  return (
    <Section>
      <PositionsBalance balance={balance} className="mb-3" onAssetsMode={onAssetsMode} />
      <PositionsTable
        positions={positions}
        isDeleting={isClosing}
        symbolsToClose={symbolsToClose}
        onClose={closePositions}
      />
    </Section>
  );
};

export default React.memo(PositionsSection);
