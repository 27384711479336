import React from 'react';

import logoPng from '@assets/logo.png';
import { LAYOUT_WIDTH_CLASS_NAMES } from '@components/Layout/classes';
import { Typography } from '@material-tailwind/react';
import cn from 'classnames';

type PropsType = {
  title: string;
  message?: string;
  className?: string;
};

const AuthLayout: React.FC<React.PropsWithChildren<PropsType>> = ({
  title,
  message,
  children,
  className,
}) => {
  return (
    <div className={cn('grow flex flex-col items-center justify-center', className)}>
      <div className={cn(LAYOUT_WIDTH_CLASS_NAMES, '!max-w-sm md:!px-0')}>
        <div className="flex flex-col items-center justify-center mb-4">
          <div className="h-[80px]">
            <img src={logoPng} alt="logo" />
          </div>
          <Typography className="font-bold mt-2 text-center" variant="h4">
            {title}
          </Typography>
          {message && (
            <Typography variant="paragraph" className="text-sm mt-2 mb-2 font-semibold text-center">
              {message}
            </Typography>
          )}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default React.memo(AuthLayout);
