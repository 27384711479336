import React, { useEffect, useState } from 'react';

import Preloader from '@components/Common/Preloader';
import HistoryTable from '@components/History/HistoryTable';
import { PageLayout } from '@components/Layout';
import { useHistoryQuery } from '@hooks/queries/useHistoryQuery';
import { useText } from '@hooks/useText';

import { ALL_OPERATIONS, historyTypes, IHistoryTypeOption } from '../../data/history-types';

const getHistoryType = (option: IHistoryTypeOption) => {
  if (option.value === ALL_OPERATIONS) {
    return null;
  }

  return option.value;
};

const HistoryPage = () => {
  const { t } = useText();
  const [historyType, setHistoryType] = useState<IHistoryTypeOption>(historyTypes[0]);

  const {
    query: { data: history, isLoading: isHistoryLoading, isFetching: isHistoryFetching },
    pagination: historyPagination,
    utils: { resetQuery: resetHistory },
  } = useHistoryQuery(getHistoryType(historyType));

  useEffect(() => {
    resetHistory();
  }, [historyType]);

  if (isHistoryLoading) {
    return <Preloader />;
  }

  if (!history) {
    return null;
  }

  return (
    <PageLayout title={t('history.index')}>
      <HistoryTable
        data={history}
        historyType={historyType}
        setHistoryType={setHistoryType}
        isLoading={isHistoryFetching}
        pagination={historyPagination}
      />
    </PageLayout>
  );
};

export default React.memo(HistoryPage);
