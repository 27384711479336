import React, { useMemo, useState } from 'react';

import { ITab } from '@components/Common';
import Tabs from '@components/Common/Tabs';
import PageLayout from '@components/Layout/PageLayout';
import { ClosedOrdersTab, PAndLTab } from '@components/Statistic';
import { useFuturesPnlsQuery } from '@hooks/queries/useFuturesPnlsQuery';
import { useSyncFuturesDealsQuery } from '@hooks/queries/useSyncFuturesDealsQuery';
import { useText } from '@hooks/useText';
import { IPeriod } from '@interfaces/period';

import { getPeriodForDays } from '../../utils/date';

enum TABS {
  P_AND_L = 'P&L',
  CLOSED_ORDERS = 'CLOSED_ORDERS',
}

const StatisticFuturesUSDM = () => {
  const { t } = useText();
  const [isPnlWithFee, setIsPnlWithFee] = useState(false);
  const [period, setPeriod] = useState<IPeriod>(() => getPeriodForDays(7));
  const { data: { isSynced = false } = {}, isLoading: isDealsSyncing } = useSyncFuturesDealsQuery();
  const {
    data: pnls,
    isLoading: isPnlsLoading,
    isFetching: isPnlsFetching,
  } = useFuturesPnlsQuery({ ...period, isPnlWithFee }, isSynced);
  const isTabsLoading = isPnlsLoading || isPnlsFetching || isDealsSyncing;

  const tabs = useMemo<ITab[]>(() => {
    return [
      {
        label: t('statistic.p&l'),
        value: TABS.P_AND_L,
        content: (
          <PAndLTab
            pnls={pnls}
            isLoading={isTabsLoading}
            period={period}
            isPnlWithFee={isPnlWithFee}
            setIsPnlWithFee={setIsPnlWithFee}
            onPeriodChange={setPeriod}
          />
        ),
      },
      {
        label: t('statistic.closedOrders'),
        value: TABS.CLOSED_ORDERS,
        content: (
          <ClosedOrdersTab
            pnls={pnls}
            isLoading={isTabsLoading}
            isSynced={isSynced}
            period={period}
            onPeriodChange={setPeriod}
            setIsPnlWithFee={setIsPnlWithFee}
            isPnlWithFee={isPnlWithFee}
          />
        ),
      },
    ];
  }, [period, pnls, isTabsLoading, isSynced, isPnlWithFee]);

  return (
    <PageLayout title={t('statistic.title')}>
      <Tabs tabs={tabs} headerClassName="lg:max-w-2xl" />
    </PageLayout>
  );
};

export default React.memo(StatisticFuturesUSDM);
