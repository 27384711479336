import { IApiValidationErrorResponse } from '@api/api.interfaces';
import { ApiError } from '@api/core/api.error';
import { referralApi } from '@api/index';
import { IReferralCodePayload } from '@api/referral/referral-api.interfaces';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { queryClient } from '@query/index';
import { QUERIES } from '@query/queries';
import { useMutation } from 'react-query';

export const useCreateReferralCodeMutation = (onSuccess: () => void) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useMutation<unknown, ApiError<IApiValidationErrorResponse>, IReferralCodePayload>({
    mutationFn: (payload) => referralApi.createCode(payload),
    onSuccess: () => {
      onSuccess();
      notify(t('referrals.success'), 'success');
      queryClient.invalidateQueries({ queryKey: QUERIES.REFERRALS_CODES });
    },
  });
};
