import React, { useEffect, useMemo, useState } from 'react';

import FormField, { FormFieldProps } from '@components/Form/FormField';
import { useText } from '@hooks/useText';
import { Select, Option } from '@material-tailwind/react';

import SearchEngine from '../../../../utils/search';

import './index.scss';

export interface ISelectFieldOption {
  value: string | number;
  label: string;
}

export type SelectFieldPropsType = FormFieldProps & {
  name?: string;
  hotOptions?: any[];
  options?: any[];
  renderOption?: (option: any) => React.ReactNode;
  renderSelected?: (option: any) => React.ReactNode;
  getOptionValue?: (option: any) => any;
  value?: any;
  onChange?: (value: any) => void;
  label: string;
  disabled?: boolean;
  searchable?: boolean;
  searchFields?: string[];
};

const SelectField: React.ForwardRefRenderFunction<HTMLDivElement, SelectFieldPropsType> = (
  {
    name,
    error,
    className,
    options = [],
    hotOptions,
    renderOption,
    renderSelected,
    getOptionValue,
    label,
    value,
    onChange,
    disabled,
    searchable,
    searchFields = ['label'],
  },
  ref,
) => {
  const { t } = useText();
  const [term, setTerm] = useState('');

  const searchEngine = useMemo(() => {
    return new SearchEngine(searchFields);
  }, [searchFields]);

  useEffect(() => {
    if (searchable) {
      setTerm('');
    }
  }, [value]);

  const getValue = (option: any) => {
    if (!option) {
      return null;
    }

    return getOptionValue ? getOptionValue(option) : option.value;
  };

  const optionsExcludedHot = useMemo(() => {
    if (!hotOptions?.length) {
      return options;
    }

    return options.filter((o) => !hotOptions.some((h) => getValue(h) === getValue(o)));
  }, [options, hotOptions]);

  const optionsToRender = useMemo(() => {
    if (!searchable || !term) {
      return optionsExcludedHot;
    }

    return searchEngine.search(options, term);
  }, [optionsExcludedHot, term, options]);

  const renderItem = (option: any) => {
    // @ts-ignore
    return renderOption ? renderOption(option) : <div>{t(option.label)}</div>;
  };

  const handleChange = (value: any) => {
    if (onChange) {
      const option = options.find((o) => getValue(o) === value);

      onChange(option || null);
    }
  };

  const handleTermChange = (e: React.BaseSyntheticEvent) => {
    setTerm(e.target.value);
  };

  const selected = (
    element: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined,
  ) => {
    if (searchable) {
      return (
        <div>
          <div className="select-field__selected">
            {value && renderSelected && renderSelected(value)}
            {value && !renderSelected && renderItem(value)}
          </div>
          <input
            className="bg-transparent outline-none select-field__search"
            onClick={(e) => e.stopPropagation()}
            value={term}
            onChange={handleTermChange}
          />
        </div>
      );
    }

    if (value) {
      if (renderSelected) {
        return renderSelected(value);
      }

      return renderItem(value);
    }

    return element;
  };

  const renderOptionComponent = (option: any) => {
    const value = getValue(option);
    const content = renderItem(option);

    return (
      <Option key={value} value={value}>
        {content}
      </Option>
    );
  };

  return (
    <FormField error={error} className={className}>
      <Select
        name={name}
        label={label}
        ref={ref}
        value={getValue(value)}
        onChange={handleChange}
        selected={selected}
        disabled={disabled}
        className="select-field"
      >
        {!term && !!hotOptions?.length ? hotOptions.map(renderOptionComponent) : <></>}
        {!term && !!hotOptions?.length ? <li className="my-4 border-t border-gray-600" /> : <></>}
        {optionsToRender.length ? (
          optionsToRender.map(renderOptionComponent)
        ) : (
          <li>
            <p className="text-white text-base font-semibold text-center">
              {t('common.noOptions')}
            </p>
          </li>
        )}
      </Select>
    </FormField>
  );
};

export default React.memo(React.forwardRef(SelectField));
