import PasswordField, {
  NewPasswordFieldPropsType,
} from '@components/Form/Fields/NewPasswordField/NewPasswordField';
import { ControllerComponent } from '@hocs/withFormField';
import { Controller } from 'react-hook-form';

const NewPasswordFieldController: ControllerComponent<NewPasswordFieldPropsType> = ({
  name,
  control,
  ...props
}) => (
  <Controller
    name={name as string}
    control={control}
    render={({ field: { ...field } }) => <PasswordField {...props} {...field} />}
  />
);

NewPasswordFieldController.isController = true;

export default NewPasswordFieldController;
