export const validateAmount = (amount?: string, max?: string) => {
  if (!amount || !max) {
    return true;
  }

  const valueInt = parseFloat(amount);
  const maxInt = parseFloat(max);

  return valueInt <= maxInt;
};
