import React, { useEffect, useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useMd } from '@hooks/responsive/useMd';
import { useControlledField } from '@hooks/useControlledField';
import {
  IconButton,
  Input,
  InputProps,
  Popover,
  PopoverContent,
  PopoverHandler,
} from '@material-tailwind/react';
import { DateRange, DayPicker } from 'react-day-picker';
import InputMask from 'react-input-mask';

import { getRangeFromString, rangeToString } from '../../utils/date';

type PropsType = {
  label: string;
  value?: DateRange | null;
  onChange?: (value: DateRange | undefined | null) => void;
  clearable?: boolean;
};

const DATE_FORMAT = 'MM/dd/yyyy';
const INPUT_MASK = '99/99/9999 - 99/99/9999';

const DateInput: React.FC<InputProps> = ({ onBlur, ...props }) => (
  <InputMask mask={INPUT_MASK} value={props.value} onChange={props.onChange} onBlur={onBlur}>
    {/*@ts-ignore*/}
    {(inputProps) => <Input {...props} {...inputProps} crossOrigin />}
  </InputMask>
);

const DateRangePicker: React.FC<PropsType> = ({ label, value, onChange, clearable }) => {
  const isMd = useMd();
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useControlledField<DateRange | undefined | null>(
    undefined,
    value,
    onChange,
  );
  const [inputValue, setInputValue] = useState('');
  const [isInputError, setIsInputError] = useState(false);

  const handleChange = (range?: DateRange) => {
    if (date?.to && date.from) {
      if (range?.from?.getTime() === date.from.getTime()) {
        setDate({
          from: range.to,
        });
      } else if (range?.to?.getTime() === date?.to?.getTime()) {
        setDate({
          from: range.from,
        });
      } else {
        setDate(null);
      }
    } else {
      setDate(range || null);
    }
  };

  const handleInputChange = (e: React.BaseSyntheticEvent) => {
    const value: string = e.target.value || '';
    const range = getRangeFromString(value, DATE_FORMAT);

    if (range) {
      setDate(range);
    }

    setInputValue(value);
    setIsInputError(false);
  };

  const handleInputBlur = () => {
    const range = getRangeFromString(inputValue, DATE_FORMAT);

    if (!range) {
      setIsInputError(true);
    }
  };

  useEffect(() => {
    setInputValue(rangeToString(date, DATE_FORMAT));
  }, [date]);

  const handleClear = () => {
    setDate(undefined);
  };

  return (
    <div className="lg:max-w-[260px] w-full relative">
      <Popover placement="bottom" open={isOpen} handler={setIsOpen}>
        <PopoverHandler>
          <div>
            <DateInput
              label={label}
              value={inputValue}
              error={isInputError}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              icon={
                date &&
                clearable && (
                  <IconButton variant="text" size="sm" className="h-[25px]" onClick={handleClear}>
                    <XMarkIcon className="text-white w-5" />
                  </IconButton>
                )
              }
            />
          </div>
        </PopoverHandler>
        <PopoverContent className="z-10">
          <DayPicker
            mode="range"
            selected={date || undefined}
            numberOfMonths={isMd ? 2 : 1}
            defaultMonth={date?.from}
            pagedNavigation
            disabled={{ after: new Date() }}
            onSelect={handleChange}
            className="border-0"
            classNames={{
              caption: 'flex justify-center py-2 mb-4 relative items-center',
              caption_label: 'text-sm font-medium text-white',
              nav: 'flex items-center',
              nav_button:
                'bg-transparent hover:bg-blue-500 hover:text-gray-800 p-2 rounded-md transition-colors duration-300',
              nav_button_previous: 'absolute left-1.5',
              nav_button_next: 'absolute right-1.5',
              table: 'w-full border-collapse',
              head_row: 'flex font-medium text-white',
              head_cell: 'm-0.5 w-9 font-normal text-sm',
              row: 'flex w-full mt-2',
              cell: 'h-9 w-9 text-center text-sm p-0 relative focus-within:relative focus-within:z-20',
              day: 'rounded-md text-gray-400 h-9 w-9 p-0.5 font-normal bg-transparent hover:shadow-blue-500/20 hover:shadow-lg hover:bg-blue-500 hover:text-gray-800 transition-all duration-200',
              day_range_start: '!rounded-l-md',
              day_range_end: '!rounded-r-md',
              day_today: '!bg-gray-200 text-gray-900 !rounded-full',
              day_selected:
                '!rounded-none !bg-blue-500 text-gray-800 shadow-md shadow-blue-500/20',
              day_disabled: 'text-gray-500 opacity-50',
              day_hidden: 'invisible',
              months: 'flex gap-6',
            }}
            components={{
              IconLeft: ({ ...props }) => <ChevronLeftIcon {...props} className="h-5 w-5" />,
              IconRight: ({ ...props }) => <ChevronRightIcon {...props} className="h-5 w-5" />,
            }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default React.memo(DateRangePicker);
