import { walletApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { useQuery } from 'react-query';

export const useSpotAssets = (enabled?: boolean) => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery(['spot-assets'], () => walletApi.getSpotAssets(), {
    enabled,
    refetchInterval: 10000,
    onError: () => notify(t('errors.common')),
  });
};
