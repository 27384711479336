export default {
  AUTH: {
    LOGOUT: '/auth/logout',
    SIGN_UP: '/auth/sign-up',
    SIGN_IN: '/auth/sign-in',
    SIGN_IN_2FA: '/auth/sign-in-2fa',
    RESET_PASSWORD_REQUEST: '/users/reset-password-request',
    RESET_PASSWORD: '/users/reset-password',
    VERIFY_EMAIL: '/auth/email-verify',
  },
  TWO_FACTOR_AUTH: {
    REGISTER: '/2fa/register',
    VERIFY: '/2fa/verify',
    REQUEST: '/2fa/request',
    REQUIRE_ON_SIGN_IN: '/2fa/require-on-sign-in',
  },
  USERS: {
    ME: '/users/me',
    SEND_EMAIL_VERIFICATION_LINK: '/users/email-verification-request',
    CHANGE_LNG: '/users/change-lng',
  },
  SUMSUB: {
    VERIFICATION_ACCESS_TOKEN: '/sumsub/verification-access-token',
  },
  WALLET: {
    COINS: '/wallet/coins',
    DEPOSIT_ADDRESS: '/wallet/deposit-address',
    SPOT_ASSETS: '/wallet/spot/assets',
    FUTURES_ASSETS: '/wallet/futures/assets',
    TRANSFER_ASSETS: '/wallet/transfer',
    WITHDRAW_ASSET: '/wallet/withdraw',
  },
  API_KEYS: {
    INDEX: '/api-keys',
  },
  SPOT: {
    ORDERS: '/spot/orders',
    ORDERS_ALL: '/spot/orders/all',
    BALANCE: '/spot/balance',
  },
  FUTURES: {
    ORDERS: '/futures/orders',
    POSITIONS: '/futures/positions',
    ORDERS_POSITIONS: '/futures/orders-positions',
    BALANCE: '/futures/balance',
    PAIRS: '/futures/pairs',
    PAIRS_MARGIN_TYPE: '/futures/pairs/margin-type',
    PAIRS_LEVERAGE: '/futures/pairs/leverage',
    PAIRS_SET_MAX_LEVERAGE: '/futures/pairs/set-max-leverage',
    PNLS: '/futures/pnls',
    PNL: '/futures/pnl',
    DEALS: '/futures/deals',
    DEALS_SYNC: '/futures/deals/sync',
  },
  CASHBACK: {
    INDEX: '/cashback',
    ESTIMATE: '/cashback/estimate',
    TOTAL: '/cashback/total',
    RATES: '/cashback/rates',
  },
  EXCHANGE: {
    BURN: '/exchange/burn',
    ACCOUNT: '/exchange/account',
    DUST_TRANSFER: '/exchange/dust-transfer',
  },
  HISTORY: {
    INDEX: '/history',
  },
  VERIFICATION: {
    REQUEST: '/verification/request',
  },
  DOCUMENTS: {
    INDEX: '/documents',
  },
  REFERRALS: {
    URL: '/referrals/invite-url',
    REFERRALS: '/referrals',
    INVITE_REFERRAL: '/referrals/invite',
    OVERVIEW: '/referrals/overview',
    HISTORY: '/referrals/history',
    CODES: '/referrals/codes',
  },
};
