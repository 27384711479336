import React, { useEffect, useState } from 'react';

import InputField, { InputFieldPropsType } from '@components/Form/Fields/InputField/InputField';
import { useText } from '@hooks/useText';
import { IconButton } from '@material-tailwind/react';

import { roundAmount } from '../../../../utils/amount';

export type AmountFieldPropsType = Omit<
  InputFieldPropsType,
  'onChange' | 'helperText' | 'onError'
> & {
  onChange?: (value: string) => void;
  max?: string;
  coin?: string;
  multiplier?: string;
};

const AmountField: React.ForwardRefRenderFunction<HTMLInputElement, AmountFieldPropsType> = (
  { value, onChange, max, coin, multiplier, ...props },
  ref,
) => {
  const { t } = useText();
  const [amount, setAmount] = useState(value);

  useEffect(() => {
    if (value !== amount) {
      setAmount(value);
    }
  }, [value]);

  useEffect(() => {
    if (multiplier && value) {
      setValue(value as string);
    }
  }, [multiplier]);

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const pattern = /^(\d*)([.,]?)(\d*)$/;

    if (pattern.test(e.target.value)) {
      const value = e.target.value.replace(',', '.');

      setValue(value);
    }
  };

  const handleMax = () => {
    if (max) {
      setValue(max);
    }
  };

  const transformValue = (value: string): string => {
    if (!multiplier || !value || isNaN(parseFloat(value)) || value.endsWith('.')) {
      return value;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
    const [_, symbols] = multiplier.split('.');

    if (!symbols.length) {
      return value;
    }

    return roundAmount(value, symbols.length);
  };

  const setValue = (rowValue: string) => {
    const value = transformValue(rowValue);

    setAmount(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <InputField
      {...props}
      helperText={coin && max ? t('wallet.available', { coin, max }) : undefined}
      value={amount}
      ref={ref}
      onChange={handleChange}
      icon={
        <IconButton onClick={handleMax} variant="text" size="sm" className="h-[25px]" color="blue">
          <p className="text-[11px] text-white font-bold">MAX</p>
        </IconButton>
      }
    />
  );
};

export default React.memo(React.forwardRef(AmountField));
