import React, { useEffect } from 'react';

import { AuthLink, LoadingButton } from '@components/Common';
import { Form, FormInputField, FormPasswordField } from '@components/Form';
import routes from '@config/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { setTextPath, useText } from '@hooks/useText';
import { Typography } from '@material-tailwind/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

type PropsType = {
  error?: string;
  onSubmit: (data: ISignInFormData) => Promise<any>;
};

export interface ISignInFormData {
  email: string;
  password: string;
}

const FIELD_NAMES: { [key in keyof ISignInFormData]: key } = {
  email: 'email',
  password: 'password',
};

const schema: yup.ObjectSchema<ISignInFormData> = yup
  .object({
    email: yup.string().required(setTextPath('errors.required')),
    password: yup.string().required(setTextPath('errors.required')),
  })
  .required();

const SignInForm: React.FC<PropsType> = ({ onSubmit, error }) => {
  const { t } = useText();
  const formMethods = useForm<ISignInFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    formMethods.setFocus(FIELD_NAMES.password);
    setTimeout(() => {
      formMethods.setFocus(FIELD_NAMES.email);
    }, 100);
  }, []);

  return (
    <Form onSubmit={onSubmit} formMethods={formMethods} errorText={t('signIn.error')} serverErrorText={t('signIn.serverError')} error={error}>
      <div className="mb-1">
        <FormInputField
          name={FIELD_NAMES.email}
          label={t('signIn.emailLabel')}
          autoComplete="username"
        />
        <FormPasswordField name={FIELD_NAMES.password} label={t('signIn.passwordLabel')} />
      </div>
      <AuthLink to={routes.RESET_PASSWORD_REQUEST} className="mb-6">
        {t('resetPasswordRequest.resetPassword')}
      </AuthLink>
      <LoadingButton
        fullWidth
        type="submit"
        isLoading={formMethods.formState.isSubmitting}
        className="w-full"
      >
        {t('signIn.continue')}
      </LoadingButton>
      <div className="mt-3 flex items-center w-full">
        <Typography className="text-sm font-semibold">{t('signUp.dontHaveAccount')}</Typography>
        <AuthLink to={routes.SIGN_UP} className="ml-1">
          {t('signUp.index')}
        </AuthLink>
      </div>
    </Form>
  );
};

export default React.memo(SignInForm);
