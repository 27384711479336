import { IWalletsBalance } from '@interfaces/balances';

import { WALLET_TYPE } from '../constants';

export const getBalanceByWalletType = (type: WALLET_TYPE, balance: IWalletsBalance) => {
  switch (type) {
    case WALLET_TYPE.SPOT:
      return balance.spot;
    case WALLET_TYPE.USDT_FUTURE:
      return balance.futuresUSDM;
    default:
      return '0';
  }
};
