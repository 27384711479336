import React, { useMemo } from 'react';

import Referral from '@components/Referral/Referrals';
import { Table } from '@components/Table';
import { useMd } from '@hooks/responsive/useMd';
import { IPaginationData } from '@hooks/usePaginationQuery';
import { useText } from '@hooks/useText';
import { IReferral } from '@interfaces/referrals';
import { Typography } from '@material-tailwind/react';
import { createColumnHelper } from '@tanstack/react-table';

import { renderAmount } from '../../utils/renderers';

type PropsType = {
  data: IReferral[];
  isLoading?: boolean;
  pagination: IPaginationData;
};

const columnHelper = createColumnHelper<IReferral>();

const ReferralsTable: React.FC<PropsType> = ({ data, pagination, isLoading }) => {
  const { t } = useText();
  const isMd = useMd();

  const columns = useMemo(() => {
    if (!isMd) {
      return [
        columnHelper.display({
          id: 'referral',
          header: t('referrals.referral'),
          cell: (info) => <Referral referral={info.row.original} />,
        }),
      ];
    }

    return [
      columnHelper.accessor('email', {
        header: () => t('referrals.email'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold whitespace-nowrap">
            {info.getValue()}
          </Typography>
        ),
      }),
      columnHelper.accessor('code', {
        header: () => t('referrals.code'),
        cell: (info) => (
          <Typography variant="small" className="font-semibold break-all">
            {info.getValue()}
          </Typography>
        ),
      }),
      columnHelper.accessor('progress', {
        header: () => t('referrals.income'),
        cell: (info) => {
          const { progress, progressAsset } = info.row.original;

          return (
            <Typography variant="small" className="font-semibold whitespace-nowrap">
              {renderAmount(progress, progressAsset)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor('volume', {
        header: () => t('cashback.volume'),
        cell: (info) => {
          const { volume, progressAsset } = info.row.original;

          return (
            <Typography variant="small" className="font-semibold whitespace-nowrap">
              {renderAmount(volume, progressAsset)}
            </Typography>
          );
        },
      }),
    ];
  }, [isMd]);

  return (
    <div>
      <Table
        columns={columns}
        data={data}
        emptyText={t('referrals.empty')}
        onPageChange={pagination.setPage}
        page={pagination.page}
        itemsPerPage={pagination.itemsPerPage}
        itemsCount={pagination.totalCount || 0}
        isLoading={isLoading}
        witPagination
      />
    </div>
  );
};

export default React.memo(ReferralsTable);
