import React from 'react';

import { TimerButton } from '@components/Common';
import { useSendEmailConfirmationLink } from '@hooks/mutations/useSendEmailConfirmationLink';
import { useAuth } from '@hooks/useAuth';
import { useText } from '@hooks/useText';
import { Button, Typography } from '@material-tailwind/react';

const EmailConfirmationGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user, logout } = useAuth();
  const { t } = useText();

  const { mutate: sendEmailConfirmationLink, isLoading } = useSendEmailConfirmationLink();

  if (!user || user.isEmailVerified) {
    return <>{children}</>;
  }

  return (
    <div className="flex h-full w-full items-center justify-center grow px-6">
      <div>
        <Typography className="text-white text-center" variant="h2" color="white">
          {t('emailConfirmation.title')}
        </Typography>
        <Typography
          className="text-white font-medium max-w-fit text-center mt-4"
          variant="paragraph"
          color="gray"
        >
          {t('emailConfirmation.description', { email: user.email })}
        </Typography>
        <div className="flex md:justify-center md:flex-row mt-6 flex-col gap-4 md:gap-0">
          <Button className="mr-10 w-full md:w-auto" onClick={logout}>
            {t('emailConfirmation.back')}
          </Button>
          <TimerButton
            className="w-full md:w-auto"
            variant="outlined"
            onClick={() => sendEmailConfirmationLink()}
            isLoading={isLoading}
          >
            {t('emailConfirmation.resend')}
          </TimerButton>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EmailConfirmationGuard);
