import { setTextPath } from '@hooks/useText';
import { IOption } from '@interfaces/option';

import { OPERATIONS_TYPES } from '../constants';

export type IHistoryTypeOption = IOption<OPERATIONS_TYPES | typeof ALL_OPERATIONS>;

export const ALL_OPERATIONS = 'ALL' as const;

export const historyTypes: IHistoryTypeOption[] = [
  {
    label: setTextPath('history.allOperations'),
    value: ALL_OPERATIONS,
  },
  {
    label: setTextPath('history.dust_transfer'),
    value: OPERATIONS_TYPES.DUST_TRANSFER,
  },
  {
    label: setTextPath('history.transfer'),
    value: OPERATIONS_TYPES.TRANSFER,
  },
  {
    label: setTextPath('history.deposit'),
    value: OPERATIONS_TYPES.DEPOSIT,
  },
  {
    label: setTextPath('history.withdraw'),
    value: OPERATIONS_TYPES.WITHDRAW,
  },
];
