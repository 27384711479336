import React, { useEffect } from 'react';

import Preloader from '@components/Common/Preloader';
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogProps,
  Typography,
} from '@material-tailwind/react';

type PropsType = {
  isOpen: boolean;
  isLoading?: boolean;
  title: string | React.ReactNode;
  footer?: React.ReactNode;
  size?: DialogProps['size'];
  onClose: () => void;
};

const Modal: React.FC<React.PropsWithChildren<PropsType>> = ({
  isOpen,
  isLoading,
  title,
  children,
  footer,
  size,
  onClose,
}) => {
  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', close);

    return () => window.removeEventListener('keydown', close);
  }, []);

  return (
    <Dialog size={size} open={isOpen} handler={() => {}} className="!z-[100]">
      {!isLoading && (
        <DialogHeader>
          <Typography variant="h5" className="w-full">
            {title}
          </Typography>
        </DialogHeader>
      )}
      {!isLoading && (
        <DialogBody className="border-t border-b border-border-nav">{children}</DialogBody>
      )}
      {isLoading && (
        <DialogBody className="border-t border-b border-border-nav">
          <Preloader />
        </DialogBody>
      )}
      {footer && !isLoading && <DialogFooter className="md:space-x-2">{footer}</DialogFooter>}
    </Dialog>
  );
};

export default React.memo(Modal);
