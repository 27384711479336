import { useEffect, useState } from 'react';

export const useControlledField = <Value = any>(
  initialValue: Value,
  value?: Value,
  onChange?: (value: Value) => void
) => {
  const [state, setState] = useState<Value>(initialValue);

  useEffect(() => {
    if (value !== undefined) {
      setState(value);
    }
  }, [value]);

  const finalValue = value || state || initialValue;

  const updateValue = (updated: ((value: Value) => Value) | Value) => {
    const newValue = typeof updated === 'function' ? (updated as Function)(finalValue) : updated;

    if (onChange) {
      onChange(newValue);
    } else {
      setState(newValue);
    }
  };

  return [finalValue, updateValue] as [Value, typeof updateValue];
};
