import React from 'react';

import { Typography } from '@material-tailwind/react';

type PropsType = {
  title: string | React.ReactNode;
  value: string | React.ReactNode;
};

const PrimaryInfoBlock: React.FC<PropsType> = ({ title, value }) => {
  return (
    <div>
      <Typography variant="h6" className="whitespace-nowrap text-lg font-semibold">
        {title}
      </Typography>
      <Typography variant="h3" color="blue" className="whitespace-nowrap" textGradient>
        {value}
      </Typography>
    </div>
  );
};

export default React.memo(PrimaryInfoBlock);
