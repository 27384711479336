import React from 'react';

import { LoadingButton } from '@components/Common';
import OperationsButtons from '@components/Wallet/OperationsButtons';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { useXl } from '@hooks/responsive/useXl';
import { useText } from '@hooks/useText';

type PropsType = {
  leftSide: React.ReactNode;
  onCloseAll?: () => void;
  isAllClosing?: boolean;
};

const WalletTab: React.FC<React.PropsWithChildren<PropsType>> = ({
  children,
  leftSide,
  onCloseAll,
  isAllClosing,
}) => {
  const { t } = useText();
  const isXl = useXl();

  return (
    <div>
      <div className="flex justify-between mb-6 items-center flex-wrap gap-6 md:gap-2">
        {!isXl && (
          <LoadingButton
            isLoading={isAllClosing}
            onClick={onCloseAll}
            className="w-full md:w-auto"
            contentClassName="flex items-center justify-center md:justify-between gap-2 "
            color="gray"
          >
            {t('wallet.closeAll')} <XMarkIcon className="w-5" />
          </LoadingButton>
        )}
        {isXl && <div className="order-2 md:order-1">{leftSide}</div>}
        <OperationsButtons className="order-1 md:order-2" />
      </div>
      {!isXl && <div className="mb-6">{leftSide}</div>}
      <div>{children}</div>
    </div>
  );
};

export default React.memo(WalletTab);
