import { IPeriod } from '@interfaces/period';
import { endOfDay, format, isAfter, isValid, parse, startOfDay, subDays } from 'date-fns';
import { DateRange } from 'react-day-picker';

export const getPeriodForDays = (days: number): IPeriod => {
  return {
    fromDate: startOfDay(subDays(new Date(), days - 1)),
    toDate: new Date(),
  };
};

export const mapRangeToPeriod = (range?: DateRange | null): IPeriod | null => {
  if (!range?.to || !range?.from) {
    return null;
  }

  return {
    fromDate: startOfDay(range.from),
    toDate: endOfDay(range.to),
  };
};

export const mapPeriodForRange = (period: IPeriod): DateRange => {
  return {
    from: period.fromDate,
    to: period.toDate,
  };
};

export const getRangeFromString = (str: string, format: string): DateRange | null => {
  const [from, to] = str.split(' - ');
  const fromDate = parse(from, format, new Date());
  const toDate = parse(to, format, new Date());

  if (isValid(fromDate) && isValid(toDate) && isAfter(toDate, fromDate)) {
    return {
      from: fromDate,
      to: toDate,
    };
  }

  return null;
};

export const rangeToString = (
  range?: DateRange | null,
  formatType: string = 'MM/dd/yyyy',
): string => {
  const from = range?.from ? format(range.from, formatType) : '';
  const to = range?.to ? format(range.to, formatType) : '';

  return `${from} - ${to}`;
};
