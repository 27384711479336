import React from 'react';

import { useFormContext } from 'react-hook-form';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

type PropsType = {
  name: string;
  fieldOptions?: RegisterOptions;
};

type ComponentPropsType = {
  error?: string;
};

export type ControllerComponent<P> = React.ComponentType<{ control: any } & P> & {
  isController?: boolean;
};

export default function withFormField<P extends ComponentPropsType>(
  Component: ControllerComponent<P>,
) {
  const ControlledComponent: React.FC<P & PropsType> = ({ name, fieldOptions, ...props }) => {
    const {
      register,
      control,
      formState: { errors },
    } = useFormContext();

    const getProps = (): any => {
      if (Component.isController) {
        return {
          control,
          name,
          error: errors[name]?.message,
          ...props,
        };
      }

      return {
        ...register(name, fieldOptions),
        name,
        error: errors[name]?.message,
        ...props,
      };
    };

    return <Component {...getProps()} />;
  };

  return ControlledComponent;
}
