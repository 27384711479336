import AmountFieldController from '@components/Form/Fields/AmountField/AmountFieldController';
import CheckboxFieldController from '@components/Form/Fields/CheckboxField/CheckboxFieldController';
import InputFieldController from '@components/Form/Fields/InputField/InputFieldController';
import IPAddressesFieldController from '@components/Form/Fields/IPAddressesField/IPAddressesFieldController';
import NewPasswordFieldController from '@components/Form/Fields/NewPasswordField/NewPasswordFieldController';
import PasswordFieldController from '@components/Form/Fields/PasswordField/PasswordFieldController';
import SelectFieldController from '@components/Form/Fields/SelectField/SelectFieldController';
import withFormField from '@hocs/withFormField';

export const FormInputField = withFormField(InputFieldController);
export const FormNewPasswordField = withFormField(NewPasswordFieldController);
export const FormPasswordField = withFormField(PasswordFieldController);
export const FormSelectField = withFormField(SelectFieldController);
export const FormAmountField = withFormField(AmountFieldController);
export const FormCheckboxField = withFormField(CheckboxFieldController);
export const FormIPAddressesField = withFormField(IPAddressesFieldController);

export { default as Form } from './Form';
export { default as FormRow } from './FormRow';
export { default as SelectField } from './Fields/SelectField/SelectField';
export { default as AmountField } from './Fields/AmountField/AmountField';
export { default as CheckboxField } from './Fields/CheckboxField/CheckboxField';
export { default as NumberField } from './Fields/NumberField/NumberField';

export type { ISelectFieldOption } from './Fields/SelectField/SelectField';
