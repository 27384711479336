import React from 'react';

import { LAYOUT_WIDTH_CLASS_NAMES } from '@components/Layout/classes';
import Footer from '@components/Layout/Footer';
import Header from '@components/Layout/Header';
import cn from 'classnames';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
  return (
    <div className="flex flex-col grow">
      <Header />
      <div className={cn('grow py-10 text-white flex flex-col', LAYOUT_WIDTH_CLASS_NAMES)}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default React.memo(MainLayout);
