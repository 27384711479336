import { apiKeysApi } from '@api/index';
import { useNotification } from '@hooks/useNotification';
import { useText } from '@hooks/useText';
import { QUERIES } from '@query/queries';
import { useQuery } from 'react-query';

export const useApiKeysQuery = () => {
  const { notify } = useNotification();
  const { t } = useText();

  return useQuery([QUERIES.API_KEYS], () => apiKeysApi.getAll(), {
    onError: () => notify(t('errors.common')),
  });
};
