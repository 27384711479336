import { walletApi } from '@api/index';
import { useQuery } from '@hooks/useQuery';
import { QUERIES } from '@query/queries';

export const useFuturesBalance = (enabled?: boolean) => {
  return useQuery([QUERIES.FUTURES_BALANCE], () => walletApi.getFuturesBalance(), {
    enabled,
    refetchInterval: 15000,
  });
};
