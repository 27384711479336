import React, { useMemo } from 'react';

import { IInfoITem, InfoList, Section } from '@components/Common';
import { useText } from '@hooks/useText';

import { CASHBACK_TYPE, CASHBACK_TYPE_LABELS } from '../../constants';

type PropsType = {
  rates: Record<CASHBACK_TYPE, number>;
};

const CashbackRate: React.FC<PropsType> = ({ rates }) => {
  const { t } = useText();

  const items = useMemo<IInfoITem[]>(() => {
    return Object.values(CASHBACK_TYPE).map((key) => {
      const type = key as CASHBACK_TYPE;

      return {
        key: t(CASHBACK_TYPE_LABELS[type] as never),
        value: `${rates[type]}%`,
      };
    });
  }, [rates]);

  return (
    <Section>
      <InfoList title={t('cashback.currentRate')} items={items} />
    </Section>
  );
};

export default React.memo(CashbackRate);
