import { IApiEmptyResponse } from '@api/api.interfaces';
import { BaseApi } from '@api/core/base.api';
import endpoints from '@config/endpoints';
import { IUser } from '@interfaces/user';

import { LANGUAGES } from '../../constants';

export class UsersApi extends BaseApi {
  public getMe() {
    return this.client.get<IUser>(endpoints.USERS.ME);
  }

  public sendEmailVerificationLink() {
    return this.client.post<never, IApiEmptyResponse>(endpoints.USERS.SEND_EMAIL_VERIFICATION_LINK);
  }

  public changeLanguage(lng: LANGUAGES) {
    return this.client.patch(endpoints.USERS.CHANGE_LNG, {
      lng,
    });
  }
}
