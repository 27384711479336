import React, { useCallback } from 'react';

import { InfoBlock, Section, TabPropsType } from '@components/Common';
import Preloader from '@components/Common/Preloader';
import TwoFactorAuth from '@components/Common/TwoFactorAuth';
import { ConfirmModal } from '@components/Modals';
import WithdrawalForm, { IWithdrawalFormData } from '@components/Operations/WithdrawalForm';
import { useWithdrawMutation } from '@hooks/mutations/useWithdrawMutation';
import { useCoinsQuery } from '@hooks/queries/useCoins';
import { useSpotAssets } from '@hooks/queries/useSpotAssets';
import { OtpCallbackType, use2FA } from '@hooks/use2FA';
import { useConfirm } from '@hooks/useConfirm';
import { useText } from '@hooks/useText';

import { COIN_TYPES, TWO_FACTOR_AUTH_ACTIONS } from '../../constants';
import { calcAmountToReceive } from '../../utils/amount';
import { renderAmount, renderNetwork } from '../../utils/renderers';

const WithdrawalTab: React.FC<TabPropsType> = ({ isActive }) => {
  const { t } = useText();
  const { data: spotAssets, isLoading: isSpotAssetsFetching } = useSpotAssets(isActive);
  const { data: coins, isLoading: isCoinsLoading } = useCoinsQuery(COIN_TYPES.WITHDRAWAL, isActive);
  const {
    mutateAsync: withdraw,
    error: withdrawError,
    isLoading: isWithdrawing,
  } = useWithdrawMutation();
  const isLoading = isCoinsLoading || isSpotAssetsFetching;

  const withdrawAsset = useCallback<OtpCallbackType<IWithdrawalFormData>>(async (data) => {
    if (data.network && data.asset) {
      await withdraw({
        amount: data.amount,
        asset: data.asset?.coin,
        network: data.network?.network,
        address: data.address,
        otps: data.otps,
      });
    }
  }, []);

  const { activate: activate2FA, ...twoFactorAuthState } = use2FA<IWithdrawalFormData>({
    callback: withdrawAsset,
    mapDataTo2FAPayload: (data) => {
      if (data.network && data.asset) {
        return {
          action: TWO_FACTOR_AUTH_ACTIONS.WITHDRAW,
          payload: {
            amount: data.amount,
            asset: data.asset?.coin,
            network: data.network?.network,
            address: data.address,
          },
        };
      }
    },
  });

  const {
    confirm: handleSubmit,
    data: confirmData,
    ...confirmState
  } = useConfirm<IWithdrawalFormData>(activate2FA);

  if (isLoading) {
    return <Preloader />;
  }

  if (!spotAssets || !coins) {
    return null;
  }

  return (
    <Section>
      <WithdrawalForm
        spotAssets={spotAssets}
        coins={coins}
        onSubmit={handleSubmit}
        error={withdrawError ? t('withdraw.failed') : undefined}
      />
      <ConfirmModal title={t('withdraw.confirm')} {...confirmState}>
        {confirmData && confirmData.asset && confirmData.network && (
          <div>
            <InfoBlock
              label={t('wallet.address')}
              text={confirmData.address}
              className="mb-2 md:mb-4"
            />
            <InfoBlock
              label={t('wallet.network')}
              text={renderNetwork()(confirmData.network)}
              className="mb-2 md:mb-4"
            />
            <div className="flex justify-between flex-col md:flex-row gap-2 md:gap-0">
              <InfoBlock
                label={t('wallet.amount')}
                text={renderAmount(confirmData.amount, confirmData.asset.coin)}
              />
              <InfoBlock
                label={t('wallet.fee')}
                text={renderAmount(confirmData.network.withdrawFee, confirmData.asset.coin)}
              />
              <InfoBlock
                label={t('withdraw.receive')}
                text={renderAmount(
                  calcAmountToReceive(confirmData.amount, confirmData.network.withdrawFee),
                  confirmData.asset.coin,
                )}
              />
            </div>
          </div>
        )}
      </ConfirmModal>
      <TwoFactorAuth {...twoFactorAuthState} isVerifying={isWithdrawing} />
    </Section>
  );
};

export default React.memo(WithdrawalTab);
